import { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import invitationAPI from '../../api/invitationAPI';
import ErrorBoundary from '../../components/ErrorBoundary';
import viewHide from './view_hide.svg';
import viewShow from './view_show.svg';

const ConfirmInvitationScreen = ( { title } ) => {
  const token = new URL( window.location.href ).searchParams.get( 'token' );

  const [ showPassword, setShowPassword ] = useState( false );
  const iconType = showPassword ? viewShow : viewHide;
  const [ userPassword, setUserPassword ] = useState( '' );
  const [ confirmationSuccess, setConfirmationSuccess ] = useState( null );
  const [ isTokenValid, setTokenValid ] = useState( undefined );

  useEffect( () => {
    if ( token )
      invitationAPI
        .getInvitationData( token )
        .then( () => setTokenValid( true ) )
        .catch( () => setTokenValid( false ) );
  }, [ token ] );

  const confirmInvitation = e => {
    e.preventDefault();
    if ( token && userPassword ) {
      const success = invitationAPI.registerJoblinkUser( token, userPassword.trim() );
      setConfirmationSuccess( success );
    }
  };

  if ( confirmationSuccess ) {
    return <Redirect to={'/'} />;
  }

  return (
    <ErrorBoundary>
      {isTokenValid === false && <div>Your invitation link is invalid</div>}
      {isTokenValid === true && (
        <>
          <div className='app-login__head app-login__head--login-reset'>
            <h2 className='title'>{title}</h2>
          </div>
          <div className='col-12'>
            <div className='app-login__body app-login__body--login-reset'>
              <p>Please set the password for your account in the field below.</p>
            </div>
            <form onSubmit={confirmInvitation}>
              <div className='form-row'>
                <label htmlFor='password'>Password</label>
                <span className='relative'>
                  <input
                    type={showPassword ? 'text' : 'password'}
                    className='form-control w-100'
                    name='password'
                    id='password'
                    placeholder='Enter Your Password'
                    required
                    value={userPassword}
                    onChange={e => setUserPassword( e.target.value )}
                  />
                  <span
                    className='password-show'
                    style={{ backgroundImage: `url(${iconType})` }}
                    onClick={() => setShowPassword( !showPassword )}
                  ></span>
                </span>
              </div>
              <div className='form-row'>
                <button
                  type='submit'
                  className='btn btn--green'
                >
                  Set password
                </button>
              </div>
            </form>
          </div>
        </>
      )}
    </ErrorBoundary>
  );
};

export default ConfirmInvitationScreen;
