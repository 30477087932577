import React, { memo, useEffect } from 'react';
import ErrorBoundary from '../../components/ErrorBoundary';
import LinkBack from '../../components/LinkBack';
import { useGetCostCentersQuery } from '../../store/apis/costCentersApi';
import CreateOrderForm from './CreateOrderForm';

const NewOrder = () => {
  const { data: costCenters } = useGetCostCentersQuery();

  useEffect( () => {
    localStorage.clear();
    return () => localStorage.clear();
  }, [] );

  return (
    <ErrorBoundary>
      <div className='app-content'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12 col-md-6'>
              <LinkBack title='Back to Event listing' />
              <h1 className='title mt-3 mb-4'>Create new order</h1>
            </div>
          </div>
          <div className='row'>
            <div className='col-12 mb-4'>
              <CreateOrderForm costCenters={costCenters} />
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default memo( NewOrder );
