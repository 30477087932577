import { useEffect, useState } from 'react';
import { ReactComponent as SearchIcon } from '../../../../assets/images/search-icon.svg';
import '../EditMembersModal.sass';

export const Search = ( { onChange, style } ) => {
  const [ value, setValue ] = useState( '' );

  useEffect( () => {
    onChange( value );
  }, [ value ] );

  const onInputChange = e => {
    setValue( e.target.value );
  };

  return (
    <div
      className='search'
      style={style}
    >
      <SearchIcon />
      <input
        value={value}
        onChange={onInputChange}
        placeholder='Type here to search...'
      />
    </div>
  );
};
