import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { mainApi } from './apis/mainApi';
import { rootReducer } from './reducers/rootReducer';

const middleware = [ thunk, mainApi.middleware ];

export const store = initializeStore();

function initializeStore() {
  return createStore( rootReducer, composeWithDevTools( applyMiddleware( ...middleware ) ) );
}
