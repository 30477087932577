import { DateTime } from 'luxon';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useGetJobEventsPolling } from '../../hooks';
import { useLazyGetLocationQuery } from '../../store/apis/locationsApi';
import Pagination from '../Pagination';
import { Spinner } from '../Spinner';
import { OperationsWeek } from './OperationsWeek';

export const OperationBody = ( {
  locations,
  weeksStarts,
  expandedLocations,
  setExpandedLocations,
  setEmpty,
} ) => {
  const history = useHistory();
  const [ getLocation ] = useLazyGetLocationQuery();
  const events = useGetJobEventsPolling();

  const [ jobRoles, setJobRoles ] = useState( [] );
  const [ isLoading, setIsLoading ] = useState( [] );

  const [ itemOffset, setItemOffset ] = useState( 0 );
  const [ itemsPerPage ] = useState( 25 );
  const [ pageCount, setPageCount ] = useState( 0 );

  const [ showEmpty, setShowEmpty ] = useState( false );
  const toggleShowEmpty = hide => () => setShowEmpty( hide );

  const [ emptyJobRolesCount, setEmptyJobRolesCount ] = useState( 0 );

  const [ weekRefs ] = useState( [ useRef( null ), useRef( null ), useRef( null ) ] );

  const goCreateOrder = ( dateTime, jobRole ) => () => {
    const location = expandedLocations.find( loc => loc.id === jobRole.LocationId );
    history.push( '/new-order', {
      costCenterId: location.CostCenterId,
      locationId: location.id,
      jobRoleId: jobRole.id,
      companyId: location.CompanyAccount.name,
      date: dateTime
        .set( {
          hour: DateTime.now().hour,
        } )
        .toJSDate(),
    } );
  };

  const getLocationName = id => {
    const location = expandedLocations.find( loc => loc.id === id );
    return location?.name || '';
  };

  useEffect( () => {
    loadJobRoles();
    async function loadJobRoles() {
      if ( locations ) {
        setIsLoading( true );
        const fetchedLocations = await Promise.all(
          locations.map( async loc => {
            if ( loc ) return ( await getLocation( loc.id, true ) ).data;
            return;
          } )
        );
        console.log( { locations } );
        setJobRoles(
          fetchedLocations
            .map( location =>
              [ ...location.JobRoles ].sort( ( a, b ) => ( a.name > b.name ? 1 : -1 ) )
            )
            .flat()
        );
        setExpandedLocations( fetchedLocations );
        setIsLoading( false );
      }
    }
  }, [ getLocation, locations, setExpandedLocations ] );

  const visibleJobRoles = useMemo( () => {
    const endOffset = itemOffset + itemsPerPage;
    setPageCount( Math.ceil( jobRoles.length / itemsPerPage ) );
    return jobRoles.slice( itemOffset, endOffset );
  }, [ itemOffset, itemsPerPage, jobRoles ] );

  useEffect( () => {
    const timeout = setTimeout( tryCountEmployees, 100 );
    function tryCountEmployees() {
      try {
        countEmptyJobRoles();
      } catch {
        setTimeout( tryCountEmployees, 100 );
      }
    }
    function countEmptyJobRoles() {
      let count = 0;
      weekRefs.forEach( weekElementRef => {
        count +=
          visibleJobRoles.length -
          Array.from( weekElementRef.current.querySelectorAll( '.job-role-week' ) ).length;
      } );
      setEmptyJobRolesCount( count );
    }
    return () => clearTimeout( timeout );
  }, [ jobRoles.length, visibleJobRoles.length, weekRefs, weeksStarts ] );

  useEffect( () => {
    if ( setEmpty ) setEmpty( !jobRoles.length );
  }, [ jobRoles.length, setEmpty ] );

  if ( !events.data || isLoading ) return <Spinner />;

  if ( !jobRoles.length )
    return <div className='message-empty'>No job roles matching this location</div>;

  return (
    <>
      {weeksStarts.map( ( weekStart, weekIndex ) => (
        <OperationsWeek
          key={weekIndex}
          getLocationName={getLocationName}
          goCreateOrder={goCreateOrder}
          jobRoles={visibleJobRoles}
          weekStart={weekStart}
          showOnlyEmpty={false}
          weekElementRef={weekRefs[weekIndex]}
        />
      ) )}
      {!!visibleJobRoles.length && (
        <>
          <div
            className='d-flex justify-content-center w-100 mt-5 mb-5'
            style={{ maxHeight: 48 }}
          >
            <button
              className={`btn ${!showEmpty ? 'btn--green' : 'btn--default'}`}
              onClick={toggleShowEmpty( !showEmpty )}
              style={{ maxHeight: 48 }}
            >
              {!showEmpty ? <>Show All (+{emptyJobRolesCount})</> : 'Hide'}
            </button>
          </div>
          {showEmpty &&
            weeksStarts.map( ( weekStart, weekIndex ) => (
              <OperationsWeek
                key={weekIndex}
                getLocationName={getLocationName}
                goCreateOrder={goCreateOrder}
                jobRoles={visibleJobRoles}
                weekStart={weekStart}
                showOnlyEmpty={true}
              />
            ) )}
        </>
      )}
      <div className='total-time-columns-background'>
        <span></span>
        <span></span>
        <span></span>
      </div>
      {jobRoles.length > itemsPerPage && (
        <Pagination
          pageCount={pageCount}
          onPageChange={e => setItemOffset( e.selected * itemsPerPage )}
          perPage={itemsPerPage}
        />
      )}
    </>
  );
};
