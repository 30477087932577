import { Field } from 'formik';

export const InternalCommentField = () => {
  return (
    <div className='form-row w-100'>
      <label htmlFor='internalComment'>Internal Comment</label>
      <Field
        as='textarea'
        id='internalComment'
        name='internalComment'
        className='form-control h-350'
        placeholder='Type message here...'
      />
    </div>
  );
};
