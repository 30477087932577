import { useState } from 'react';
import { ReactComponent as RemoveIcon } from '../../../../assets/images/remove-icon.svg';
import { useUploadCertificateMutation } from '../../../../store/apis/employeesApi';

export const CertificateUpload = ( { employeeId } ) => {
  const [ uploadCertificate ] = useUploadCertificateMutation();

  const [ certificateUploadFile, setCertificateUploadFile ] = useState();

  const [ isDragOver, setDragOver ] = useState( false );

  const onCertificateFileChange = event => {
    if ( event.target.files[0] ) setCertificateUploadFile( event.target.files[0] );
  };

  const onCertificateFileDrop = event => {
    if ( event.dataTransfer.files[0] )
      setCertificateUploadFile( event.dataTransfer.files[0] );
    event.preventDefault();
    setDragOver( false );
  };

  const onDragOver = e => {
    e.preventDefault();
    setDragOver( true );
  };

  const onDragLeave = () => {
    setDragOver( false );
  };

  const clearFile = () => {
    setCertificateUploadFile( undefined );
  };

  const onUploadClick = event => {
    event.preventDefault();
    console.log( 'onUploadClick' );
    uploadCertificate( {
      file: certificateUploadFile,
      validationDate: new Date(),
      employeeId,
      certificateTypeId: 1,
    } )
      .unwrap()
      .then( () => {
        clearFile();
      } );
  };

  return (
    <div className='certificate-upload'>
      {certificateUploadFile ? (
        <>
          <button
            className='btn btn--green'
            onClick={onUploadClick}
          >
            Upload
          </button>
          <p>{certificateUploadFile.name}</p>
          <RemoveIcon
            className='remove'
            onClick={clearFile}
          />
        </>
      ) : (
        <label
          onDrop={onCertificateFileDrop}
          onDragOver={onDragOver}
          onDragEnter={onDragOver}
          onDragLeave={onDragLeave}
          className={isDragOver ? 'drag-over' : undefined}
        >
          Select or drop a file to upload
          <input
            type='file'
            hidden
            onChange={onCertificateFileChange}
          />
          <div className='drag-over-box'></div>
        </label>
      )}
    </div>
  );
};
