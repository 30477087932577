import { createSlice } from '@reduxjs/toolkit';

export const socketSlice = createSlice( {
  initialState: { isMessagesSocketOpen: false },
  name: 'socket',
  reducers: {
    setMessagesSocketOpen: ( state, action ) => {
      state.isMessagesSocketOpen = action.payload;
    },
  },
} );

export const socketActions = socketSlice.actions;
