import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Pagination from '../../../../components/Pagination';
import { Spinner } from '../../../../components/Spinner';
import { useGetAllEmployeesQuery } from '../../../../store/apis/employeesApi';
import styles from '../../OperationsScreen.module.sass';
import { HeaderRow } from '../header';
import { EmployeeRow, OpenEventsRow } from '../rows';

export const EmployeeGroupLayout = () => {
  const { currentWeekStart, costCenter, weeksCount, employeeFilter, allEvents } =
    useSelector( state => state.operationsPage );

  const [ page, setPage ] = useState( 0 );
  const [ perPage, setPerPage ] = useState( 25 );

  const { data: employeesPaginated } = useGetAllEmployeesQuery( {
    filters: {
      statuses: [ 'active' ],
      costCenterIds: costCenter === 'all' ? undefined : [ costCenter ]
    },
    includes: {
      AcceptedOffers: {
        start: currentWeekStart.toJSDate(),
        end: currentWeekStart.plus( { weeks: weeksCount } ).toJSDate()
      },
      ReceivedOffers: {
        start: currentWeekStart.toJSDate(),
        end: currentWeekStart.plus( { weeks: weeksCount } ).toJSDate()
      }
    },
    pagination: {
      offset: page * perPage,
      limit: perPage,
      includeTotal: true
    }
  } );

  const { data: employees, pagination } = employeesPaginated || { data: [] };

  const filteredEmployees = useMemo( () => {
    if ( employeeFilter ) return [ employeeFilter ];
    if ( !employees ) return [];
    return employees;
  }, [ costCenter, employees, employeeFilter ] );

  if ( !filteredEmployees?.length ) return <Spinner className={styles['spinner']} />;

  const onPageChange = ( { selected } ) => {
    setPage( selected );
  };

  return (
    <>
      <div
        className={[ styles['table'], styles['no-padding-bottom'] ].join( ' ' )}
        data-weeks={weeksCount}
      >
        <HeaderRow week={currentWeekStart} />

        <OpenEventsRow
          jobEvents={allEvents}
          weekStart={currentWeekStart}
          weeksCount={weeksCount}
        />
        {filteredEmployees.map( ( employee, index ) => (
          <EmployeeRow
            key={index}
            employee={employee}
            week={currentWeekStart}
            showEmpty
          />
        ) )}
      </div>

      {pagination && (
        <Pagination
          onPageChange={onPageChange}
          pageCount={Math.ceil( pagination.total / perPage )}
          perPage={perPage}
          setPerPage={setPerPage}
        />
      )}
    </>
  );
};
