import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { ReactComponent as SvgFileIcon } from '../../../assets/images/csv-file.svg';
import { LabeledField } from '../../../components/LabeledField';
import NoResultInTable from '../../../components/NoResultInTable';
import { NoResults } from '../../../components/NoResults';
import { Spinner } from '../../../components/Spinner';
import { StyledSelect } from '../../../components/StyledSelect';
import { useGetJobEventsPolling } from '../../../hooks';
import { useGetAllEmployeesQuery } from '../../../store/apis/employeesApi';
import { eventLengthWithTimeBreak } from '../../../utils/dates';
import {
  combineSortingPredicates,
  eventsSortingPredicates
} from '../../../utils/sortings/eventsSortings';
import styles from '../ReportingScreen.module.sass';
import { downloadCsv } from './utils';

export const EmployeeReporting = () => {
  const { data: events } = useGetJobEventsPolling();
  const { data: employees } = useGetAllEmployeesQuery();

  const [ start, setStart ] = useState( DateTime.now().startOf( 'month' ) );
  const [ end, setEnd ] = useState( DateTime.now().endOf( 'month' ) );
  const [ employeeId, setEmpoyeeId ] = useState();

  const onDateChange = setter => date => setter( DateTime.fromJSDate( date ) );

  const employeeData = useMemo( () => {
    if ( !employeeId || !events || !employees ) return;
    const employee = employees.find( item => item.id === employeeId );
    const employeeEvents = events
      .filter(
        event =>
          event.phase === 'log' &&
          event.JobOffer.AcceptedEmployeeId === employee.id &&
          DateTime.fromISO( event.start ) >= start &&
          DateTime.fromISO( event.end ) <= end
      )
      .sort(
        combineSortingPredicates(
          eventsSortingPredicates.byDate(),
          eventsSortingPredicates.byJobRole()
        )
      );

    let totalHours = 0;
    const jobRolesTotal = [];
    const rows = employeeEvents.map( event => {
      const start = DateTime.fromISO( event.start );
      const end = DateTime.fromISO( event.end );
      const length = eventLengthWithTimeBreak( event );
      totalHours += length;
      const jobRoleTotal = jobRolesTotal.find(
        item => item.jobRole.id === event.JobRoleId
      );
      if ( jobRoleTotal ) jobRoleTotal.hours += length;
      else jobRolesTotal.push( { jobRole: event.JobRole, hours: length } );
      return [
        {
          value: start.toFormat( 'd.LL.' )
        },
        {
          value: event.JobRole.name
        },
        { value: length, className: styles['center'] },
        { value: start.toFormat( 'HH:mm' ), className: styles['center'] },
        {
          value: end.toFormat(
            end.hasSame( start, 'day' )
              ? 'HH:mm'
              : `HH:mm (+${Math.ceil( end.diff( start, 'day' ).days )})`
          ),
          className: styles['center']
        },
        {
          value: event.JobRole.Location.CompanyAccount.name
        },
        {
          value: event.JobRole.Location.name
        }
      ];
    } );
    return {
      totalHours,
      jobRolesTotal,
      rows,
      employee
    };
  }, [ employeeId, employees, events, start, end ] );

  const downloadEmployeeCsv = () => {
    if ( employeeData ) {
      downloadCsv(
        [
          [
            'Date',
            'Job Role',
            'Total hours',
            'Start Time',
            'End Time',
            'Company	Location'
          ],
          ...employeeData.rows.map( row => row.map( col => col.value ) ),
          [ 'Grand Total:', employeeData.totalHours ],
          [ 'Job Roles Total:' ],
          ...employeeData.jobRolesTotal.map( data => [ data.jobRole.name, data.hours ] )
        ],
        `${employeeData.employee.firstName} ${
          employeeData.employee.lastName
        }-${DateTime.now().toFormat( 'D' )}`
      );
    }
  };

  if ( !events || !employees ) return <Spinner />;

  return (
    <>
      <div className={styles['filters']}>
        <LabeledField label={'Employee'}>
          <StyledSelect
            options={employees.map( employee => ( {
              value: employee.id,
              label: `${employee.firstName} ${employee.lastName}`
            } ) )}
            value={employeeId}
            onChange={setEmpoyeeId}
          />
        </LabeledField>
        <LabeledField label='Start'>
          <ReactDatePicker
            selected={start.toJSDate()}
            onChange={onDateChange( setStart )}
            className='form-control'
            wrapperClassName={styles['date-input']}
            dateFormat='dd/MM/yyyy'
          />
        </LabeledField>
        <LabeledField label='End'>
          <ReactDatePicker
            selected={end.toJSDate()}
            onChange={onDateChange( setEnd )}
            className='form-control'
            wrapperClassName={styles['date-input']}
            dateFormat='dd/MM/yyyy'
          />
        </LabeledField>
      </div>
      {employeeData ? (
        <>
          <section className={styles['reporting-item']}>
            <h1>
              {employeeData.employee.firstName} {employeeData.employee.lastName}
              <SvgFileIcon
                onClick={downloadEmployeeCsv}
                className={styles['download']}
              />
            </h1>
            <table className='table'>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Job Role</th>
                  <th className={styles['center']}>Total hours</th>
                  <th className={styles['center']}>Start Time</th>
                  <th className={styles['center']}>End Time</th>
                  <th>Company</th>
                  <th>Location</th>
                </tr>
              </thead>
              <tbody>
                {employeeData.rows.map( ( row, rowIndex ) => (
                  <tr key={rowIndex}>
                    {row.map( ( col, colIndex ) => (
                      <td
                        key={colIndex}
                        className={col.className}
                      >
                        {col.value}
                      </td>
                    ) )}
                  </tr>
                ) )}
                {!employeeData.rows.length && <NoResultInTable colSpan={7} />}
              </tbody>
            </table>
          </section>
          <div className={styles['grand-total']}>
            <h4>Grand total:</h4>
            <span>{employeeData.totalHours}</span>
          </div>
          <div className={styles['job-roles-total']}>
            <h4>Job roles total:</h4>
            <ul>
              {employeeData.jobRolesTotal.map( jobRoleTotal => (
                <li key={jobRoleTotal.jobRole.id}>
                  <span>{jobRoleTotal.jobRole.name}</span>
                  <span>{jobRoleTotal.hours}</span>
                </li>
              ) )}
            </ul>
          </div>
        </>
      ) : (
        <NoResults />
      )}
    </>
  );
};
