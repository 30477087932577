import { DateTime } from 'luxon';
import { useCallback, useContext, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as PdfIcon } from '../../assets/images/pdf-file.svg';
import { eventItemHoverBoxClassName } from '../../components/EventItemHoverBox';
import { EventItemHoverBoxContext } from '../../components/EventItemHoverBox/EventItemHoverBoxContext';
import { getLogStatusData } from '../../utils/utils';

export const LogEventItem = ( { onSelect, event, checked } ) => {
  const logStatus = useMemo( () => getLogStatusData( event ), [ event ] );

  const { setEvent, setBoundingBox } = useContext( EventItemHoverBoxContext );
  const [ hoverTimeout, setHoverTimeout ] = useState();

  const openHoverBox = useCallback(
    e => () => {
      setEvent( event );
      setBoundingBox( e.target.closest( '.event-listing-item' ).getClientRects()[0] );
      if ( hoverTimeout ) clearTimeout( hoverTimeout );
    },
    [ setEvent, setBoundingBox, hoverTimeout ]
  );

  const onMouseEnter = e => {
    const timeout = setTimeout( openHoverBox( e ), 500 );
    setHoverTimeout( timeout );
  };

  const onMouseLeave = e => {
    if (
      !(
        e.relatedTarget.classList &&
        Array.from( e.relatedTarget.classList ).includes( eventItemHoverBoxClassName )
      )
    ) {
      setEvent( null );
      setBoundingBox( null );
      if ( hoverTimeout ) clearTimeout( hoverTimeout );
    }
  };

  return (
    <tr
      key={event.id}
      className='event-listing-item'
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <td className='table-checkbox'>
        <label className='custom-checkbox'>
          <input
            type='checkbox'
            onChange={onSelect( event )}
            checked={checked}
          />
          <span />
        </label>
      </td>
      <td className='link-in'>
        <NavLink to={`event-listing/${event.id}`}>
          {`${event.JobOffer.AcceptedEmployee.firstName} 
                                                    ${event.JobOffer.AcceptedEmployee.lastName}`}
        </NavLink>
      </td>
      <td className='weight-600 link-in text-center'>
        <NavLink to={`event-listing/${event.id}`}>
          {DateTime.fromISO( event.start ).toFormat( 'dd.LL - HH:mm' )}
        </NavLink>
      </td>
      <td className='link-in text-center'>
        <NavLink to={`event-listing/${event.id}`}>
          {Math.round(
            DateTime.fromISO( event.end ).diff( DateTime.fromISO( event.start ), 'hours' ).hours
          )}
        </NavLink>
      </td>
      <td className='link-in'>
        <NavLink to={`event-listing/${event.id}`}>
          {event.JobRole.Location.CompanyAccount.name}
        </NavLink>
      </td>
      <td className='link-in'>
        <NavLink to={`event-listing/${event.id}`}>{event.JobRole.Location.name}</NavLink>
      </td>
      <td className='link-in'>
        <NavLink to={`event-listing/${event.id}`}>{event.JobRole.name}</NavLink>
      </td>
      <td className='link-in'>
        <NavLink
          to={`event-listing/${event.id}`}
          style={{ justifyContent: 'center' }}
        >
          {event.JobLog.Customer_Comment && '!'}
        </NavLink>
      </td>
      <td className='badge-box badge-box-single link-in'>
        <NavLink to={`event-listing/${event.id}`}>
          <span className={[ 'log-badge', logStatus.color ].join( ' ' )}>
            {logStatus.label}
          </span>
        </NavLink>
      </td>
      <td className='d-flex justify-content-center'>
        <a href={( () => '#' )()}>
          <PdfIcon />
        </a>
      </td>
    </tr>
  );
};
