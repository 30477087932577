import { DateTime } from 'luxon';
import { memo, useMemo } from 'react';
import { Link } from 'react-router-dom';
import styles from '../../OperationsScreen.module.sass';
import { OperationEventBadge } from './OperationEventBadge';

const OperationBadgeComponent = ( {
  icon: Icon,
  events,
  employee,
  jobRole,
  jobRoleGroups
} ) => {
  const hasEventsInOtherJobRoles = useMemo( () => {
    if ( !jobRoleGroups || !jobRole ) return false;
    const otherJobRolesGroups = jobRoleGroups.filter(
      group => group.jobRole.id !== jobRole
    );
    const date = DateTime.fromISO( events[0].start ).toISODate();

    const accepted = otherJobRolesGroups
      .map(
        group =>
          group.byEmployee.find( item => item.employee.id === employee.id )?.jobEvents
            .byDate.accepted[date] || []
      )
      .flat();

    if ( accepted.length )
      return {
        status: 'accepted',
        jobEvents: accepted
      };

    const received = otherJobRolesGroups
      .map(
        group =>
          group.byEmployee.find( item => item.employee.id === employee.id )?.jobEvents
            .byDate.received[date] || []
      )
      .flat();

    if ( received.length )
      return {
        status: 'offered',
        jobEvents: received
      };

    return false;
  }, [ jobRole, jobRoleGroups ] );

  const isSingle = events?.length === 1 && !hasEventsInOtherJobRoles;

  const link = useMemo( () => {
    if ( isSingle ) {
      const firstEvent = events[0];
      return `/event-listing/${firstEvent.id}`;
    }
  }, [ events ] );

  return link ? (
    <Link
      to={link}
      className={styles['badge']}
    >
      <OperationEventBadge
        events={[ events ].flat()}
        employee={employee}
        hasEventsInOtherJobRoles={hasEventsInOtherJobRoles}
      />
    </Link>
  ) : (
    <div className={styles['badge']}>
      {events ? (
        <OperationEventBadge
          events={[ events ].flat()}
          employee={employee}
          hasEventsInOtherJobRoles={hasEventsInOtherJobRoles}
        />
      ) : (
        <Icon />
      )}
    </div>
  );
};

export const OperationBadge = memo( OperationBadgeComponent );
