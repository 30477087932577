import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Alert from '../../../components/Alert';
import { fieldErrorClass } from '../../../constants';
import { authApi } from '../../../store/apis/authApi';
import { loginSchema } from './schema';
import viewHide from './view_hide.svg';
import viewShow from './view_show.svg';

const LoginForm = () => {
  const [ showPassword, setShowPassword ] = useState( false );
  const iconType = showPassword ? viewShow : viewHide;
  const handleClick = () => setShowPassword( !showPassword );

  const [ isError, setError ] = useState( false );

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      onSubmit={values => {
        authApi.signIn( values ).then( success => setError( !success ) );
      }}
      validationSchema={loginSchema}
    >
      {( { values, handleChange, touched, errors, isValid, handleSubmit } ) => (
        <Form
          onSubmit={handleSubmit}
          className='form editing-company-form'
        >
          <div className='form-row'>
            <label htmlFor='loginEmail'>Email</label>
            <Field
              className={`form-control ${
                errors.email && touched.email && fieldErrorClass
              }`}
              type='email'
              name='email'
              value={values.email}
              onChange={handleChange}
              placeholder='Enter Your Email'
            />
          </div>
          <div className='form-row'>
            <label htmlFor='loginPassword'>Password</label>
            <span className='relative'>
              <Field
                className={`form-control w-100 ${
                  errors.password && touched.password && fieldErrorClass
                }`}
                type={showPassword ? 'text' : 'password'}
                name='password'
                value={values.password}
                onChange={handleChange}
                placeholder='Enter Password'
                autoComplete='on'
              />
              <span
                className='password-show'
                style={{ backgroundImage: `url('${iconType}')` }}
                onClick={handleClick}
              ></span>
            </span>
          </div>
          {isError && (
            <div className='form-row'>
              <Alert
                type={'danger'}
                text='Incorrect Email or Password'
              />
            </div>
          )}
          <div className='form-row mb-3'>
            <button
              type='submit'
              disabled={!isValid}
              className='btn btn--block btn--green'
            >
              Sign in
            </button>
          </div>
          <div className='form-row mb-0'>
            <NavLink to='/reset-password'>
              <span className='btn btn--block btn--light-green'>Forgot password?</span>
            </NavLink>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
