export const downloadCsv = ( rows, filename ) => {
  const csvContent =
    'data:text/csv;charset=utf-8,' +
    rows.map( col => col.map( item => `"${item}"` ).join( ',' ) ).join( '\n' );
  const encodedUri = encodeURI( csvContent );
  const a = document.createElement( 'a' );
  a.setAttribute( 'href', encodedUri );
  a.setAttribute( 'download', `${filename}.csv` );
  document.body.appendChild( a );
  a.click();
  a.remove();
};
