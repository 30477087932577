import { skipToken } from '@reduxjs/toolkit/dist/query';
import { DateTime } from 'luxon';
import { useContext, useMemo } from 'react';
import { EventItemHoverBox } from '../../components/EventItemHoverBox';
import { EventItemHoverBoxContext } from '../../components/EventItemHoverBox/EventItemHoverBoxContext';
import { InfoRows } from '../../components/InfoRows/InfoRows';
import { Spinner } from '../../components/Spinner';
import { useGetCustomerUserQuery } from '../../store/apis/customerUsersApi';
import { useGetJoblinkUserQuery } from '../../store/apis/joblinkUsersApi';

export const EventListingHoverBox = () => {
  const { event } = useContext( EventItemHoverBoxContext );

  const { data: joblinkUser, isLoading: isJoblinkUserLoading } = useGetJoblinkUserQuery(
    ( event ? event.JoblinkUserCreatorId : undefined ) || skipToken
  );
  const { data: customerUser, isLoading: isCustomerUserLoading } =
    useGetCustomerUserQuery(
      ( event ? event.CustomerUserCreatorId : undefined ) || skipToken
    );
  const user = useMemo( () => {
    if ( event ) {
      const creatorId = event.JoblinkUserCreatorId || event.CustomerUserCreatorId;
      return [ joblinkUser, customerUser ].find( item => item?.id === creatorId );
    }
  }, [ event, joblinkUser, customerUser ] );

  const isAccepted = useMemo( () => {
    return !!event?.JobOffer?.AcceptedEmployee;
  }, [ event ] );

  const offeredTo = useMemo( () => {
    if ( !event || !event.JobOffer?.ReceivedEmployees ) return [];
    return event.JobOffer.ReceivedEmployees.map( employee =>
      [ employee.firstName, employee.lastName ].join( ' ' )
    );
  }, [ event, isAccepted ] );

  const createdField = useMemo( () => {
    if ( event ) {
      const dateString = DateTime.fromISO( event.createdAt ).toFormat( 'dd.LL. HH:mm' );
      const userString = user ? [ user.firstName, user.lastName ].join( ' ' ) : undefined;
      return userString ? `${dateString} (${userString})` : dateString;
    }
  }, [ user, event ] );

  const render = useMemo( () => {
    if ( event ) {
      if ( isJoblinkUserLoading || isCustomerUserLoading ) {
        return <Spinner />;
      } else {
        return (
          <InfoRows
            rows={[
              ...( isAccepted
                ? []
                : [
                    {
                      items: [
                        {
                          label: `Offered to ${offeredTo?.length}:`,
                          element: (
                            <>
                              {offeredTo?.map( ( name, index ) => (
                                <div key={index}>{name}</div>
                              ) )}
                            </>
                          )
                        }
                      ],
                      props: { disableShadowBox: true, className: 'mb-3' }
                    }
                  ] ),
              {
                items: [
                  {
                    label: 'Created',
                    value: createdField
                  }
                ],
                props: { disableShadowBox: true, className: 'mb-1' }
              }
            ]}
          />
        );
      }
    }
  }, [ event, isAccepted, offeredTo, createdField ] );

  return <EventItemHoverBox>{render}</EventItemHoverBox>;
};
