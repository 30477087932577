import LinkBack from '../../../components/LinkBack';

export const Header = ( { jobEvent, selectedEmployees, onAssign } ) => (
  <div className='row mb-4'>
    <div className='col-12 col-md-12'>
      <LinkBack title='Back to Event listing' />
      <div className='title-box mt-3'>
        <h1 className='title mr-2'>
          Employee assigment (
          {jobEvent?.JobOffer.ReceivedEmployees.length + selectedEmployees.length})
        </h1>
        <button
          className='btn btn--green ml-4'
          onClick={onAssign}
        >
          Assign selected
        </button>
      </div>
      <h2 className='mt-0'>
        {jobEvent.JobRole.Location.name}
        <br />
        {jobEvent.JobRole.name}
      </h2>
    </div>
  </div>
);
