import { useState } from 'react';
import './Switch.sass';

export const Switch = ( { onChange, defaultValue, style } ) => {
  const [ checked, setChecked ] = useState( defaultValue || false );

  const onClick = () => {
    setChecked( !checked );
    onChange( !checked );
  };

  return (
    <div
      className='switch'
      data-checked={checked}
      onClick={onClick}
      style={style}
    >
      <span></span>
    </div>
  );
};
