import { useEffect, useState } from 'react';
import { useGetPresetTasksQuery } from '../../../../store/apis/presetTasksApi';
import { PresetTask } from './PresetTask';

export const PresetTasksList = ( { checked, onChange } ) => {
  const presetTasks = useGetPresetTasksQuery();

  const [ checkedTasks, setCheckedTasks ] = useState( checked || [] );

  useEffect( () => {
    onChange( checkedTasks );
  }, [ checkedTasks, onChange ] );

  if ( !presetTasks.data ) return null;

  const checkTask = task => {
    setCheckedTasks(
      checkedTasks.concat( {
        taskId: task.id,
        skillIds: [],
      } )
    );
  };

  const uncheckTask = task => {
    setCheckedTasks( checkedTasks.filter( item => item.taskId !== task.id ) );
  };

  const onTaskChange = task => {
    if ( checkedTasks.find( item => item.taskId === task.id ) ) uncheckTask( task );
    else checkTask( task );
  };

  const onSkillChange = ( task, skill ) => {
    const alreadyChecked = checkedTasks.find( item => item.taskId === task.id );
    if ( alreadyChecked ) {
      setCheckedTasks( [
        ...checkedTasks.filter( item => item.taskId !== task.id ),
        {
          taskId: task.id,
          skillIds: alreadyChecked.skillIds.find( id => id === skill.id )
            ? alreadyChecked.skillIds.filter( id => id !== skill.id )
            : alreadyChecked.skillIds.concat( skill.id ),
        },
      ] );
    } else {
      setCheckedTasks( [
        ...checkedTasks,
        {
          taskId: task.id,
          skillIds: [ skill.id ],
        },
      ] );
    }
  };

  return (
    <div className='checkboxes'>
      {presetTasks.data.map( task => {
        const skillChecked = checkedTasks.find( item => item.taskId === task.id );

        return (
          <PresetTask
            checked={!!skillChecked}
            onChange={onTaskChange}
            task={task}
            key={task.id}
            onChangeSkills={onSkillChange}
            checkedSkills={skillChecked && skillChecked.skillIds}
          />
        );
      } )}
    </div>
  );
};
