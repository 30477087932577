import { DateTime } from 'luxon';

export const filterFutureEvents = events =>
  events.filter( event => DateTime.fromISO( event.start ) > DateTime.now() );

export const filterThisWeekComingEvents = events =>
  events.filter( event => {
    const eventStart = DateTime.fromISO( event.start );
    const now = DateTime.now();
    return eventStart.hasSame( now, 'week' ) && eventStart > now;
  } );

export const filterThisMonthComingEvents = events =>
  events.filter( event => {
    const eventStart = DateTime.fromISO( event.start );
    const now = DateTime.now();
    return eventStart.hasSame( now, 'month' ) && eventStart > now;
  } );

export const filterNextWeekComingEvents = events =>
  events.filter( event => {
    const nextWeekStart = DateTime.now().plus( { week: 1 } ).startOf( 'week' );
    const nextWeekEnd = DateTime.now()
      .plus( { week: 1 } )
      .startOf( 'week' )
      .plus( { week: 1 } );
    const eventStart = DateTime.fromISO( event.start );
    return eventStart >= nextWeekStart && eventStart <= nextWeekEnd;
  } );

export const filterPastEvents = events =>
  events.filter( event => DateTime.fromISO( event.start ) < DateTime.now() );

export const filterThisMonthPastEvents = events =>
  events.filter( event => {
    const eventStart = DateTime.fromISO( event.start );
    const now = DateTime.now();
    return eventStart.hasSame( now, 'month' ) && eventStart < now;
  } );

export const filterLastMonthEvents = events =>
  events.filter( event => {
    const eventStart = DateTime.fromISO( event.start );
    const now = DateTime.now();
    return eventStart.month === now.month - 1 && eventStart.year === now.year;
  } );

export const filterLastThreeMonthsEvents = events =>
  events.filter( event => {
    const eventStart = DateTime.fromISO( event.start );
    const now = DateTime.now();
    const monthsDiff = now.month - eventStart.month;
    return monthsDiff > 0 && monthsDiff <= 3 && eventStart.year === now.year;
  } );

export const filterCurrentYearPastEvents = events =>
  events.filter( event => {
    const eventStart = DateTime.fromISO( event.start );
    const now = DateTime.now();
    return eventStart < now && eventStart.hasSame( now, 'year' );
  } );

export const filterEventsByStatus = ( events, status ) =>
  events.filter( event => event.status === status );

export const filterExceptEventByStatus = ( events, status ) =>
  events.filter( event => event.status !== status );

export const filterJobLogMissingEvents = events =>
  events.filter( event => event.phase === 'log' && event.JobLog.phase === 'Employee' );

export const filterJobLogInProgressEvents = events =>
  events.filter(
    event =>
      event.phase === 'log' &&
      event.JobLog.phase !== 'Employee' &&
      event.JobLog.phase !== 'finalized'
  );

export const filterEventsByStatuses = ( events, statuses ) =>
  events.filter( event => statuses.includes( event.status ) );

export const filterFilledEventsForChart = events =>
  events.filter( event => [ 'completed', 'filled' ].includes( event.status ) );

export const filterEventsForPerformance = events =>
  events.filter( event => [ 'completed', 'filled', 'failed' ].includes( event.status ) );

export const filterByJobLogPhase = phase => events =>
  events.filter( event => event.JobLog.phase === phase );

export const filterCompletedLogs = events =>
  events.filter(
    event =>
      event.JobLog.phase === 'finalized' && event.JobLog.Joblink_Decision === 'accept'
  );
export const filterFailedLogs = events =>
  events.filter(
    event =>
      event.JobLog.phase === 'finalized' && event.JobLog.Joblink_Decision !== 'accept'
  );

export const filterEventsByJobRole = ( events, jobRoleName ) =>
  events.filter( event =>
    event.JobRole.name.toLowerCase().includes( jobRoleName.toLowerCase() )
  );

export const filterEventsByLocation = ( events, locationName ) => {
  return events.filter( event =>
    event.JobRole.Location.name.toLowerCase().includes( locationName.toLowerCase() )
  );
};

export const filterDayBetweenEvents = ( events, dayStart, endDay ) => {
  const filterResult = events.filter( event => {
    if ( endDay ) {
      return (
        DateTime.fromISO( event.start ) >= DateTime.fromJSDate( dayStart ) &&
        DateTime.fromJSDate( endDay ) >= DateTime.fromISO( event.end )
      );
    }
    return DateTime.fromISO( event.start ) > DateTime.fromJSDate( dayStart );
  } );

  return filterResult;
};

export const jobLogFilterValuesMap = {
  employee: filterByJobLogPhase( 'Employee' ),
  customer: filterByJobLogPhase( 'CustomerUser' ),
  joblink: filterByJobLogPhase( 'JoblinkUser' ),
  completed: filterCompletedLogs,
  failed: filterFailedLogs,
  filterBetweenDate: filterDayBetweenEvents
};
