/******************** APP ********************/
export const INITIALIZED_SUCCESS = 'JBL/APP/INITIALIZED_SUCCESS';
export const CLIENT_PING_SUCCESS = 'JBL/APP/CLIENT_PING_SUCCESS';

export const SHOW_ALERT = 'JBL/APP/SHOW_ALERT';
export const HIDE_ALERT = 'JBL/APP/HIDE_ALERT';
export const SHOW_MODAL = 'JBL/APP/SHOW_MODAL';
export const HIDE_MODAL = 'JBL/APP/HIDE_MODAL';
export const ENABLE_BUTTONS = 'JBL/APP/ENABLE_BUTTONS';
export const DISABLE_BUTTONS = 'JBL/APP/DISABLE_BUTTONS';
export const TOGGLE_NAV_BAR = 'JBL/APP/TOGGLE_NAV_BAR';

/******************** LOGIN ********************/
export const SIGN_IN = 'JBL/LOGIN/SIGN_IN';
export const AUTHORIZATION = 'JBL/LOGIN/AUTHORIZATION';
export const RESET_PASSWORD = 'JBL/LOGIN/RESET_PASSWORD';
export const INCORRECT_VALUE = 'JBL/LOGIN/INCORRECT_VALUE';
export const GET_ROLE = 'JBL/LOGIN/GET_ROLE';
export const LOG_OUT = 'JBL/LOGIN/LOG_OUT';
export const RESET_TOKEN_VALID = 'JBL/LOGIN/RESET_TOKEN_VALID';

/******************** USERS ********************/
export const FETCH_USERS = 'JBL/USERS/FETCH_USERS';
export const SEARCH_USERS = 'JBL/USERS/SEARCH_USERS';
export const FETCH_ADMINS = 'JBL/USERS/FETCH_ADMINS';
export const SEARCH_ADMINS = 'JBL/USERS/SEARCH_ADMINS';
export const SELECT_USER = 'JBL/USERS/SELECT_USER';
export const RESET_SELECTED_USER = 'JBL/USERS/RESET_SELECTED_USER';
export const RESET_USERS_LOADER = 'JBL/USERS/RESET_LOADER';
export const UPDATE_USER_INFO = 'JBL/USERS/UPDATE_USER_INFO';

/******************** ACCOUNTS ********************/
export const FETCH_ACCOUNTS = 'JBL/ACCOUNTS/FETCH_ACCOUNTS';
export const SEARCH_ACCOUNTS = 'JBL/ACCOUNTS/SEARCH';
export const SELECT_ACCOUNT = 'JBL/ACCOUNTS/SELECT_ACCOUNT';
export const REMOVE_FROM_SELECTED_ACCOUNT =
  'JBL/ACCOUNTS/REMOVE_SELECTED_ACCOUNT';
export const SET_COMPANY_CREATED = 'JBL/ACCOUNTS/SET_COMPANY_CREATED';

/******************** TASKS ********************/
export const FETCH_TASKS = 'JBL/TASKS/FETCH_TASKS';
export const CREATE_ONE_TASK = 'JBL/TASKS/CREATE_ONE_TASK';
export const REMOVE_TASK_SKILL = 'JBL/TASKS/REMOVE_TASK_SKILL';
export const CLEAR_TASKS_RESPONSE = 'JBL/TASKS/CLEAR_TASKS_RESPONSE';

/******************** ORDERS ********************/
export const FETCH_ORDERS = 'JBL/ORDERS/FETCH_ORDERS';
export const SEARCH_ORDERS = 'JBL/ORDERS/SEARCH_ORDERS';
export const CREATE_ORDER = 'JBL/ORDERS/CREATE_ORDER';
export const SELECT_ORDER = 'JBL/ORDERS/SELECT_ORDERS';
// export const CANCEL_ORDER = 'JBL/ORDERS/CANCEL_ORDER';
export const SELECT_EMPLOYEES = 'JBL/ORDERS/SELECT_EMPLOYEES';
export const RESET_SELECTED_ORDER = 'JBL/ORDERS/RESET_SELECTED_ORDER';
export const SHOW_ORDERS_LOADER = 'JBL/ORDERS/SHOW_LOADER';
export const NEW_ORDER_CREATED_SUCCESS = 'JBL/ORDERS/NEW_ORDER_CREATED_SUCCESS';
export const NEW_ORDER_CREATED_FAILED = 'JBL/ORDERS/NEW_ORDER_CREATED_FAILED';
export const RESET_STATUS_CREATED_ORDER =
  'JBL/ORDERS/RESET_STATUS_CREATED_ORDER';
export const CANCEL_OFFER = 'JBL/ORDERS/CANCEL_OFFER';

/******************** COST_CENTERS ********************/
export const FETCH_COST_CENTERS = 'JBL/COST_CENTERS/FETCH_ALL';
export const SEARCH_COST_CENTERS = 'JBL/COST_CENTERS/SEARCH';

/******************** LOCATIONS ********************/
export const GET_ONE_LOCATION = 'JBL/LOCATIONS/GET_ONE_LOCATION';
export const UPDATE_ONE_LOCATION = 'JBL/LOCATIONS/UPDATE_ONE_LOCATION';
// export const CREATE_ONE_LOCATION = 'JBL/LOCATIONS/CREATE_ONE_LOCATION';
export const SEARCH_LOCATIONS = 'JBL/LOCATIONS/SEARCH_LOCATIONS';

/******************** JOB_ROLES ********************/
export const GET_ONE_JOB_ROLE_BY_ID = 'JBL/JOB_ROLES/GET_ONE_JOB_ROLE_BY_ID';

/******************** EMPLOYEES ********************/
export const FETCH_ALL_EMPLOYEES = 'JBL/EMPLOYEES/FETCH_ALL';
export const SEARCH_EMPLOYEE = 'JBL/EMPLOYEES/SEARCH_EMPLOYEE';
export const FETCH_ALL_POTENTIAL_EMPLOYEES =
  'JBL/EMPLOYEES/FETCH_ALL_POTENTIAL';
export const EDIT_EMPLOYEE_INFO_SUCCESS =
  'JBL/EMPLOYEES/EDIT_EMPLOYEE_INFO_SUCCESS';
export const EDIT_EMPLOYEE_INFO_FAILED =
  'JBL/EMPLOYEES/EDIT_EMPLOYEE_INFO_FAILED';
export const ADD_EMPLOYEES_TO_ORDER = 'JBL/EMPLOYEES/ADD_EMPLOYEES_TO_ORDER';
export const SEND_OFFER_TO_EMPLOYEES = 'JBL/EMPLOYEES/ADD_EMPLOYEES_TO_ORDER';

/******************** CERTIFICATES ********************/
export const FETCH_CERTIFICATES = 'JBL/CERTIFICATES/FETCH_CERTIFICATES';
export const SEARCH_CERTIFICATES = 'JBL/CERTIFICATES/SEARCH_CERTIFICATES';

/******************** INVITATION ********************/
export const GET_INVITATION_DATA = 'JBL/INVITATION/GET_INVITATION_DATA';
export const INVITATION_TOKEN_VALID = 'JBL/INVITATION/INVITATION_TOKEN_VALID';
