import { memo } from 'react';

const Unit = ( { data } ) => {
  return (
    <li
      className={[ 'caption-item', data.className ].join( ' ' )}
      onClick={data.onClick}
      data-clickable={!!data.onClick}
    >
      <span
        style={{ background: data.color }}
        className='icon'
      />
      {data.title}
      <span className='count'>{data.value}</span>
    </li>
  );
};

export default memo( Unit );
