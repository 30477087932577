import React from 'react';
import privateIcon from '../../../../../assets/images/chatMessage-mail.svg';
import { Button } from '../../../../../components/Button';
import './replyAll.scss';

const ReplyAll = ( { openInputReply } ) => {
  return (
    <>
      <div className='replyAll__top'>
        <Button
          type={'green'}
          onClick={openInputReply}
        >
          Reply to all
        </Button>
      </div>

      <div className='replyAll__bottom'>
        <span>
          or click{' '}
          <img
            src={privateIcon}
            alt=''
          />{' '}
          to reply in a private message
        </span>
      </div>
    </>
  );
};

export default ReplyAll;
