import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axiosBaseQuery } from '../../api/instances';

export const mainApi = createApi( {
  baseQuery: axiosBaseQuery,
  reducerPath: 'mainApi',
  tagTypes: [
    'conversations',
    'joblinkGroups',
    'company',
    'companies',
    'costCenters',
    'customerUsers',
    'employees',
    'leaves',
    'event',
    'allEvents',
    'inviteJU',
    'inviteEmployee',
    'users',
    'jobRole',
    'allJobRoles',
    'location',
    'tasks',
    'joblink-reset',
    'customer-reset',
    'employee-reset',
    'availability',
    'invitedCustomerUsers'
  ],
  endpoints: () => ( {} )
} );
