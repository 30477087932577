import moment from 'moment';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Button } from '../../../components/Button';
import {
  useGetJobEventQuery,
  useLockEventMutation,
  useUnlockEventMutation
} from '../../../store/apis/eventsApi';
import { createConversationActions } from '../../../store/slices';
import CancelOrAbortForm from './CancelOrAbortForm';
import Default from './Default';
import { EditJobEventForm } from './EditJobEventForm';

const Open = () => {
  const { orderId } = useParams();
  const history = useHistory();

  const { data: jobEvent } = useGetJobEventQuery( orderId );

  const [ lockEvent ] = useLockEventMutation();
  const [ unlockEvent ] = useUnlockEventMutation();

  const dispatch = useDispatch();

  const [ showHistory, setShowHistory ] = useState( false );
  const jobOffer = jobEvent?.JobOffer;

  // Cancel or Abort Modal Data
  const [ cancelModalOpened, setCancelModalOpened ] = useState( false );
  const [ isEditModalOpened, setEditModalOpened ] = useState( false );
  const [ modalFormat, setModalFormat ] = useState( null );

  const [ isLocked, setLocked ] = useState( !!jobEvent?.lockedAt );
  const firstRender = useRef( true );
  const [ lock, triggerLock ] = useState( !!jobEvent?.lockedAt );

  const openCancelModal = () => setCancelModalOpened( true );
  const closeCancelModal = () => setCancelModalOpened( false );
  const openEditModal = () => {
    if ( isLocked ) return;
    setEditModalOpened( true );
    triggerLock( true );
  };
  const closeEditModal = () => {
    setEditModalOpened( false );
    triggerLock( false );
  };

  const onCancelOffer = () => {
    openCancelModal();
    setModalFormat( 'cancel' );
  };

  const onAbortOffer = () => {
    openCancelModal();
    setModalFormat( 'abort' );
  };

  const onWindowClose = useCallback(
    e => {
      unlockEvent( orderId );
      e.preventDefault();
      e.returnValue = '';
    },
    [ orderId, unlockEvent ]
  );

  useEffect( () => {
    setLocked( !!jobEvent?.lockedAt );
  }, [ jobEvent, jobEvent?.lockedAt ] );

  useEffect( () => {
    if ( firstRender.current ) {
      // don't run on first render
      firstRender.current = false;
      return;
    }
    if ( lock ) {
      lockEvent( orderId );
      window.addEventListener( 'beforeunload', onWindowClose );
    } else {
      unlockEvent( orderId );
      window.removeEventListener( 'beforeunload', onWindowClose );
    }
  }, [ lock, orderId, onWindowClose, lockEvent, unlockEvent ] );

  const customStyles = {
    content: {
      maxWidth: 720
    }
  };

  const employeesNotResponded = useMemo( () => {
    return (
      jobEvent?.JobOffer?.ReceivedEmployees.filter(
        employee => employee.SentEmployees.type === 'not_responded'
      ) || []
    );
  }, [ jobEvent ] );

  const onMessageAll = () => {
    dispatch( createConversationActions.setEmployeesPrefilled( employeesNotResponded ) );
    history.push( '/chat/create' );
  };

  const onMessageEmployee = employee => () => {
    dispatch( createConversationActions.setEmployeesPrefilled( employee ) );
    history.push( '/chat/create' );
  };

  return (
    <>
      <Default />
      <div className='row'>
        <div className='col-12'>
          <div className='white-shadow-box mb-1'>
            <div className='mb-3'>
              Employees
              {!!employeesNotResponded.length && (
                <Button
                  className='ml-3'
                  onClick={onMessageAll}
                  type='pale-green'
                >
                  Message to all
                </Button>
              )}
            </div>
            <div className='info-cols'>
              {jobOffer?.ReceivedEmployees?.map( employee => (
                <div
                  className='info-col'
                  key={employee.id}
                >
                  <span className='info-col__label'>
                    {employee.firstName} {employee.lastName}
                    <span
                      className='employee-message'
                      onClick={onMessageEmployee( employee )}
                    >
                      message
                    </span>
                  </span>
                  <div>
                    <small className='text-grey'>{employee?.SentEmployees?.type}</small>
                  </div>
                </div>
              ) )}
              {!jobOffer?.AcceptedEmployee && !jobOffer?.ReceivedEmployees.length && (
                <div className='text-undefined'>Not assigned yet</div>
              )}
            </div>
          </div>
        </div>
        <div className='col-12'>
          <div className='d-flex flex-wrap flex-md-nowrap justify-content-center align-items-center justify-content-md-between pt-4 pb-4'>
            <div className='btn-group pb-4 pb-md-0'>
              <NavLink
                className='btn btn--green mr-12'
                to={`/event-listing/${jobEvent?.id}/employee-assignment`}
              >
                Add Employees
              </NavLink>
              <div
                className='btn btn--light-green mr-12'
                onClick={openEditModal}
              >
                Edit offer
              </div>
              <div
                className='btn btn--light-danger mr-12'
                onClick={() => onCancelOffer()}
              >
                Cancel offer
              </div>
              <div
                className='btn btn--outline-danger mr-12'
                onClick={() => onAbortOffer( jobEvent.id )}
              >
                Abort offer
              </div>
            </div>
            <button
              className='btn btn--default'
              onClick={() => setShowHistory( !showHistory )}
            >
              {showHistory ? 'Hide' : 'Show'} document history
            </button>
          </div>
        </div>
        {showHistory && (
          <div className='col-12'>
            <div className='white-shadow-box mb-1'>
              <div className='mb-3'>Document History</div>
              <div className='info-cols'>
                <div className='info-col'>
                  <span className='info-col__label'>Company created Job Order</span>
                </div>
                <div className='info-col'>
                  <span className='info-col__label'>
                    {moment( jobEvent?.createdAt ).format( 'MMMM D YYYY, h:mm a' )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal
        isOpen={cancelModalOpened}
        ariaHideApp={false}
        contentLabel={`Cancel or ${modalFormat}`}
        style={customStyles}
      >
        <span
          className='icon-close'
          onClick={closeCancelModal}
        />
        <div className='modal-head'>
          <div className='modal-title'>Are you sure?</div>
        </div>
        <div className='modal-body'>
          <CancelOrAbortForm
            id={orderId}
            modalFormat={modalFormat}
            closeModal={closeCancelModal}
            phase={jobEvent?.phase}
          />
        </div>
      </Modal>
      <Modal
        isOpen={isEditModalOpened}
        style={customStyles}
        ariaHideApp={false}
      >
        <div className='modal-head'>
          <div className='modal-title'>
            {jobEvent?.JobRole.Location.name}, {jobEvent?.JobRole.name}
          </div>
        </div>
        <EditJobEventForm onClose={closeEditModal} />
      </Modal>
    </>
  );
};

export default memo( Open );
