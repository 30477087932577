import { Skill } from './Skill';

export const PresetTask = ( {
  task,
  checked,
  onChange,
  checkedSkills,
  onChangeSkills,
} ) => {
  const onInputChange = () => {
    onChange( task );
  };

  const onSkillChange = skill => () => {
    onChangeSkills( task, skill );
  };

  return (
    <div className='checkboxes-item'>
      <label className='custom-checkbox'>
        <input
          type='checkbox'
          checked={checked}
          onChange={onInputChange}
        />
        <span>{task.name}</span>
      </label>
      {checked && (
        <div className='inner-checkboxes'>
          {task.Skills.map( skill => (
            <Skill
              key={skill.id}
              skill={skill}
              checked={checkedSkills.includes( skill.id )}
              onChange={onSkillChange( skill )}
            />
          ) )}
        </div>
      )}
    </div>
  );
};
