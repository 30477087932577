import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useFormikContext } from 'formik';
import { useEffect, useMemo } from 'react';
import { StyledSelect } from '../../../../components/StyledSelect';
import { useGetJobRoleQuery } from '../../../../store/apis/jobRolesApi';
import { useGetPresetTasksQuery } from '../../../../store/apis/presetTasksApi';

export const TaskField = () => {
  const { values, setFieldValue } = useFormikContext();

  const jobRoleId = +values.jobRole;
  const taskId = +values.task;

  const { data: jobRole } = useGetJobRoleQuery( jobRoleId || skipToken );
  const { data: presetTasks } = useGetPresetTasksQuery();

  const options = useMemo( () => {
    if ( !jobRole || !presetTasks ) return [];
    if ( jobRoleId !== jobRole.id ) return [];
    return jobRole.Tasks.map( task => ( {
      label: presetTasks.find( presetTask => presetTask.id === task.PresetTaskId ).name,
      value: task.id
    } ) );
  }, [ jobRole, presetTasks, jobRoleId ] );

  useEffect( () => {
    if ( jobRoleId !== jobRole?.id ) setFieldValue( 'task', '' );
  }, [ jobRoleId, jobRole ] );

  useEffect( () => {
    if ( options.length ) {
      setFieldValue( 'task', options[0].value );
    }
  }, [ options ] );

  const onSelectChange = id => {
    setFieldValue( 'task', id );
  };

  return (
    <div className='form-row w-100 select-box'>
      <label>Task</label>
      <StyledSelect
        options={options}
        value={taskId}
        onChange={onSelectChange}
        containerStyle={{
          maxWidth: 'none',
          marginLeft: 0
        }}
        isDisabled={!options?.length}
      />
    </div>
  );
};
