import React from 'react';
import { useParams } from 'react-router-dom';
import { InfoRows } from '../../../components/InfoRows/InfoRows';
import { UserCard } from '../../../components/UserCard/UserCard';
import { useGetJobEventQuery } from '../../../store/apis/eventsApi';
import { formatDateString, formatTimeString } from '../../../utils/dates/dates';
import { getJobLogValues } from '../../../utils/utils';
import Default from './Default';

const Completed = () => {
  const { orderId } = useParams();

  const event = useGetJobEventQuery( orderId );
  const acceptedEmployee = event.data?.JobOffer?.AcceptedEmployee;
  const jobLog = getJobLogValues( event.data );

  return (
    <>
      <Default />
      <InfoRows
        onlyContainerBox
        rows={[
          {
            items: [
              {
                label: 'Worker name',
                element: (
                  <UserCard
                    className='mt-2 justify-content-between'
                    infoBlockClassName='mr-2'
                    avatarPosition='last'
                    employee={acceptedEmployee}
                  />
                ),
              },
              {
                label: 'Job role',
                value: event.data?.JobRole.name,
              },
              {
                label: 'Date',
                value: formatDateString( jobLog.updatedAt ),
              },
            ],
          },
          {
            items: [
              { label: 'Start time', value: formatTimeString( event.data?.start ) },
              { label: 'End time', value: formatTimeString( event.data?.end ) },
              {
                label: 'Lunch break',
                value: jobLog.Employee_LunchBreak || 0,
              },
            ],
            props: {
              className: 'mt-3',
            },
          },
          {
            items: [
              {
                label: 'Worker comment',
                element: (
                  <div className='form-control job-log-comment'>
                    {jobLog?.Employee_Comment}
                  </div>
                ),
              },
            ],
            props: {
              className: 'mt-3',
            },
          },
          {
            items: [
              {
                label: 'Customer comment',
                element: (
                  <div className='form-control job-log-comment'>
                    {jobLog?.Customer_Comment}
                  </div>
                ),
              },
            ],
            props: {
              className: 'mt-3',
            },
          },
        ]}
      />
    </>
  );
};

export default Completed;
