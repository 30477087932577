import React from 'react';
import Modal from 'react-modal';
import invitationAPI from '../../../api/invitationAPI';
import InviteForm from './InviteForm';

const InviteModal = props => {
  const { role, data, modalIsOpen, closeModal, maxWidth } = props;

  const customStyles = {
    content: {
      maxWidth: maxWidth,
    },
  };

  const submitInvite = values => {
    invitationAPI.inviteJoblinkUser( values );
    closeModal();
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={() => null}
      onRequestClose={() => null}
      style={customStyles}
      ariaHideApp={false}
      contentLabel={`Invite new Joblink ${role}`}
    >
      <span
        className='icon-close'
        onClick={closeModal}
      />
      <div className='modal-head'>
        <div className='modal-title'>{`Invite new Joblink ${role}`}</div>
      </div>
      <div className='modal-body'>
        <InviteForm
          role={role}
          data={data}
          closeModal={closeModal}
          onSubmit={submitInvite}
        />
      </div>
    </Modal>
  );
};

export default InviteModal;
