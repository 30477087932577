import ReactDatePicker from 'react-datepicker';
import { RiArrowLeftCircleLine, RiArrowRightCircleLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { LabeledField } from '../../../components/LabeledField';
import { StyledSelect } from '../../../components/StyledSelect';
import { useGetCostCentersQuery } from '../../../store/apis/costCentersApi';
import { operationsPageActions } from '../../../store/slices';
import styles from '../OperationsScreen.module.sass';
import { CalendarButton, Search } from './controls';

export const OperationScreenControls = () => {
  const { currentWeekStart, costCenter, weeksCount, viewMode } = useSelector(
    state => state.operationsPage
  );

  const dispatch = useDispatch();

  const { data: costCenters } = useGetCostCentersQuery();

  const setCurrentWeekStart = date => {
    dispatch( operationsPageActions.setCurrentWeekStart( date.startOf( 'week' ) ) );
  };
  const setViewMode = mode => dispatch( operationsPageActions.setViewMode( mode ) );
  const setCostCenter = costCenter =>
    dispatch( operationsPageActions.setCostCenter( costCenter ) );
  const setWeeksCount = count => dispatch( operationsPageActions.setWeeksCount( count ) );

  const goPrev = () => {
    setCurrentWeekStart( currentWeekStart.minus( { week: 1 } ) );
  };
  const goNext = () => {
    setCurrentWeekStart( currentWeekStart.plus( { week: 1 } ) );
  };

  return (
    <div className={styles['controls']}>
      <div className={styles['buttons']}>
        <LabeledField label={'Cost Center'}>
          <StyledSelect
            options={[ { value: 'all', label: 'All Cost Centers' } ].concat(
              costCenters?.map( costCenter => ( {
                label: costCenter.name,
                value: costCenter.id
              } ) ) || []
            )}
            value={costCenter}
            onChange={setCostCenter}
            containerStyle={{ height: 44 }}
          />
        </LabeledField>
        <LabeledField
          label={'Filter by'}
          className={styles['select']}
        >
          <Search />
        </LabeledField>
        <ReactDatePicker
          customInput={<CalendarButton />}
          onSelect={setCurrentWeekStart}
          wrapperClassName={styles['button']}
          popperClassName={styles['button']}
        />
        <RiArrowLeftCircleLine
          onClick={goPrev}
          className={styles['button']}
        />
        <RiArrowRightCircleLine
          onClick={goNext}
          className={styles['button']}
        />
        <LabeledField
          label={'View'}
          className={styles['select']}
        >
          <StyledSelect
            options={[
              { value: 2, label: 'Two Weeks' },
              { value: 3, label: 'Three Weeks' }
            ]}
            value={weeksCount}
            onChange={setWeeksCount}
            containerStyle={{ height: 44 }}
          />
        </LabeledField>
        <LabeledField
          label={'Group By'}
          className={styles['select']}
        >
          <StyledSelect
            options={[
              { value: 'employee', label: 'Employee' },
              { value: 'jobRole', label: 'Job Role' }
            ]}
            value={viewMode}
            onChange={setViewMode}
            containerStyle={{ height: 44 }}
          />
        </LabeledField>
      </div>
    </div>
  );
};
