import { concat } from '../../utils/utils';

export const InfoColumn = ( { className, label, value, children } ) => (
  <div className={concat( 'info-col', className )}>
    {label && <span className='info-col__label'>{label}</span>}
    {typeof value == 'string' ? (
      <strong className='info-col__title'>{value}</strong>
    ) : (
      value
    )}
    {children}
  </div>
);
