import { useMemo } from 'react';
import { CompanyReporting, CostCentersReporting, EmployeeReporting } from './components';

export const ReportingScreen = ( { type = 'company' } ) => {
  const reportingComponent = useMemo( () => {
    if ( type === 'company' ) {
      return <CompanyReporting />;
    } else if ( type === 'employee' ) {
      return <EmployeeReporting />;
    } else if ( type === 'costCenter' ) {
      return <CostCentersReporting />;
    }
    return null;
  }, [ type ] );

  return (
    <div className='app-content'>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12 col-lg-6'>
            <h1 className='title'>Reporting</h1>
          </div>
          <div className='col-12'>{reportingComponent}</div>
        </div>
      </div>
    </div>
  );
};
