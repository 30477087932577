import React, { useContext } from 'react';
import { ReactComponent as CloseIcon } from '../../../../../assets/images/chat-close.svg';
import { Button } from '../../../../../components/Button';
import { Input } from '../../../../GlobalComponents/Elements';
import { ChatContext } from '../../ChatScreen';
import './inputSend.sass';

const InputSend = ( { value, onChange, sendMessage } ) => {
  const { replyTo, discardReply } = useContext( ChatContext );

  return (
    <>
      {replyTo && (
        <div className='reply-to'>
          {replyTo.text}
          <CloseIcon onClick={discardReply} />
        </div>
      )}
      <div className='input-send'>
        <Input
          modificators={{
            width: 'full',
            marginBtn: true,
          }}
          value={value}
          onChange={onChange}
        />

        <Button
          type='green'
          onClick={sendMessage}
        >
          Send
        </Button>
      </div>
    </>
  );
};

export default InputSend;
