import { NavLink, useHistory, useParams } from 'react-router-dom';
import { ButtonWithConfirmation } from '../../components/ButtonWithConfirmation/ButtonWithConfirmation';
import ErrorBoundary from '../../components/ErrorBoundary';
import LinkBack from '../../components/LinkBack';
import {
  useDeleteCustomerUserMutation,
  useGetCustomerUserQuery
} from '../../store/apis/customerUsersApi';

export const CustomerUserScreen = () => {
  const { id } = useParams();
  const history = useHistory();

  const { data: customerUser, isError } = useGetCustomerUserQuery( id );
  const [ deleteUser ] = useDeleteCustomerUserMutation();

  if ( isError ) history.goBack();
  if ( !customerUser ) return null;

  const deleteAccount = () => {
    deleteUser( id )
      .unwrap()
      .then( () => {
        history.goBack();
      } );
  };

  return (
    <ErrorBoundary>
      <div className='app-content'>
        <div className='container-fluid'>
          <div className='row mb-3'>
            <div className='col-12'>
              <div>
                <LinkBack title='Back to Customer Users' />
              </div>
              <div className='d-flex flex-wrap align-items-center justify-content-between'>
                <div className='title-box mt-4 mt-md-3'>
                  <h1 className='title error-title mb-0'>
                    {customerUser.firstName} {customerUser.lastName}
                  </h1>
                  <span className='title-badge mt-3 mt-md-0 ml-3'>
                    Customer {customerUser.class}
                  </span>
                </div>

                <ButtonWithConfirmation
                  onConfirm={deleteAccount}
                  confirmLabel='Delete'
                  text='Delete account'
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <div className='white-shadow-box pb-3 mb-1'>
                <div className='info-cols'>
                  <div className='info-col'>
                    <span className='info-col__label'>Email</span>
                    <strong className='info-col__title'>{customerUser.email}</strong>
                  </div>
                  <div className='info-col'>
                    <span className='info-col__label'>Phone</span>
                    <strong className='info-col__title'>{customerUser.phone}</strong>
                  </div>
                </div>
                <div className='mt-4 mt-md-2'>
                  <NavLink
                    to={`/customer-users/${id}/edit`}
                    className='btn btn--smd-block btn--light-green'
                  >
                    Edit info
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};
