import { useMemo, useState } from 'react';
import { useGetJobEventsPolling } from '../../hooks';
import ErrorBoundary from '../ErrorBoundary';
import { Spinner } from '../Spinner';
import { UnprocessedEvent } from './Order';
import styles from './UnprocessedEvents.module.scss';
import { UnprocessedEventsHoverWindow } from './UnprocessedEventsHoverWindow';

export const UnprocessedEvents = ( { filter } ) => {
  const { data: events, isLoading } = useGetJobEventsPolling();

  const [ hoverEvent, setHoverEvent ] = useState();
  const [ hoverOffsetTop, setHoverOffsetTop ] = useState( 0 );
  const [ scrollTop, setScrollTop ] = useState( 0 );

  const visibleEvents = useMemo( () => {
    if ( !events ) return [];
    let tmp = events.filter( event => event.status === 'unprocessed' );
    if ( filter ) tmp = filter( tmp );
    tmp = tmp.sort( ( a, b ) => ( new Date( a.start ) > new Date( b.start ) ? 1 : -1 ) );
    return tmp;
  }, [ filter, events ] );

  if ( !events || isLoading ) return <Spinner />;

  const onEventMouseEnter = ( { event, top } ) => {
    setHoverEvent( event );
    setHoverOffsetTop( top );
  };
  const onEventMouseLeave = () => setHoverEvent( undefined );

  const onListScroll = scrollEvent => {
    setScrollTop( scrollEvent.target.scrollTop );
  };

  return (
    <ErrorBoundary>
      <div
        className={styles['orders-list']}
        onScroll={onListScroll}
      >
        {visibleEvents.map( event => (
          <UnprocessedEvent
            key={event.id}
            event={event}
            onMouseEnter={onEventMouseEnter}
            onMouseLeave={onEventMouseLeave}
          />
        ) )}
        <UnprocessedEventsHoverWindow
          event={hoverEvent}
          offsetTop={hoverOffsetTop - scrollTop}
        />
      </div>
    </ErrorBoundary>
  );
};
