import { Field, Form, Formik } from 'formik';
import React, { memo } from 'react';
import { fieldErrorClass } from '../../../constants';
import { useCreateCostCenterMutation } from '../../../store/apis/costCentersApi';
import validators from '../../../utils/validators';

const CostCenterCreatingForm = ( { closeModal } ) => {
  const [ createCostCenter ] = useCreateCostCenterMutation();

  const initialValues = {
    name: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={values => {
        createCostCenter( values.name )
          .unwrap()
          .then( () => {
            closeModal();
          } );
      }}
    >
      {( { values, handleChange, handleSubmit, errors, touched, isSubmitting } ) => (
        <Form
          onSubmit={handleSubmit}
          className='form'
        >
          <div className='row'>
            <div className='col-12'>
              <div className='form-group'>
                <div className='form-row w-100'>
                  <label htmlFor='name'>Cost Center Name</label>
                  <Field
                    className={`form-control ${
                      errors.name && touched.name && fieldErrorClass
                    }`}
                    type='text'
                    id='name'
                    name='name'
                    value={values.name}
                    onChange={handleChange}
                    validate={validators.required}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='form-footer'>
            <div className='form-row mt-40 mb-10'>
              <button
                className='btn btn--green btn--block mb-2'
                type='submit'
                disabled={isSubmitting}
              >
                Save
              </button>
              <button
                type='button'
                className='btn btn--default btn--block'
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default memo( CostCenterCreatingForm );
