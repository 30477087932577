export const UserCard = ( {
  employee,
  description,
  className,
  avatar = true,
  avatarPosition = 'first',
  infoBlockClassName = 'ml-2',
  nameBold,
} ) => {
  return (
    <div
      className={[
        'user-card',
        className,
        avatarPosition === 'last' ? 'flex-row-reverse' : undefined,
      ].join( ' ' )}
    >
      {avatar && (
        <img
          className='user-card__avatar avatar'
          src={employee?.photoUrl}
        />
      )}
      <div className={infoBlockClassName}>
        <div className='info-col__label user-card__name'>
          {nameBold ? (
            <b>
              {employee?.firstName} {employee?.lastName}
            </b>
          ) : (
            `${employee?.firstName} ${employee?.lastName}`
          )}
        </div>
        {description && (
          <small className='text-success user-card__desc'>{description}</small>
        )}
      </div>
    </div>
  );
};
