import { useEffect, useState } from 'react';
import { useGetPresetTasksQuery } from '../../../../store/apis/presetTasksApi';
import { Skill } from './Skill';

export const Skills = ( { checked, onChange } ) => {
  const presetTasks = useGetPresetTasksQuery();

  const [ checkedSkillsIds, setCheckedSkillsIds ] = useState( checked || [] );

  useEffect( () => {
    onChange( checkedSkillsIds );
  }, [ checkedSkillsIds, onChange ] );

  const onSkillChange = skill => {
    if ( checkedSkillsIds.includes( skill.id ) ) {
      setCheckedSkillsIds( checkedSkillsIds.filter( id => id !== skill.id ) );
    } else {
      setCheckedSkillsIds( checkedSkillsIds.concat( skill.id ) );
    }
  };

  if ( !presetTasks.data ) return null;

  return (
    <div className='employee-skills-list'>
      {presetTasks.data.map( task =>
        task.Skills.map( ( skill, index ) => (
          <Skill
            key={index}
            checked={checkedSkillsIds.includes( skill.id )}
            skill={skill}
            onChange={onSkillChange}
          />
        ) )
      )}
    </div>
  );
};
