import { ReactComponent as DislikeIcon } from '../../assets/images/dislike.svg';
import { ReactComponent as LikeIcon } from '../../assets/images/like.svg';
import './JobRating.sass';

export const JobRating = ( { value, onChange } ) => {
  const onClick = rating => () => onChange( value === rating ? '' : rating );

  return (
    <div className='job-rating'>
      <div
        className='good'
        onClick={onClick( 'good' )}
      >
        Good
        {value === 'good' && <LikeIcon />}
      </div>
      <div
        className='bad'
        onClick={onClick( 'bad' )}
      >
        Bad
        {value === 'bad' && <DislikeIcon />}
      </div>
    </div>
  );
};
