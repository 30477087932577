import { mainApi } from './mainApi';

export const costCentersApi = mainApi.injectEndpoints( {
  endpoints: build => ( {
    getCostCenters: build.query( {
      query: () => ( {
        url: '/costCenters',
      } ),
      providesTags: [ 'costCenters' ],
    } ),
    createCostCenter: build.mutation( {
      query: name => ( {
        url: '/costCenters',
        method: 'post',
        body: { name },
      } ),
      invalidatesTags: [ 'costCenters' ],
    } ),
    updateCostCenter: build.mutation( {
      query: ( { id, name } ) => ( {
        url: `/costCenters/${id}`,
        method: 'put',
        body: { name },
      } ),
      invalidatesTags: [ 'costCenters' ],
    } ),
  } ),
} );

export const {
  useGetCostCentersQuery,
  useCreateCostCenterMutation,
  useUpdateCostCenterMutation,
} = costCentersApi;
