import { useFormikContext } from 'formik';
import { StyledSelect } from '../../../../components/StyledSelect';
import { useGetCostCentersQuery } from '../../../../store/apis/costCentersApi';

export const CostCenterField = () => {
  const { values } = useFormikContext();
  const { data: costCenters } = useGetCostCentersQuery();

  const costCenterId = +values.costCenter;

  return (
    <div className='form-row w-100 select-box'>
      <label>Cost Center</label>
      <StyledSelect
        options={costCenters?.map( costCenter => ( {
          label: costCenter.name,
          value: costCenter.id
        } ) )}
        value={costCenterId}
        isDisabled
        onChange={() => null}
        containerStyle={{
          marginLeft: 0,
          maxWidth: 'none'
        }}
      />
    </div>
  );
};
