import { useMemo, useState } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Card from '../../components/Card';
import ErrorBoundary from '../../components/ErrorBoundary';
import Legends from '../../components/Legend';
import { OperationsElement } from '../../components/OperationsElement';
import { UnprocessedEvents } from '../../components/Orders/UnprocessedEvents';
import { Spinner } from '../../components/Spinner';
import { useGetJobEventsPolling } from '../../hooks';
import { eventListingActions } from '../../store/slices';
import { PieChartBgColor } from '../../theme';
import {
  filterEventsByStatus,
  filterEventsByStatuses,
  filterEventsForPerformance,
  filterExceptEventByStatus,
  filterFilledEventsForChart,
  filterJobLogInProgressEvents,
  filterJobLogMissingEvents
} from '../../utils/filters/eventsFilters';
import {
  currentEventsSelectOptions,
  performanceSelectOptions,
  unprocessedEventsSelectOptions
} from './data';

const DashboardScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const events = useGetJobEventsPolling();

  const [ unporcessedEventsFilter, setUnprocessedEventsFilter ] = useState(
    unprocessedEventsSelectOptions[0]
  );
  const [ currentEventsFilter, setCurrentEventsFilter ] = useState(
    currentEventsSelectOptions[0]
  );
  const [ performanceFilter, setPerformanceFilter ] = useState( performanceSelectOptions[0] );

  const currentEvents = useMemo( () => {
    if ( !events.data ) return [];
    return currentEventsFilter.filter( events.data );
  }, [ currentEventsFilter, events.data ] );

  const performanceEvents = useMemo( () => {
    if ( !events.data ) return [];
    return filterEventsForPerformance( performanceFilter.filter( events.data ) );
  }, [ events.data, performanceFilter ] );

  const setEventListingFilters = ( start, end, status ) => () => {
    dispatch( eventListingActions.setStartDate( start ) );
    dispatch( eventListingActions.setEndDate( end ) );
    dispatch( eventListingActions.setStatusFilter( status ) );
  };

  const goToListingWithFilters = ( start, end, status ) => () => {
    setEventListingFilters( start, end, status )();
    history.push( '/event-listing' );
  };

  const goToProcessingWithFilters = ( start, end, status ) => () => {
    if ( start ) dispatch( eventListingActions.setProcessingStartDate( start ) );
    if ( end ) dispatch( eventListingActions.setProcessingEndDate( end ) );
    if ( status ) dispatch( eventListingActions.setStatusFilterJobProcessing( status ) );
    history.push( '/job-logs-processing' );
  };

  const onLegendUnitClick = ( filter, statusOption ) =>
    goToListingWithFilters( filter.start, filter.end, statusOption );

  const onLegendLogUnitClick = status =>
    goToProcessingWithFilters( performanceFilter.start, performanceFilter.end, status );

  const currentEventsChartData = useMemo(
    () => [
      {
        title: 'Unprocessed',
        value: filterEventsByStatus( currentEvents, 'unprocessed' ).length,
        color: '#FACC15',
        onClick: onLegendUnitClick( currentEventsFilter, {
          label: 'Unprocessed',
          value: 'unprocessed'
        } )
      },
      {
        title: 'Open',
        value: filterEventsByStatus( currentEvents, 'open' ).length,
        color: '#818CF8',
        onClick: onLegendUnitClick( currentEventsFilter, { label: 'Open', value: 'open' } )
      },
      {
        title: 'Filled',
        value: filterEventsByStatus( currentEvents, 'filled' ).length,
        color: '#4ADE80',
        onClick: onLegendUnitClick( currentEventsFilter, {
          label: 'Filled',
          value: 'filled'
        } )
      },
      {
        title: 'Failed',
        value: filterEventsByStatus( currentEvents, 'failed' ).length,
        color: '#F87171',
        onClick: onLegendUnitClick( currentEventsFilter, {
          label: 'Failed',
          value: 'failed'
        } )
      },
      {
        title: 'Canceled',
        value: filterEventsByStatus( currentEvents, 'canceled' ).length,
        color: '#FB923C',
        onClick: onLegendUnitClick( currentEventsFilter, {
          label: 'Canceled',
          value: 'canceled'
        } )
      },
      {
        title: 'Total',
        value: filterExceptEventByStatus( currentEvents, 'canceled' ).length,
        exclude: true
      }
    ],
    [ currentEvents ]
  );

  const performanceEventsChartData = useMemo(
    () => [
      {
        title: 'Filled',
        value: filterFilledEventsForChart( performanceEvents ).length,
        color: '#4ADE80',
        onClick: onLegendUnitClick( performanceFilter, {
          label: 'Filled',
          value: 'filled'
        } )
      },
      {
        title: 'Job Log Missing',
        value: filterJobLogMissingEvents( performanceEvents ).length,
        color: '#FACC15',
        className: 'small',
        onClick: onLegendLogUnitClick( {
          value: 'employee',
          label: 'Waiting for employee log'
        } )
      },
      {
        title: 'Job Log in Progress',
        value: filterJobLogInProgressEvents( performanceEvents ).length,
        color: '#818CF8',
        className: 'small',
        onClick: onLegendLogUnitClick( {
          value: 'joblink',
          label: 'Waiting for joblink approval'
        } )
      },
      {
        title: 'Failed',
        value: filterEventsByStatus( performanceEvents, 'failed' ).length,
        color: '#F87171',
        onClick: onLegendUnitClick( performanceFilter, {
          label: 'Failed',
          value: 'failed'
        } )
      },
      {
        title: 'Total',
        value: performanceEvents.length,
        exclude: true
      }
    ],
    [ performanceEvents ]
  );

  return (
    <ErrorBoundary>
      <div className='app-content'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <h1 className='title'>Dashboard</h1>
            </div>
          </div>
          <div className='row'>
            <div className='col-12 col-xl-4 mb-5 mb-lg-4'>
              <Card
                title='Unprocessed'
                selectFilterState={unporcessedEventsFilter}
                setSelectFilterState={setUnprocessedEventsFilter}
                selectOptions={unprocessedEventsSelectOptions}
              >
                <div className='card-body'>
                  <UnprocessedEvents filter={unporcessedEventsFilter.filter} />
                </div>
                <div className='card-footer'>
                  <div className='btn-group'>
                    <Link
                      className='btn btn--light-green btn--block'
                      to='/event-listing'
                    >
                      Show all
                    </Link>
                    <Link
                      className='btn btn--light-green btn--block'
                      to='/new-order'
                    >
                      Create new
                    </Link>
                  </div>
                </div>
              </Card>
            </div>
            <div className='col-12 col-xl-4 mb-5 mb-lg-4'>
              <Card
                title='Current events'
                selectFilterState={currentEventsFilter}
                setSelectFilterState={setCurrentEventsFilter}
                selectOptions={events.isLoading ? undefined : currentEventsSelectOptions}
              >
                {events.isLoading ? (
                  <Spinner />
                ) : (
                  <>
                    <div className='card-body'>
                      <div className='graphic'>
                        <PieChart
                          data={currentEventsChartData.filter( item => !item.exclude )}
                          lineWidth={50}
                          background={PieChartBgColor}
                          animate={true}
                        />

                        <div className='custom-chart'>
                          <b className='custom-chart__count'>
                            {Math.round(
                              ( filterEventsByStatuses( currentEvents, [ 'filled' ] ).length /
                                filterEventsByStatuses( currentEvents, [ 'filled', 'open' ] )
                                  .length || 0 ) * 100
                            )}
                            %
                          </b>
                          <span className='custom-chart__desc'>Filled</span>
                        </div>
                      </div>
                      <Legends units={currentEventsChartData} />
                    </div>
                    <div className='card-footer'>
                      <Link
                        className='btn btn--light-green btn--block'
                        to='/event-listing'
                      >
                        Show all
                      </Link>
                    </div>
                  </>
                )}
              </Card>
            </div>
            <div className='col-12 col-xl-4 mb-5 mb-lg-4'>
              <Card
                title='Performance'
                selectFilterState={performanceFilter}
                setSelectFilterState={setPerformanceFilter}
                selectOptions={events.isLoading ? undefined : performanceSelectOptions}
              >
                {events.isLoading ? (
                  <Spinner />
                ) : (
                  <>
                    <div className='card-body'>
                      <div className='graphic'>
                        <PieChart
                          data={performanceEventsChartData.filter( item => !item.exclude )}
                          lineWidth={50}
                          background={PieChartBgColor}
                          animate={true}
                        />
                        <div className='custom-chart'>
                          <b className='custom-chart__count'>
                            {Math.round(
                              ( filterEventsByStatuses( performanceEvents, [ 'filled' ] )
                                .length /
                                filterEventsByStatuses( performanceEvents, [
                                  'filled',
                                  'failed'
                                ] ).length || 0 ) * 100
                            )}
                            %
                          </b>
                          <span className='custom-chart__desc'>Filled</span>
                        </div>
                      </div>
                      <Legends units={performanceEventsChartData} />
                    </div>
                    <div className='card-footer'>
                      <Link
                        className='btn btn--light-green btn--block'
                        to='/event-listing'
                      >
                        Show all
                      </Link>
                    </div>
                  </>
                )}
              </Card>
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <OperationsElement />
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default DashboardScreen;
