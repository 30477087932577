import React, { useEffect, useMemo, useState } from 'react';
import { useGetJoblinkGroupsQuery } from '../../../store/apis/chatApi';
import { useGetProfileQuery } from '../../../store/apis/currentUserApi';

import './filterMessage.sass';

export const JoblinkGroups = ( { onChange, unreadCount } ) => {
  const { data: groups } = useGetJoblinkGroupsQuery();
  const { data: profile } = useGetProfileQuery();

  const [ active, setActive ] = useState( 'all' );
  const toggleActive = value => () => setActive( value );

  const visibleGroups = useMemo( () => {
    if ( !profile ) return [];
    const visible = groups
      ? groups.filter( group => profile.profile.JoblinkGroupIds.includes( group.id ) )
      : [];
    return [ { id: 'all', name: 'All' }, ...visible, { id: 'unread', name: 'Unread' } ];
  }, [ groups, profile ] );

  useEffect( () => {
    if ( onChange ) onChange( active );
  }, [ active ] );

  return (
    <div className='filter-message'>
      <ul>
        {visibleGroups.map( ( item, index ) => {
          return (
            <li
              key={index}
              className={active === item.id ? 'filter-active' : undefined}
              onClick={toggleActive( item.id )}
            >
              {item.name}
              {item.id === 'unread' ? <span>{unreadCount}</span> : null}
            </li>
          );
        } )}
      </ul>
    </div>
  );
};
