import { concat } from '../../../../utils/utils';
import styles from '../../OperationsScreen.module.sass';

export const BadgeHoverBox = ( { className, children, employee, date } ) => (
  <div className={concat( styles['hover-box'], className )}>
    <div className={styles['header']}>
      <div className={styles['time']}>
        <span>{date.toFormat( 'ccc' )}</span>
        <span>{date.toFormat( 'LLLL dd' )}</span>
        <span>{date.toFormat( 'yyyy' )}</span>
      </div>
      {employee && (
        <div className={styles['employee']}>
          {employee.lastName}, {employee.firstName}
        </div>
      )}
    </div>
    {children}
  </div>
);
