export const Skill = ( { skill, onChange, checked } ) => {
  const onInputChange = () => {
    onChange( skill );
  };

  return (
    <div className='checkboxes-item'>
      <label className='custom-checkbox'>
        <input
          type='checkbox'
          checked={checked}
          onChange={onInputChange}
        />
        <span>{skill.name}</span>
      </label>
    </div>
  );
};
