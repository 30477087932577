import React from 'react';
import Modal from 'react-modal';
import { InviteCustomerForm } from './InviteForm';

export const InviteCustomerModal = ( { modalIsOpen, closeModal, maxWidth } ) => {
  const customStyles = {
    content: {
      maxWidth: maxWidth
    }
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={() => null}
      onRequestClose={() => null}
      style={customStyles}
      ariaHideApp={false}
      contentLabel={'Invite new Customer User'}
    >
      <span
        className='icon-close'
        onClick={closeModal}
      />
      <div className='modal-head'>
        <div className='modal-title'>{'Invite new Customer User'}</div>
      </div>
      <div className='modal-body'>
        <InviteCustomerForm closeModal={closeModal} />
      </div>
    </Modal>
  );
};
