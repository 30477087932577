import { mainApi } from './mainApi';

export const resetPasswordApi = mainApi.injectEndpoints( {
  endpoints: build => ( {
    resetPasswordJoblink: build.mutation( {
      query: email => ( {
        url: '/reset-password/JoblinkUser/request',
        method: 'POST',
        body: { email },
        headers: {
          frontendurl: `${window.location.origin}/reset-password?role=joblink&token=`,
        },
      } ),
      invalidatesTags: ( res, err, arg ) =>
        err ? [] : [ { type: 'joblink-reset', id: arg } ],
    } ),
    resetPasswordCustomer: build.mutation( {
      query: email => ( {
        url: '/reset-password/CustomerUser/request',
        method: 'POST',
        body: { email },
        headers: {
          frontendurl: window.location.origin,
        },
      } ),
      invalidatesTags: ( res, err, arg ) =>
        err ? [] : [ { type: 'customer-reset', id: arg } ],
    } ),
    resetPasswordEmployee: build.mutation( {
      query: email => ( {
        url: '/reset-password/Employee/request',
        method: 'POST',
        body: { email },
        headers: {
          frontendurl: `${window.location.origin}/reset-password?role=employee&token=`,
        },
      } ),
      invalidatesTags: ( res, err, arg ) =>
        err ? [] : [ { type: 'joblink-reset', id: arg } ],
    } ),
  } ),
} );

export const {
  useResetPasswordJoblinkMutation,
  useResetPasswordCustomerMutation,
  useResetPasswordEmployeeMutation,
} = resetPasswordApi;
