import { useMemo } from 'react';
import { ReactComponent as FileIcon } from '../../assets/images/pdf-file.svg';

export const EmployeeCertificate = ( { certificate, style } ) => {
  const isImage = useMemo( () => {
    return /(png|jpg|jpeg)/i.test( certificate.url );
  }, [ certificate ] );

  return isImage ? (
    <a
      href={certificate.url}
      className='certificate-box'
      key={certificate.id}
      target='_blank'
      rel='noreferrer'
      style={style}
    >
      <img
        src={certificate.url}
        alt='Employee`s certificate'
      />
    </a>
  ) : (
    <a
      href={certificate.url}
      target='_blank'
      rel='noreferrer'
    >
      <FileIcon />
    </a>
  );
};
