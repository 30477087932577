import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTokenFromCookie } from '../api/instances';
import { _baseURL } from '../constants';
import { chatApi } from '../store/apis/chatApi';
import { socketActions } from '../store/slices';

export const useMessagesListener = () => {
  const dispatch = useDispatch();

  const { isMessagesSocketOpen } = useSelector( state => state.socket );

  const onDataReceived = raw => {
    try {
      const parsed = JSON.parse( raw );
      if ( parsed.reason === 'changed' ) {
        dispatch( chatApi.util.invalidateTags( [ { type: 'conversations', id: parsed.id } ] ) );
        dispatch( chatApi.util.invalidateTags( [ { type: 'conversations', id: 'all' } ] ) );
      }
    } catch {
      console.log( 'could not handle socket data' );
      console.log( { raw } );
    }
  };

  const connect = () => {
    const url = `ws://${_baseURL.replace( 'http://', '' )}/conversations/listen`;
    const messagesSocket = new WebSocket( url );

    const onMessage = event => {
      onDataReceived( event.data );
    };
    messagesSocket.addEventListener( 'message', onMessage );
    const accessToken = getTokenFromCookie();
    messagesSocket.addEventListener( 'open', () => {
      messagesSocket.send( JSON.stringify( { authorization: accessToken } ) );
      dispatch( socketActions.setMessagesSocketOpen( true ) );
    } );
    messagesSocket.addEventListener( 'close', () => {
      dispatch( socketActions.setMessagesSocketOpen( false ) );
      connect();
    } );
    messagesSocket.addEventListener( 'error', event => console.log( { error: event } ) );

    return messagesSocket;
  };

  useEffect( () => {
    if ( !isMessagesSocketOpen ) connect();
  }, [] );
};
