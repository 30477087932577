import { OperationsElementHeaderDay } from './OperationsElementHeaderDay';

export const OperationsElementHeader = ( { weeksStarts, locations } ) => (
  <div className='operations-element-header'>
    {weeksStarts.map( ( start, weekIndex ) => (
      <div
        className='week'
        key={weekIndex}
      >
        {Array( 7 )
          .fill()
          .map( ( _, index ) => {
            const day = start.plus( { days: index } );
            return (
              <OperationsElementHeaderDay
                key={index}
                day={day}
                locations={locations}
              />
            );
          } )}
        <div className='day week-number'>
          <span>
            <b>Week</b>
          </span>
          <b>{start.weekNumber}</b>
        </div>
      </div>
    ) )}
  </div>
);
