import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

const initialState = {
  isAuth: !!Cookies.get( 'token' ),
  validated: false,
};

export const appSlice = createSlice( {
  initialState,
  name: 'app',
  reducers: {
    setAuth: ( state, action ) => {
      state.isAuth = action.payload;
    },
    setValidated: ( state, action ) => {
      state.validated = action.payload;
    },
  },
} );

export const appActions = appSlice.actions;
