import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { groupJobEventsByJobRole } from '../../../../utils/helpers';
import styles from '../../OperationsScreen.module.sass';
import { HeaderRow } from '../header';
import { JobRoleGroup } from '../rows/JobRoleGroup';

export const OperationsJobRoleLayout = () => {
  const { allEvents, weeksCount, currentWeekStart } = useSelector(
    state => state.operationsPage
  );

  const groups = useMemo( () => groupJobEventsByJobRole( allEvents ), [ allEvents ] );

  return (
    <div
      className={styles['table']}
      data-weeks={weeksCount}
    >
      <HeaderRow week={currentWeekStart} />
      {groups.map( ( group, index ) => (
        <JobRoleGroup
          key={index}
          jobRole={group.jobRole}
          location={group.jobRole.Location}
          jobRoleGroups={groups}
        />
      ) )}
    </div>
  );
};
