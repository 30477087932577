export const theadData = [
  'Start time',
  'Hours',
  'Location',
  'Job role',
  'Status',
  'Documents',
];

export const filterOptions = [
  { value: 'all', label: 'Show all' },
  { value: 'unprocessed', label: 'Unprocessed' },
  { value: 'open', label: 'Open' },
  { value: 'filled', label: 'Filled' },
  { value: 'failed', label: 'Failed' },
  { value: 'canceled', label: 'Canceled' },
  { value: 'completed', label: 'Completed' },
];

export const select1 = [
  { value: 'option1', label: 'Option 1' },
  { value: 'option2', label: 'Option 2' },
  { value: 'option3', label: 'Option 3' },
];
