import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSendMessageMutation } from '../../../../store/apis/chatApi';
import { ChatContext } from '../ChatScreen';
import './chatBottom.scss';
import InputSend from './InputSend/InputSend';
import ReplyAll from './ReplyAll/ReplyAll';

const ChatBottom = () => {
  const params = useParams();

  const [ sendMessage ] = useSendMessageMutation();

  const [ replyAll, setReplyAll ] = useState( false );
  const [ text, setText ] = useState( '' );

  const { replyTo, discardReply } = useContext( ChatContext );

  const openInputReply = () => setReplyAll( true );

  const onSend = () => {
    sendMessage( {
      id: params.id,
      message: { text: text, ReplyToId: replyTo ? replyTo.id : undefined },
    } ).finally( () => {
      setText( '' );
      discardReply();
    } );
  };

  return (
    <div className='chatBottom'>
      {!replyAll ? (
        <>
          <ReplyAll openInputReply={openInputReply} />
        </>
      ) : (
        <>
          <InputSend
            value={text}
            onChange={setText}
            sendMessage={onSend}
          />
        </>
      )}
    </div>
  );
};

export default ChatBottom;
