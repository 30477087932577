import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import LinkCount from '../../components/LinkCount';
import MenuItemHasChildren from '../../components/MenuItemHasChildren';
import OldSchoolMenuLink from '../../components/OldSchoolMenuLink/OldSchoolMenuLink';
import NoMatch from '../../screens/NoMatch';
import { useGetConversationsListQuery } from '../../store/apis/chatApi';
import { useGetProfileQuery } from '../../store/apis/currentUserApi';
import { isAdmin } from '../../utils/helpers/responce-helpers';
import routes, {
  AccountsSubMenu,
  AdminSettingsSubMenu,
  ReportingSubMenu
} from './routes';

const AppNavigation = () => {
  const profile = useGetProfileQuery();
  const { data: conversations } = useGetConversationsListQuery();

  const unreadConversations = useMemo( () => {
    return ( conversations || [] ).filter(
      conversation =>
        conversation.LastMessage &&
        conversation.LastReadMessageId !== conversation.LastMessage?.id
    );
  }, [ conversations ] );

  const authRole = profile.data?.profile.class;

  return (
    <main>
      <div className='app-nav'>
        <div className='container-fluid'>
          <div className={'row grid-xxl'}>
            <div className={'col-12 col-lg-3 p-0'}>
              <nav className='main-nav'>
                <ul>
                  <li>
                    <OldSchoolMenuLink
                      activeOnlyWhenExact={true}
                      to='/'
                      label='Dashboard'
                    />
                  </li>
                  <li>
                    <OldSchoolMenuLink
                      to='/operations'
                      label='Operations'
                    />
                  </li>
                  <li>
                    <OldSchoolMenuLink
                      to='/event-listing'
                      label='Event listing'
                    />
                  </li>
                  <li>
                    <OldSchoolMenuLink
                      to='/messages'
                      label={
                        <LinkCount
                          name='Messages'
                          count={unreadConversations.length}
                        />
                      }
                    />
                  </li>
                  <MenuItemHasChildren
                    label='Reporting'
                    subMenu={ReportingSubMenu}
                  />
                  <MenuItemHasChildren
                    label='Accounts'
                    subMenu={AccountsSubMenu}
                  />
                  {isAdmin( authRole ) && (
                    <MenuItemHasChildren
                      label='Admin settings'
                      subMenu={AdminSettingsSubMenu}
                    />
                  )}
                </ul>
              </nav>
            </div>
            <div className='col-12 col-lg-9 pr-0 pl-0 mb-3 static'>
              <Switch>
                {routes.map( ( route, index ) => (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                  >
                    {route.component}
                  </Route>
                ) )}
                <Route path='*'>
                  <NoMatch />
                </Route>
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

AppNavigation.propTypes = {
  authRole: PropTypes.string,
  hideNavBar: PropTypes.bool
};

export default AppNavigation;
