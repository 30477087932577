import ReactModal from 'react-modal';
import { ReactComponent as WarningIcon } from '../../../assets/images/warning-icon.svg';
import { Button } from '../../../components/Button';
import './ConfirmationModal.sass';

export const ConfirmationModal = ( { isOpen, close, text, confirm } ) => {
  return (
    <ReactModal
      isOpen={isOpen}
      ariaHideApp={false}
      style={{ content: { maxWidth: 500 } }}
    >
      <div className='confirmation-modal'>
        <WarningIcon />
        <p>{text}</p>
        <div className='confirmation-modal-buttons'>
          <Button
            type='pale-green'
            onClick={close}
          >
            No, go back
          </Button>
          <Button
            type='red'
            onClick={confirm.onClick}
          >
            {confirm.label}
          </Button>
        </div>
      </div>
    </ReactModal>
  );
};
