import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import groupChatIcon from '../../../assets/images/group-chat--icons.svg';
import { formateDateToMessage } from '../../../utils/dates/dates';
import { EditMembersModal } from '../EditMembersModal';
import './dialogsItem.sass';

const DialogsItem = ( { conversation, urgent = false } ) => {
  const { topic, LastMessage, employeeCount, customerUserCount, updatedAt, id } =
    conversation;

  const [ isEditMembersModalOpen, setEditMembersModalOpen ] = useState( false );
  const toggleEditMembersModalOpen = value => () => setEditMembersModalOpen( value );

  const isUnread = useMemo( () => {
    return conversation?.LastMessage?.id !== conversation?.LastReadMessageId;
  }, [ conversation ] );

  const { author, text: lastMessageText } = LastMessage || {};

  const dateMessage = formateDateToMessage( LastMessage?.updatedAt || updatedAt );
  const membersCount = employeeCount + customerUserCount;

  const onMembersClick = e => {
    e.preventDefault();
    e.stopPropagation();
    setEditMembersModalOpen( true );
  };

  return (
    <Link
      to={isEditMembersModalOpen ? '/messages' : `/chat/${id}`}
      className={`dialog-item ${isUnread ? 'dialog-item--online' : ''}`}
    >
      <div className='dialog-item__column'>
        <div className='dialog-item__main '>
          <div className='dialog-item__main--top'>
            <h5 className='dialog-item__subject'>{topic}</h5>

            {urgent ? <span className='dialog-item__urgent'>Urgent</span> : null}
          </div>

          {author && (
            <div className='dialog-item__main--middle'>
              <span className='dialog-item__user'>
                {author.name || `${author.firstName} ${author.lastName}`}
              </span>
              <span className='dialog-item__place'>{author.userType}</span>
            </div>
          )}
          {lastMessageText && <p className='dialog-item__text'>{lastMessageText}</p>}
        </div>
      </div>

      <div
        className='dialog-item__column members'
        onClick={onMembersClick}
      >
        {membersCount > 1 ? (
          <div className='dialog-item__persons'>
            <img src={groupChatIcon} />
            <span>{membersCount}</span>
          </div>
        ) : (
          <span className='dialog-item__private'>private</span>
        )}
      </div>

      <div className='dialog-item__column'>
        <span className='dialog-item__text'>{dateMessage}</span>
      </div>
      {isEditMembersModalOpen && (
        <EditMembersModal
          close={toggleEditMembersModalOpen( false )}
          conversationId={conversation.id}
          isOpen
        />
      )}
    </Link>
  );
};

DialogsItem.propTypes = {
  id: PropTypes.number,
  img: PropTypes.string,
  user: PropTypes.string,
  subject: PropTypes.string,
  textMessage: PropTypes.string,
  place: PropTypes.string,
  dateLastMessage: PropTypes.object,
  online: PropTypes.bool,
  groupChat: PropTypes.number,
  urgent: PropTypes.bool,
};

export default DialogsItem;
