import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import mailIcon from '../../../assets/images/mail-download.svg';
import ErrorBoundary from '../../../components/ErrorBoundary';
import { __resetEmail_ } from '../../../constants';

const ResetDoneScreen = () => {
  const history = useHistory();

  const emailForReset = localStorage.getItem( __resetEmail_ );

  const onDone = () => {
    history.replace( '/' );
  };

  useEffect( () => {
    return () => {
      localStorage.removeItem( __resetEmail_ );
    };
  }, [] );

  return (
    <ErrorBoundary>
      <div className='app-login__head app-login__head--reset-done'>
        <img
          className='main-icon'
          src={mailIcon}
          alt='We’ve sent you email to'
        />
        <p>We’ve sent you email to</p>
        {emailForReset ? (
          <strong className='subtitle'>{emailForReset}</strong>
        ) : (
          <div className='alert alert-warning'>Something went wrong. try again</div>
        )}
      </div>
      <div className='app-login__body app-login__body--reset-done'>
        <div className='custom-alert custom-alert-info'>
          <strong className='custom-alert-head'>
            Please, check your email and follow the instructions
          </strong>
          <p className='custom-alert-body'>
            Note, if you didn’t receive the email, check “Spam” or “Junk” folders
          </p>
        </div>
      </div>
      <div className='app-login__footer'>
        <button
          className='btn btn--block btn--light-green'
          onClick={onDone}
        >
          Done
        </button>
      </div>
    </ErrorBoundary>
  );
};

export default ResetDoneScreen;
