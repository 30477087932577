import React, { memo, useEffect, useState } from 'react';
import { NavLink, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import ReactSelect from 'react-select';
import ErrorBoundary from '../../../components/ErrorBoundary';
import LocationsLegend from '../../../components/LocationsLegend';
import {
  useGetCompanyQuery,
  useUpdateCompanyMutation
} from '../../../store/apis/companiesApi';
import { useGetCostCentersQuery } from '../../../store/apis/costCentersApi';
import { useGetProfileQuery } from '../../../store/apis/currentUserApi';
import { selectTheme } from '../../../theme';
import { ChangeStatusModal } from './ChangeStatusModal';

const CompanyDetails = () => {
  // Other Hooks
  const { url } = useRouteMatch();
  const { companyId } = useParams();
  const history = useHistory();

  const company = useGetCompanyQuery( companyId );
  const profile = useGetProfileQuery();
  const { data: costCenters } = useGetCostCentersQuery();
  const [ updateCompany ] = useUpdateCompanyMutation();
  const [ isAccountStatusModalOpen, setAccountStatusModalOpen ] = useState( false );

  const [ accountStatus, setAccountStatus ] = useState();
  const [ targetAccountStatus, setTargetAccountStatus ] = useState();

  useEffect( () => {
    if ( company.data ) setAccountStatus( company.data.status );
  }, [ company.data ] );

  const onAccountStatusChange = option => {
    if ( company.data && option.value === company.data.status ) return;
    if ( option.value === 'active' ) {
      updateCompany( {
        id: companyId,
        fields: { status: option.value },
      } ).then( () => {
        setAccountStatus( option.value );
      } );
    } else {
      setTargetAccountStatus( option.value );
      setAccountStatusModalOpen( true );
    }
  };

  const toggleAccountStatusModal = open => () => setAccountStatusModalOpen( open );

  return (
    <div className='app-content'>
      <ErrorBoundary>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <div className='link-back'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  fill='none'
                >
                  <g
                    stroke='#a1a1aa'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  >
                    <path d='M6 11.996h12' />
                    <path d='M12 18l-6-6 6-6' />
                  </g>
                  <defs />
                </svg>
                <div
                  onClick={history.goBack}
                  className='link-back__title'
                >
                  Back to Companies
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <div className='company-details'>
                {company.data && (
                  <div className='company-details__wrapper'>
                    <h1 className='title error-title mt-4 mt-md-3'>
                      {company.data.name}
                    </h1>
                    <div className='company-details__info white-shadow-box'>
                      <div className='row'>
                        <div className='col-12'>
                          <div className='info-cols mb-5'>
                            <div className='info-col'>
                              <span className='info-col__label'>Business ID</span>
                              <strong className='info-col__title'>
                                {company.data.businessId}
                              </strong>
                            </div>
                            <div className='info-col'>
                              <span className='info-col__label'>Invoicing address</span>
                              <strong className='info-col__title'>
                                <span>{company.data.invoicingAddress_Street},</span>
                                <span>{company.data.invoicingAddress_ZipCode},</span>
                                <span>{company.data.invoicingAddress_City}</span>
                              </strong>
                            </div>
                          </div>

                          <div className='caption__title'>E-Invoicing address</div>
                          <div className='info-cols'>
                            <div className='info-col'>
                              <span className='info-col__label'>Operator Name</span>
                              <strong className='info-col__title'>
                                {company.data.eInvoicingAddress_OperatorName}
                              </strong>
                            </div>
                            <div className='info-col'>
                              <span className='info-col__label'>Operator Code</span>
                              <strong className='info-col__title'>
                                {company.data.eInvoicingAddress_OperatorCode}
                              </strong>
                            </div>
                            <div className='info-col'>
                              <span className='info-col__label'>OVT Code</span>
                              <strong className='info-col__title'>
                                {company.data.eInvoicingAddress_OVTCode}
                              </strong>
                            </div>
                          </div>
                          <div className='info-cols mb-5'>
                            <div className='info-col'>
                              <span className='info-col__label'>Address</span>
                              <strong className='info-col__title'>
                                {company.data.eInvoicingAddress_Address}
                              </strong>
                            </div>
                          </div>

                          <div className='caption__title'>Contact person</div>
                          <div className='info-cols mb-4'>
                            <div className='info-col'>
                              <span className='info-col__label'>Full Name</span>
                              <strong className='info-col__title'>
                                {company.data.contactPerson_FullName}
                              </strong>
                            </div>
                            <div className='info-col'>
                              <span className='info-col__label'>Phone</span>
                              <strong className='info-col__title'>
                                {company.data.contactPerson_Phone}
                              </strong>
                            </div>
                            <div className='info-col'>
                              <span className='info-col__label'>Email</span>
                              <strong className='info-col__title'>
                                {company.data.contactPerson_Email}
                              </strong>
                            </div>
                          </div>
                        </div>

                        <div className='col-12 mt-2 d-flex justify-content-start align-items-end'>
                          <NavLink
                            className='btn btn--light-green'
                            to={`${companyId}/edit-company-info`}
                          >
                            Edit company info
                          </NavLink>
                          {profile.data?.type === 'JoblinkUser' && (
                            <div className='d-flex flex-column ml-3'>
                              <span>Status</span>
                              <ReactSelect
                                value={
                                  accountStatus
                                    ? {
                                        value: accountStatus,
                                        label: accountStatus,
                                      }
                                    : undefined
                                }
                                options={[
                                  { value: 'active', label: 'Active' },
                                  { value: 'locked', label: 'Locked' },
                                ]}
                                theme={selectTheme}
                                styles={{
                                  container: base => ( { ...base, width: 150 } ),
                                  singleValue: base => ( {
                                    ...base,
                                    textTransform: 'capitalize',
                                  } ),
                                }}
                                onChange={onAccountStatusChange}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='company-details__info white-shadow-box'>
                      <div className='row'>
                        <div className='col-12'>
                          <div className='section-title'>Locations</div>
                        </div>
                        {company.data?.Locations?.map( el => (
                          <div
                            key={el.id}
                            className='col-12 col-lg-3 mb-4'
                          >
                            <LocationsLegend
                              dataList={el}
                              costCenters={costCenters}
                            />
                          </div>
                        ) )}
                        <div className='col-12 mt-3'>
                          <NavLink
                            className='btn btn--light-green'
                            to={`${url}/edit-company-locations`}
                          >
                            Edit locations
                          </NavLink>
                        </div>
                      </div>
                    </div>
                    {/* <div className="company-details__info white-shadow-box">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="section-title">Company users</div>
                                                </div>
                                                {[].map(el =>
                                                    <div key={el.id} className="col-12 col-lg-6 mb-4">
                                                        <CompanyUsersLegend name="Customer admins"
                                                                            dataList={[1, 2, 3]}/>
                                                    </div>
                                                )}
                                                <div className="col-12 mt-4">
                                                    <NavLink className="btn btn--light-green btn--disabled" to="/mock">
                                                        Invite user
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div> */}
                  </div>
                )}
              </div>
            </div>
          </div>
          <ChangeStatusModal
            isOpen={isAccountStatusModalOpen}
            targetStatus={targetAccountStatus}
            maxWidth={680}
            closeModal={toggleAccountStatusModal( false )}
            onConfirm={setAccountStatus}
          />
        </div>
      </ErrorBoundary>
    </div>
  );
};

export default memo( CompanyDetails );
