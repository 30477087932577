import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { SelectField } from '../../../../../components/SelectField';
import { Spinner } from '../../../../../components/Spinner';
import { fieldErrorClass } from '../../../../../constants';
import { useGetCompaniesQuery } from '../../../../../store/apis/companiesApi';
import { useGetCostCentersQuery } from '../../../../../store/apis/costCentersApi';
import { useGetProfileQuery } from '../../../../../store/apis/currentUserApi';
import {
  useGetJoblinkUserQuery,
  useUpdateJoblinkUserMutation
} from '../../../../../store/apis/joblinkUsersApi';
import validators from '../../../../../utils/validators';

const EditAccountInfoForm = () => {
  const { id } = useParams();
  const history = useHistory();

  const [ updateUser ] = useUpdateJoblinkUserMutation();
  const { data: user, isLoading } = useGetJoblinkUserQuery( id );
  const { data: profile } = useGetProfileQuery();
  const { data: companies } = useGetCompaniesQuery(
    profile?.profile.class === 'admin' ? undefined : skipToken
  );
  const { data: costCenters } = useGetCostCentersQuery();

  if ( isLoading || !user ) return <Spinner />;

  return (
    <Formik
      initialValues={{
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: user.phone,
        CompanyAccountIds: user.CompanyAccountIds,
        CostCenterId: user.CostCenterId
      }}
      onSubmit={values => {
        updateUser( { id, fields: values } )
          .unwrap()
          .then( () => {
            history.goBack();
          } );
      }}
    >
      {( { values, handleChange, errors, touched, handleSubmit, isSubmitting } ) => (
        <Form
          onSubmit={handleSubmit}
          className='form'
        >
          <div className='white-shadow-box mb-3 pb-3 pt-3'>
            <div className='form-group'>
              <div className='form-row w-100'>
                <label htmlFor='firstName'>First Name</label>
                <Field
                  className={`form-control ${
                    errors.email && touched.email && fieldErrorClass
                  }`}
                  type='text'
                  id='firstName'
                  name='firstName'
                  validate={validators.required}
                  value={values.firstName}
                  onChange={handleChange}
                />
              </div>
              <div className='form-row w-100'>
                <label htmlFor='lastName'>Last name</label>
                <Field
                  className={`form-control ${
                    errors.phone && touched.phone && fieldErrorClass
                  }`}
                  type='text'
                  id='lastName'
                  name='lastName'
                  validate={validators.required}
                  value={values.lastName}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className='form-group'>
              <div className='form-row w-100'>
                <label htmlFor='email'>Email</label>
                <Field
                  className={`form-control ${
                    errors.email && touched.email && fieldErrorClass
                  }`}
                  type='email'
                  id='email'
                  name='email'
                  validate={validators.required}
                  value={values.email}
                  onChange={handleChange}
                />
              </div>
              <div className='form-row w-100'>
                <label htmlFor='lastName'>Phone</label>
                <Field
                  className={`form-control ${
                    errors.phone && touched.phone && fieldErrorClass
                  }`}
                  type='tel'
                  id='phone'
                  name='phone'
                  validate={validators.required}
                  value={values.phone}
                  onChange={handleChange}
                />
              </div>
            </div>

            {user.class !== 'admin' && profile?.profile.class === 'admin' && (
              <>
                <div className='form-group'>
                  <div className='form-row w-100'>
                    <label htmlFor='CostCenterId'>Cost Center</label>
                    <SelectField
                      name='CostCenterId'
                      options={
                        costCenters?.map( costCenter => ( {
                          label: costCenter.name,
                          value: costCenter.id
                        } ) ) || []
                      }
                      containerStyle={{ maxWidth: 'none', margin: 0, height: 'auto' }}
                    />
                  </div>
                </div>
                <div className='form-group'>
                  <div className='form-row w-100'>
                    <label htmlFor='CompanyAccountIds'>Companies</label>
                    <SelectField
                      name='CompanyAccountIds'
                      options={
                        companies?.map( company => ( {
                          label: company.name,
                          value: company.id
                        } ) ) || []
                      }
                      isMulti
                      containerStyle={{ maxWidth: 'none', margin: 0, height: 'auto' }}
                    />
                  </div>
                </div>
              </>
            )}
            <div className='form-footer d-flex justify-content-between align-items-center'>
              <div className='btn-group'>
                <button
                  className='btn btn--green'
                  type='submit'
                  disabled={isSubmitting}
                >
                  Send
                </button>
                <button
                  className='btn btn--default'
                  onClick={history.goBack}
                  type='button'
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditAccountInfoForm;
