import { createSlice } from '@reduxjs/toolkit';

export const createConversationSlice = createSlice( {
  initialState: {
    messageToReply: undefined,
    employeesPrefilled: undefined,
    joblinkGroupId: undefined
  },
  name: 'createConversation',
  reducers: {
    setMessageToReply: ( state, action ) => {
      state.messageToReply = action.payload;
    },
    setEmployeesPrefilled: ( state, action ) => {
      state.employeesPrefilled = Array.isArray( action.payload )
        ? action.payload
        : [ action.payload ];
    },
    setJoblinkGroupId: ( state, action ) => {
      state.joblinkGroupId = action.payload;
    },
    clear: state => {
      state.employeesPrefilled = undefined;
      state.joblinkGroupId = undefined;
      state.messageToReply = undefined;
    }
  }
} );

export const createConversationActions = createConversationSlice.actions;
