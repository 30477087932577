import moment from 'moment';
import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { useParams } from 'react-router-dom';
import { InfoColumns } from '../../../components/InfoColumns/InfoColumns';
import { UserCard } from '../../../components/UserCard/UserCard';
import {
  useGetJobEventQuery,
  useSubmitJobLogMutation
} from '../../../store/apis/eventsApi';
import { getJobLogValues } from '../../../utils/utils';
import Default from './Default';
import { EditLogForm } from './EditLogForm';

const LogWaitingEmployee = () => {
  const [ showHistory, setShowHistory ] = useState( false );

  const { orderId } = useParams();

  const jobEvent = useGetJobEventQuery( orderId );
  const [ submitJobLog ] = useSubmitJobLogMutation();

  const [ isEditModalOpen, setEditModalOpen ] = useState( false );
  const [ isRejectModalOpen, setRejectModalOpen ] = useState( false );
  const [ rejectionComment, setRejectionComment ] = useState( '' );
  const [ editedValues, setEditedValues ] = useState();

  if ( !jobEvent.data ) return null;

  const order = jobEvent.data;
  const acceptedEmployee = order.JobOffer.AcceptedEmployee;
  const jobLog = editedValues || getJobLogValues( order, true );

  const toggleEditModalOpen = open => () => setEditModalOpen( open );
  const toggleRejectModalOpen = open => () => setRejectModalOpen( open );
  const onEditValues = values => setEditedValues( values );
  const onRejectionCommentChange = event => setRejectionComment( event.target.value );

  const onAccept = () => {
    submitJobLog( {
      jobLog,
      id: orderId,
      decision: 'accept',
      comment: '',
    } );
  };

  const onReject = () => {
    submitJobLog( {
      jobLog,
      id: orderId,
      decision: 'reject',
      comment: rejectionComment,
    } );
  };

  return (
    <>
      <Default />
      <div className='row'>
        <InfoColumns
          columns={[
            {
              label: 'Employees',
              element: (
                <UserCard
                  employee={acceptedEmployee}
                  description='Job is done, waiting for job log'
                  className='mt-2'
                />
              ),
            },
            {
              element: (
                <div
                  className='btn btn--green'
                  onClick={() => console.log( 'Send message()' )}
                >
                  Send message
                </div>
              ),
              className: 'align-items-end justify-content-end',
            },
          ]}
        />
        <div className='col-12'>
          <div className='d-flex justify-content-between pt-4 pb-4'>
            <div className='btn-group pb-4 pb-md-0'>
              <button
                className='btn btn--green mr-12'
                onClick={onAccept}
                disabled={!jobLog}
              >
                Accept
              </button>
              <button
                className='btn btn--light-green mr-12'
                onClick={toggleEditModalOpen( true )}
              >
                Edit
              </button>
              <div
                className='btn btn--light-danger'
                onClick={toggleRejectModalOpen( true )}
              >
                Reject
              </div>
            </div>
            <button
              className='btn btn--default'
              onClick={() => setShowHistory( !showHistory )}
            >
              {showHistory ? 'Hide' : 'Show'} document history
            </button>
          </div>
        </div>
        {showHistory ? (
          <div className='col-12'>
            <div className='white-shadow-box mb-1'>
              <div className='mb-3'>Document History</div>
              <div className='info-cols'>
                <div className='info-col'>
                  <span className='info-col__label'>Company created Job Order</span>
                </div>
                <div className='info-col'>
                  <div className='info-col__label'>
                    {moment( order?.createdAt ).format( 'MMMM D YYYY, h:mm a' )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>

      <ReactModal
        isOpen={isEditModalOpen}
        appElement={document.body}
        style={{ content: { maxWidth: 800 } }}
      >
        <div className='modal-head'>
          <div className='modal-title'>Job log editing</div>
        </div>
        <div className='modal-body'>
          <EditLogForm
            onSubmit={onEditValues}
            closeModal={toggleEditModalOpen( false )}
            initialValues={jobLog}
          />
        </div>
      </ReactModal>
      <ReactModal
        isOpen={isRejectModalOpen}
        appElement={document.body}
        style={{ content: { maxWidth: 600 } }}
      >
        <div className='modal-head'>
          <div className='modal-title'>Why the job log is been rejected?</div>
        </div>
        <div className='modal-body'>
          <div className='form-group'>
            <div className='form-row'>
              <label htmlFor='rejection-comment'>Description</label>
              <textarea
                id='rejection-comment'
                value={rejectionComment}
                onChange={onRejectionCommentChange}
                className='form-control'
              />
            </div>
          </div>
        </div>
        <div className='form-footer mt-4 mb--4'>
          <div className='form-group'>
            <div className='form-row'>
              <button
                className='btn btn--green'
                onClick={onReject}
                disabled={!rejectionComment}
              >
                Send
              </button>
            </div>
            <div className='form-row'>
              <button
                className='btn btn--default'
                onClick={toggleRejectModalOpen( false )}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
    </>
  );
};

export default LogWaitingEmployee;
