import { useFormikContext } from 'formik';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { DateTimeField } from './DateTimeField';

export const EndTimeField = () => {
  const { values, setFieldValue } = useFormikContext();

  useEffect( () => {
    setFieldValue(
      'endTime',
      DateTime.fromJSDate( values.startTime ).plus( { hours: 6 } ).toJSDate()
    );
  }, [ values.startTime ] );

  return (
    <DateTimeField
      name='endTime'
      label='End time'
      filterTime={time => {
        return time > values.startTime;
      }}
      filterDate={date => {
        const dateTime = DateTime.fromJSDate( date );
        const start = DateTime.fromJSDate( values.startTime );
        return dateTime.hasSame( start, 'day' ) || dateTime >= start;
      }}
      className='mr-4'
    />
  );
};
