import { skipToken } from '@reduxjs/toolkit/dist/query';
import { memo, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Badge from '../../../components/Badge/Badge';
import { InfoRows } from '../../../components/InfoRows/InfoRows';
import LinkBack from '../../../components/LinkBack';
import { Spinner } from '../../../components/Spinner';
import { useGetJobEventQuery } from '../../../store/apis/eventsApi';
import { useGetJoblinkUserQuery } from '../../../store/apis/joblinkUsersApi';
import { useGetPresetTasksQuery } from '../../../store/apis/presetTasksApi';
import {
  formatDateString,
  formatDiff,
  formatTimeString
} from '../../../utils/dates/dates';

const Default = () => {
  const { orderId } = useParams();
  const { data: event, isLoading } = useGetJobEventQuery( orderId || skipToken );
  const { data: tasks } = useGetPresetTasksQuery();
  const { data: closerUser } = useGetJoblinkUserQuery(
    event.CloserJoblinkUserId || skipToken
  );

  const task = useMemo(
    () => tasks?.filter( el => el.id === event?.Task?.PresetTaskId )?.[0],
    [ event, tasks ]
  );
  const taskSkills = useMemo(
    () =>
      task?.Skills.filter( skill =>
        event.EventSkills.some( eventSkill => eventSkill.SkillId === skill.id )
      ),
    [ event, task ]
  );
  const printHandler = () => window.print();

  const receivedEmployeesLength = event?.JobOffer?.ReceivedEmployees.length;
  const rejectedEmployeesLength = event?.JobOffer?.ReceivedEmployees?.filter(
    item => item.SentEmployees.type === 'rejected'
  ).length;

  if ( isLoading || !event ) return <Spinner />;

  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <LinkBack title='Back to Event listing' />
          <div className='d-flex flex-wrap align-items-center justify-content-between'>
            <div className='title-box mt-3'>
              <h1 className='title title-small d-flex flex-column align-items-start'>
                {event?.JobRole?.Location?.name}
                {receivedEmployeesLength
                  ? ` (${
                      receivedEmployeesLength - rejectedEmployeesLength
                    }/${receivedEmployeesLength})`
                  : undefined}

                <span className='subtitle'>
                  {event?.JobRole?.Location?.address_Street},{' '}
                  {event?.JobRole?.Location?.address_City}
                </span>
              </h1>
              {event.lockedAt && (
                <span className='title-badge title-badge--locked ml-md-3'>
                  {event.LastLocker?.firstName} {event.LastLocker?.lastName}
                </span>
              )}
              <span
                className={`title-badge title-badge--${
                  event.status === 'canceled' || event.status === 'failed'
                    ? 'warning'
                    : null
                } ml-3`}
              >
                Status: {event.status[0].toUpperCase() + event.status.substring( 1 )}
              </span>
              <span className='badge-box ml-3'>
                <Badge status={[ 'order', 'offer', 'log' ].includes( event.phase )}>
                  Order
                </Badge>
                <Badge status={[ 'offer', 'log' ].includes( event.phase )}>Offer</Badge>
                <Badge status={[ 'log' ].includes( event.phase )}>Logs</Badge>
              </span>
            </div>
            <div className='actions mt-4 mt-md-3'>
              <span
                onClick={printHandler}
                className='icon save-pdf'
              />
              <span
                onClick={printHandler}
                className='icon print'
              />
            </div>
          </div>
        </div>
      </div>
      <InfoRows
        className='mt-4'
        rows={[
          {
            items: [
              { label: 'Job Role', value: event?.JobRole?.name },
              { label: 'Task', value: task?.name }
            ]
          },
          {
            items: [
              {
                label: 'Required skills',
                element: (
                  <div className='required-skills mt-2'>
                    {taskSkills?.map( skill => (
                      <div
                        key={skill.id}
                        className='required-skills'
                      >
                        <div className='skill-box pr-2'>{skill.name}</div>
                      </div>
                    ) )}
                  </div>
                )
              },
              {
                label: 'Time',
                element: (
                  <div className='info-col__title'>
                    {formatDateString( event?.start )}
                    <br />
                    {formatTimeString( event?.start )} - {formatTimeString( event?.end )} (
                    {formatDiff( event?.start, event?.end )} hrs)
                  </div>
                )
              }
            ]
          },
          {
            items: [
              {
                label: 'Description',
                element: (
                  <div className='info-col__title info-col__title--textarea'>
                    {event.phase === 'offer'
                      ? event.JobOffer.description
                      : event.JobOrder?.description}
                  </div>
                )
              },
              {
                label: 'Internal Comment',
                element: (
                  <div className='info-col__title info-col__title--textarea'>
                    {event.JobOrder.internalComment}
                  </div>
                )
              }
            ]
          },
          ...( event.CloserJoblinkUserId
            ? [
                {
                  items: [
                    {
                      label: 'Closer Joblink User',
                      value: closerUser
                        ? `${closerUser.firstName} ${closerUser.lastName}`
                        : undefined
                    },
                    {
                      label: 'Close comment',
                      value: event.closeComment || '-'
                    }
                  ]
                }
              ]
            : [] )
        ]}
      />
    </>
  );
};

export default memo( Default );
