import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { ReactComponent as AddIcon } from '../../../assets/images/add-icon.svg';

const TasksForm = ( { onSubmit, data, closeModal } ) => {
  const [ addedSkills, setAddedSkills ] = useState( [] );
  const [ newSkillName, setNewSkillName ] = useState( '' );

  const onAddSkill = () => {
    if ( newSkillName ) {
      setAddedSkills( addedSkills.concat( { id: newSkillName, name: newSkillName } ) );
      setNewSkillName( '' );
    }
  };
  const onNewSkillChange = e => setNewSkillName( e.target.value );

  return (
    <Formik
      initialValues={{ taskName: data.name, skills: data.selected || [] }}
      onSubmit={( values, { setSubmitting } ) => {
        onSubmit( values );
        setSubmitting( false );
        console.log( values );
      }}
    >
      {( { values, handleChange, handleSubmit, isSubmitting } ) => (
        <Form
          onSubmit={handleSubmit}
          className='form'
        >
          <div className='form-row'>
            <label htmlFor='taskName'>Task name</label>
            <Field
              className={'form-control'}
              type='text'
              id='taskName'
              name='taskName'
              value={values.taskName}
              onChange={handleChange}
            />
          </div>
          <div className='form-row w-100'>
            <label>Skills list</label>
            <div className='new-skill mb-2'>
              <input
                name='new-skill'
                className='form-control'
                onChange={onNewSkillChange}
                value={newSkillName}
              />
              <AddIcon
                className='add-icon'
                onClick={onAddSkill}
              />
            </div>
            <div className='checkboxes'>
              <div
                role='group'
                aria-labelledby='checkbox-group'
                style={{
                  maxHeight: 300,
                  overflowY: 'scroll',
                  position: 'relative',
                }}
              >
                {data?.Skills.concat( addedSkills ).map( skill => {
                  return (
                    <div
                      className='checkboxes-item'
                      key={skill.id}
                    >
                      <label className='custom-checkbox'>
                        <Field
                          type='checkbox'
                          name={'skills'}
                          value={skill.name}
                          onChange={handleChange}
                        />
                        <span>{skill.name}</span>
                      </label>
                    </div>
                  );
                } )}
              </div>
            </div>
          </div>
          <div className='form-footer'>
            <div className='form-row mt-40 mb-10'>
              <button
                className='btn btn--green'
                type='submit'
                disabled={isSubmitting}
              >
                Save
              </button>
            </div>
            <div className='form-row mb-0'>
              <div className='btn-group'>
                {/* {!data.creation && <button type="button" className="btn btn--light-danger btn--block"
                                        onClick={onDelete(data.id)}>Delete task
                                </button>} */}
                <button
                  type='button'
                  className='btn btn--default btn--block'
                  onClick={closeModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default TasksForm;
