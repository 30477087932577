export const EmployeeRowName = ( { availability, name, employee } ) => {
  return (
    <>
      <span className={`employee-availability ${availability}`}></span>
      <b className='employee-name'>{name}</b>
      <br />
      <div className='employee-info'>
        <h4>Internal Comment</h4>
        <p>{employee.internalComment || ' - '}</p>
      </div>
      <small>
        {employee.address_Street}, {employee.address_City}
      </small>
    </>
  );
};
