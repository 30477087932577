import { Field, Form, Formik } from 'formik';
import React, { memo, useState } from 'react';
import { fieldErrorClass } from '../../../../constants';
import {
  useGetJobRoleQuery,
  useUpdateJobRoleMutation,
  useUpdateJobRoleTasksMutation
} from '../../../../store/apis/jobRolesApi';
import validators from '../../../../utils/validators';
import { PresetTasksList } from './PresetTasksList';

const JobRoleEditingForm = ( { data, closeModal } ) => {
  const jobRole = useGetJobRoleQuery( data.id );
  const [ updateJobRole ] = useUpdateJobRoleMutation();
  const [ updateJobRoleTasks ] = useUpdateJobRoleTasksMutation();

  const [ checkedTasks, setCheckedTasks ] = useState( [] );

  if ( !jobRole.data ) return null;

  // From Initial Values
  const initialValues = {
    name: jobRole.data.name,
    accessDetails: jobRole.data.accessDetails,
    contactPerson_FullName: jobRole.data.contactPerson_FullName,
    contactPerson_Phone: jobRole.data.contactPerson_Phone,
  };

  const onSubmit = ( values, { setSubmitting } ) => {
    updateJobRole( {
      id: jobRole.data.id,
      data: values,
    } );
    updateJobRoleTasks( {
      id: jobRole.data.id,
      tasks: checkedTasks.map( task => ( {
        PresetTaskId: task.taskId,
        SkillIds: task.skillIds,
      } ) ),
    } );
    setSubmitting( false );
    closeModal();
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {( { values, handleChange, errors, touched, handleSubmit, isSubmitting } ) => (
        <Form
          onSubmit={handleSubmit}
          className='form'
        >
          <div className='row'>
            <div className='col-8'>
              <div className='form-row'>
                <label htmlFor='name'>Job role name</label>
                <Field
                  className={`form-control ${
                    errors.name && touched.name && fieldErrorClass
                  }`}
                  type='text'
                  id='name'
                  name='name'
                  value={values.name}
                  onChange={handleChange}
                  validate={validators.required}
                />
              </div>
              <div className='form-row'>
                <label htmlFor='accessDetails'>Access details</label>
                <Field
                  className={`form-control ${
                    errors.accessDetails && touched.accessDetails && fieldErrorClass
                  }`}
                  type='text'
                  id='accessDetails'
                  name='accessDetails'
                  value={values.accessDetails}
                  onChange={handleChange}
                  validate={validators.required}
                />
              </div>
              <div className='form-group'>
                <div className='form-row'>
                  <label htmlFor='contactPerson_FullName'>Contact person</label>
                  <Field
                    className={`form-control ${
                      errors.contactPerson_FullName &&
                      touched.contactPerson_FullName &&
                      fieldErrorClass
                    }`}
                    type='text'
                    id='contactPerson_FullName'
                    name='contactPerson_FullName'
                    value={values.contactPerson_FullName}
                    onChange={handleChange}
                    validate={validators.required}
                  />
                </div>
                <div className='form-row'>
                  <label htmlFor='contactPerson_Phone'>Contact phone</label>
                  <Field
                    className={`form-control ${
                      errors.contactPerson_Phone &&
                      touched.contactPerson_Phone &&
                      fieldErrorClass
                    }`}
                    type='tel'
                    id='contactPerson_Phone'
                    name='contactPerson_Phone'
                    value={values.contactPerson_Phone}
                    onChange={handleChange}
                    placeholder='0123456789'
                    validate={validators.required}
                  />
                </div>
              </div>
            </div>
            <div className='col-4'>
              <PresetTasksList
                checked={jobRole.data.Tasks.map( task => ( {
                  taskId: task.PresetTaskId,
                  skillIds: task.Skills.map( skill => skill.id ),
                } ) )}
                onChange={checked => setCheckedTasks( checked )}
              />
            </div>
          </div>
          <div className='form-footer'>
            <div className='form-row mt-40 mb-10'>
              <button
                className='btn btn--green btn--block mb-2'
                type='submit'
                disabled={isSubmitting}
              >
                Save
              </button>
              <button
                type='button'
                className='btn btn--default btn--block'
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default memo( JobRoleEditingForm );
