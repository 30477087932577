import { useEffect } from 'react';

export const useClickAwayListener = ( target, onClickAway, element ) => {
  useEffect( () => {
    if ( onClickAway && ( ( target && target.current ) || element ) ) {
      const clickAwayListener = event => {
        const targetElement = target?.current || element;
        const path = event.path || ( event.composedPath && event.composedPath() );
        if ( !path.includes( targetElement ) ) {
          onClickAway();
        }
      };
      document.addEventListener( 'click', clickAwayListener );
      return () => {
        document.removeEventListener( 'click', clickAwayListener );
      };
    }
  }, [ onClickAway, target ] );
  return null;
};
