import { useDispatch, useSelector } from 'react-redux';
import { setSortBy, setSortOrder } from '../../store/slices/eventListingFiltersSlice';

export const EventListingHeader = () => {
  const { sortBy, sortOrder } = useSelector( state => state.filters );

  const dispatch = useDispatch();

  const toggleSorting = shouldSortBy => () => {
    const sortByChanged =
      sortBy === shouldSortBy ? ( sortOrder === 'asc' ? 'desc' : 'asc' ) : 'asc';

    dispatch( setSortOrder( sortByChanged ) );
    dispatch( setSortBy( shouldSortBy ) );
  };

  return (
    <thead>
      <tr>
        <th
          className='sortable'
          onClick={toggleSorting( 'startTime' )}
          data-sort={sortBy === 'startTime' ? sortOrder : undefined}
        >
          Time
        </th>

        <th
          className='sortable'
          onClick={toggleSorting( 'location' )}
          data-sort={sortBy === 'location' ? sortOrder : undefined}
        >
          Location
        </th>

        <th
          className='sortable'
          onClick={toggleSorting( 'jobRole' )}
          data-sort={sortBy === 'jobRole' ? sortOrder : undefined}
        >
          Job role
        </th>
        <th>Employee</th>
        <th>Status</th>
      </tr>
    </thead>
  );
};
