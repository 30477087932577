import { DateTime, Duration } from 'luxon';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as AddIcon } from '../../assets/images/add-icon.svg';
import { useGetJobEventsPolling } from '../../hooks';

export const OperationsJobRoleWeek = ( {
  getLocationName,
  jobRole,
  goCreateOrder,
  weekStart,
  showOnlyEmpty,
} ) => {
  const events = useGetJobEventsPolling();
  const history = useHistory();

  const goToEvent = id => () => {
    history.push( `/event-listing/${id}` );
  };

  const visibleEvents = useMemo( () => {
    if ( !events.data ) return [];
    return events.data
      .filter(
        event =>
          event.JobRole.id === jobRole.id &&
          DateTime.fromISO( event.start ).hasSame( weekStart, 'week' )
      )
      .sort( ( a, b ) => ( new Date( a.start ) > new Date( b.start ) ? 1 : -1 ) );
  }, [ events.data, jobRole, weekStart ] );

  const totalTimeMinutes = useMemo(
    () =>
      Math.round(
        visibleEvents.reduce(
          ( minutes, event ) =>
            minutes +
            DateTime.fromISO( event.end )
              .diff( DateTime.fromISO( event.start ), 'minutes' )
              .as( 'minutes' ),
          0
        )
      ),
    [ visibleEvents ]
  );

  const totalTime = useMemo( () => {
    const minutes = totalTimeMinutes % 60;
    const hours = ( totalTimeMinutes - minutes ) / 60;
    return Duration.fromObject( { hours, minutes } ).toFormat( 'hh:mm' );
  }, [ totalTimeMinutes ] );

  if ( !visibleEvents.length && !showOnlyEmpty ) return null;
  if ( showOnlyEmpty && visibleEvents.length ) return null;

  return (
    <div className='job-role-week'>
      <p>
        {getLocationName( jobRole.LocationId )} - {jobRole.name}
      </p>
      <div className='days'>
        {Array( 7 )
          .fill()
          .map( ( _, index ) => {
            const day = weekStart.plus( { days: index } );
            return (
              <div
                className='day'
                key={index}
              >
                <AddIcon onClick={goCreateOrder( day, jobRole )} />
                {visibleEvents
                  .filter(
                    event =>
                      event.JobRole.id === jobRole.id &&
                      DateTime.fromISO( event.start ).hasSame( day, 'day' )
                  )
                  .map( event => (
                    <div
                      className='event'
                      onClick={goToEvent( event.id )}
                      key={event.id}
                    >
                      <span>{DateTime.fromISO( event.start ).toFormat( 'HH:mm' )}</span>
                      <span>{DateTime.fromISO( event.end ).toFormat( 'HH:mm' )}</span>
                    </div>
                  ) )}
              </div>
            );
          } )}
        <div className='day total-time'>{totalTime}</div>
      </div>
    </div>
  );
};
