import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { InfoRows } from '../../../components/InfoRows/InfoRows';
import { UserCard } from '../../../components/UserCard/UserCard';
import { useGetJobEventQuery } from '../../../store/apis/eventsApi';
import Default from './Default';

const Failed = () => {
  const { orderId } = useParams();

  const event = useGetJobEventQuery( orderId );

  const [ showHistory, setShowHistory ] = useState( false );

  const receivedEmployeesSplitted = useMemo( () => {
    if ( !event.data?.JobOffer?.ReceivedEmployees?.length ) return;
    return event.data.JobOffer.ReceivedEmployees.reduce( ( carry, el, index ) => {
      const resultIndex = Math.floor( index / 3 );
      if ( !carry[resultIndex] ) carry[resultIndex] = [];
      carry[resultIndex].push( el );
      return carry;
    }, [] );
  }, [ event.data ] );

  return (
    <>
      <Default />
      <InfoRows
        title='Employees'
        onlyContainerBox
        rows={receivedEmployeesSplitted?.map( employees => ( {
          items: employees.map( employee => ( {
            element: <UserCard employee={employee} />,
          } ) ),
        } ) )}
      >
        {!receivedEmployeesSplitted?.length && (
          <div className='text-undefined'>Not assigned</div>
        )}
      </InfoRows>
      <div className='row'>
        <div className='col-12'>
          <div className='d-flex justify-content-md-end pt-4 pb-4'>
            <button
              className='btn btn--default'
              onClick={() => setShowHistory( !showHistory )}
            >
              {showHistory ? 'Hide' : 'Show'} document history
            </button>
          </div>
        </div>
        {showHistory ? (
          <div className='col-12'>
            <div className='white-shadow-box mb-1'>
              <div className='mb-3'>Document History</div>
              <div className='info-cols'>
                <div className='info-col'>
                  <span className='info-col__label'>Company created Job Order</span>
                </div>
                <div className='info-col'>
                  <span className='info-col__label'>
                    {moment( event.data?.createdAt ).format( 'MMMM D YYYY, h:mm a' )}
                  </span>
                </div>
              </div>
              <div className='info-cols mt-4'>
                <div className='info-col'>
                  <span className='info-col__label'>Job Order Failed</span>
                </div>
                <div className='info-col'>
                  <span className='info-col__label'>
                    {moment( event.data?.updatedAt ).format( 'MMMM D YYYY, h:mm a' )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Failed;
