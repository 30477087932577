import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { concat, roundHalf } from '../../../../utils/utils';
import styles from '../../OperationsScreen.module.sass';

export const BadgeHoverBoxEventJobRole = ( { event, className } ) => {
  const start = useMemo( () => DateTime.fromISO( event.start ), [ event ] );
  const end = useMemo( () => DateTime.fromISO( event.end ), [ event ] );
  const hoursDiff = useMemo( () => roundHalf( end.diff( start, 'hours' ).hours ) );

  const employees = useMemo( () => {
    const offer = event.JobOffer;
    return offer?.ReceivedEmployees.map( employee => ( {
      status: employee.SentEmployees.type,
      employee: employee
    } ) );
  }, [ event ] );

  const isAccepted = useMemo( () => !!event.JobOffer?.AcceptedEmployee, [ event ] );

  const render = () => (
    <>
      <p>
        <span>
          {start.toFormat( 'HH:mm' )} - {end.toFormat( 'HH:mm' )}
        </span>
        <span>{hoursDiff} hours</span>
      </p>
      <p className={styles['employees']}>
        {employees?.length ? (
          employees.map( ( { employee, status } ) => (
            <span
              key={employee.id}
              className={
                styles[isAccepted ? ( status === 'accept' ? status : 'rejected' ) : status]
              }
            >
              {employee.lastName}, {employee.firstName}
            </span>
          ) )
        ) : (
          <span>{event.status === 'open' ? 'Open' : 'Unprocessed'}</span>
        )}
      </p>
    </>
  );

  return (
    <Link
      to={`/event-listing/${event.id}`}
      className={concat( styles['hover-event'], className )}
    >
      {render()}
    </Link>
  );
};
