import axios from 'axios';
import { _baseURL } from '../constants';

export const baseInstance = axios.create( {
  baseURL: _baseURL
} );

export const getTokenFromCookie = () =>
  document.cookie
    .split( ';' )
    .map( cookie => cookie.split( '=' ).map( str => str.trim() ) )
    .filter( ( [ key ] ) => key === 'token' )[0][1]
    .trim();

export const authorizedAxiosInstance = axios.create( {
  baseURL: _baseURL
} );

authorizedAxiosInstance.interceptors.request.use( request => {
  if ( request.headers ) {
    const token = getTokenFromCookie();
    request.headers = {
      ...request.headers,
      Authorization: token
    };
  }
  return request;
} );

export const axiosBaseQuery = async ( { url, method = 'get', body, headers, params } ) => {
  try {
    const finalUrl = /^https?:\/\//.test( url ) ? url : _baseURL + url;
    const result =
      method.toLowerCase() === 'get'
        ? await authorizedAxiosInstance( {
            url: finalUrl,
            method,
            params: { ...body, ...params },
            headers
          } )
        : await authorizedAxiosInstance( {
            url: finalUrl,
            method,
            data: body,
            headers,
            params
          } );
    return { data: result.data };
  } catch ( axiosError ) {
    const err = axiosError;
    return {
      error: { status: err.response?.status, data: err.response?.data }
    };
  }
};
