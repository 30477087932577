import { useContext, useMemo } from 'react';
import { EventItemHoverBox } from '../../components/EventItemHoverBox';
import { EventItemHoverBoxContext } from '../../components/EventItemHoverBox/EventItemHoverBoxContext';
import { InfoRows } from '../../components/InfoRows/InfoRows';
import { UserCard } from '../../components/UserCard/UserCard';
import { formatDateString, formatTimeString } from '../../utils/dates/dates';
import { getJobLogValues } from '../../utils/utils';

export const LogItemHoverBox = () => {
  const { event } = useContext( EventItemHoverBoxContext );

  const acceptedEmployee = useMemo( () => event?.JobOffer?.AcceptedEmployee, [ event ] );
  const jobLog = useMemo( () => getJobLogValues( event, true ), [ event ] );
  console.log( { jobLog, acceptedEmployee } );

  const render = useMemo( () => {
    if ( !event ) return null;
    return (
      <InfoRows
        rows={[
          {
            items: [
              {
                label: 'Worker name',
                element: (
                  <UserCard
                    className='mt-2 justify-content-between'
                    infoBlockClassName='mr-2'
                    avatarPosition='last'
                    employee={acceptedEmployee}
                  />
                ),
              },
              {
                label: 'Created',
                value: formatDateString( jobLog.updatedAt ),
              },
            ],
            props: { disableShadowBox: true, className: 'mb-3' },
          },
          {
            items: [
              {
                label: 'Start time',
                value: formatTimeString( jobLog?.Employee_Start?.toISOString() ),
              },
              {
                label: 'End time',
                value: formatTimeString( jobLog?.Employee_End?.toISOString() ),
              },
              {
                label: 'Lunch break',
                value: jobLog.Employee_LunchBreak || 0,
              },
            ],
            props: {
              className: 'mt-3',
              disableShadowBox: true,
            },
          },
          {
            items: [
              {
                label: 'Worker comment',
                element: (
                  <div className='form-control job-log-comment'>
                    {jobLog?.Employee_Comment}
                  </div>
                ),
              },
            ],
            props: {
              className: 'mt-3',
              disableShadowBox: true,
            },
          },
          {
            items: [
              {
                label: 'Customer comment',
                element: (
                  <div className='form-control job-log-comment'>
                    {jobLog?.Customer_Comment}
                  </div>
                ),
              },
            ],
            props: {
              className: 'mt-3',
              disableShadowBox: true,
            },
          },
        ]}
      />
    );
  }, [ jobLog, event, acceptedEmployee ] );

  return <EventItemHoverBox>{render}</EventItemHoverBox>;
};
