import { mainApi } from './mainApi';

export const customerUsersApi = mainApi.injectEndpoints( {
  endpoints: build => ( {
    getCustomerUsers: build.query( {
      query: () => ( { url: '/customerUsers' } ),
      providesTags: [ { type: 'customerUsers', id: 'all' } ],
    } ),
    getCustomerUser: build.query( {
      query: id => ( { url: `/customerUsers/${id}` } ),
      providesTags: ( res, err, arg ) => ( err ? [] : [ { type: 'customerUsers', id: arg } ] ),
    } ),
    updateCustomerUser: build.mutation( {
      query: ( { id, data } ) => ( {
        url: `/customerUsers/${id}`,
        method: 'PUT',
        body: data,
      } ),
      invalidatesTags: ( res, err, arg ) =>
        err ? [] : [ 'customerUsers', { type: 'customerUsers', id: arg.id } ],
    } ),
    deleteCustomerUser: build.mutation( {
      query: id => ( {
        url: `/customerUsers/${id}`,
        method: 'delete',
      } ),
      invalidatesTags: ( res, err ) => ( err ? [] : [ 'customerUsers' ] ),
    } ),
  } ),
} );

export const {
  useGetCustomerUserQuery,
  useGetCustomerUsersQuery,
  useUpdateCustomerUserMutation,
  useDeleteCustomerUserMutation,
} = customerUsersApi;
