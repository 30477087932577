import { useMemo } from 'react';
import { ReactComponent as CheckIcon } from '../../../assets/images/check.svg';
import { usePagination } from '../../../hooks';
import { useGetPresetTasksQuery } from '../../../store/apis/presetTasksApi';
import { concat } from '../../../utils/utils';
import styles from '../EmployeeAssignmentScreen.module.scss';
import { EmployeeRow } from './EmployeeRow';

export const Table = ( {
  employees,
  jobEvent,
  isEmployeeAvailable,
  selectedEmployees,
  setSelectedEmployees
} ) => {
  const { data: presetTasks } = useGetPresetTasksQuery();

  const requiredSkills = useMemo( () => {
    if ( !jobEvent || !presetTasks ) return [];
    return presetTasks
      .find( task => task.id === jobEvent.Task.PresetTaskId )
      .Skills.filter( skill =>
        jobEvent.EventSkills.map( skill => skill.SkillId ).includes( skill.id )
      );
  }, [ jobEvent, presetTasks ] );

  const { PaginationComponent, visibleItems } = usePagination( employees );

  const filteredItems = useMemo( () => {
    return visibleItems.filter(
      employee =>
        !jobEvent.JobOffer.ReceivedEmployees.find( received => received.id === employee.id )
    );
  }, [ jobEvent, visibleItems ] );

  const toggleEmployee = employee => () => {
    if ( selectedEmployees.includes( employee.id ) ) {
      setSelectedEmployees( selectedEmployees.filter( id => id !== employee.id ) );
    } else {
      setSelectedEmployees( [ ...selectedEmployees, employee.id ] );
    }
  };
  const isAllSelected =
    selectedEmployees.length === employees.length && employees.length !== 0;

  const onSelectAll = () => {
    setSelectedEmployees( isAllSelected ? [] : employees.map( employee => employee.id ) );
  };

  return (
    <div className='row'>
      <div className='col-12'>
        <table className='table'>
          <thead>
            <tr>
              <th
                className={styles['checkbox-container']}
                onClick={onSelectAll}
              >
                <div
                  className={styles['checkbox']}
                  data-checked={isAllSelected}
                >
                  <CheckIcon style={{ opacity: isAllSelected ? 1 : 0 }} />
                </div>
              </th>
              <th>Employee name</th>
              <th className='table__th-center'>Exp.</th>
              <th className={concat( 'table__th-center', styles['th-hover'] )}>
                Jobs
                <div className={concat( styles['info-hoverbox'], styles['jobs'] )}>
                  Relevant jobs: <br />
                  Green = No other jobs within 12 hours before/after this job.
                  <br />
                  Yellow = Other job accepted 1-12 hours before/after this job. <br />
                  Red = Other job {'<'}1 hour before/after this job.
                </div>
              </th>
              <th className={concat( 'table__th-center', styles['th-hover'] )}>
                Acc/Exe
                <div className={styles['info-hoverbox']}>...</div>
              </th>
              <th className={concat( 'table__th-center', styles['th-hover'] )}>
                180d
                <div className={styles['info-hoverbox']}>...</div>
              </th>
              <th>Skills</th>
              <th>Grade</th>
            </tr>
          </thead>
          <tbody className='bg-transparent'>
            {jobEvent?.JobOffer?.ReceivedEmployees.map( ( employee, index ) => (
              <EmployeeRow
                key={index}
                employee={employee}
                requiredSkills={requiredSkills}
                availability={isEmployeeAvailable( employee )}
                isSelected={true}
                jobEvent={jobEvent}
                disabled
              />
            ) )}
            {filteredItems.length ? (
              filteredItems.map( employee => {
                return (
                  <EmployeeRow
                    key={employee.id}
                    employee={employee}
                    requiredSkills={requiredSkills}
                    availability={isEmployeeAvailable( employee )}
                    isSelected={selectedEmployees.includes( employee.id )}
                    onChange={toggleEmployee( employee )}
                    jobEvent={jobEvent}
                  />
                );
              } )
            ) : (
              <tr className='no-result'>
                <td
                  className='bg-warning'
                  colSpan={8}
                >
                  Sorry, no results were found.
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={8}>{PaginationComponent}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};
