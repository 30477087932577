import React, { memo } from 'react';
import Select from 'react-select';
import { selectTheme } from '../../theme';
import ErrorBoundary from '../ErrorBoundary';
import './Card.scss';

const Card = ( {
  title,
  selectOptions = false,
  children,
  selectFilterState,
  setSelectFilterState,
} ) => {
  const handleChange = selectedOption => {
    setSelectFilterState( selectedOption );
  };

  return (
    <ErrorBoundary>
      <div className='card'>
        <div className='card-head'>
          <h5 className='card-title'>{title}</h5>
          <div className='card-subtitle text-center'>
            {selectOptions ? (
              <Select
                value={selectFilterState}
                defaultValue={selectFilterState[0]}
                onChange={handleChange}
                options={selectOptions}
                theme={selectTheme}
              />
            ) : null}
          </div>
        </div>
        {children}
      </div>
    </ErrorBoundary>
  );
};

export default memo( Card );
