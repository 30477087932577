import PropTypes from 'prop-types';
import React from 'react';
import './LinkCount.scss';

const LinkCount = ( { name, count } ) => {
  return (
    <div className='link-count'>
      {name}
      {!!count && <span className='count'>{count}</span>}
    </div>
  );
};

LinkCount.propTypes = {
  name: PropTypes.string,
  count: PropTypes.number,
};

export default LinkCount;
