import React from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import Alert from '../../../components/Alert';
import LinkBack from '../../../components/LinkBack';
import {
  useGetCompanyQuery,
  useUpdateCompanyMutation
} from '../../../store/apis/companiesApi';
import EditCompanyForm from './EditCompanyInfoForm';

const EditCompanyInfo = ( { alert } ) => {
  const { companyId } = useParams();
  const history = useHistory();

  const company = useGetCompanyQuery( companyId );
  const [ updateCompany ] = useUpdateCompanyMutation();

  const onSubmit = ( id, fields, setSubmitting ) => {
    updateCompany( { id, fields } ).then( () => {
      setSubmitting( false );
      history.goBack();
    } );
  };

  return (
    <div className='app-content'>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12'>
            <div className='link-back'>
              <LinkBack title='Back to Company' />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <div className='company-details editing-company-info'>
              {company.data && (
                <>
                  <div className='company-details__wrapper'>
                    <div className='row'>
                      <div className='col-12 col-lg-6'>
                        <h1 className='title error-title mt-4 mt-md-3'>
                          Company editing
                        </h1>
                      </div>
                      <div className='col-12 col-lg-6'>
                        <div className='d-flex align-items-center justify-content-md-end mb-3 mb-md-0'>
                          {alert && (
                            <Alert
                              type={alert.color}
                              text={alert.text}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <EditCompanyForm
                    initialData={company.data}
                    onSubmit={onSubmit}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    alert: state.app.alert,
  };
};

export default connect( mapStateToProps )( withRouter( EditCompanyInfo ) );
