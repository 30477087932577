import React from 'react';
import { classConversion } from '../../utils/classConversion';

import './Button.scss';

export const Button = ( {
  children,
  type = 'green',
  onClick,
  submit = false,
  disabled = false,
  style,
  className
} ) => {
  const buttonModification = {
    green: type == 'green',
    red: type === 'red',
    paleGreen: type === 'pale-green'
  };

  return (
    <button
      type={submit ? 'submit' : null}
      className={[
        'button',
        classConversion( buttonModification, 'button' ),
        className
      ].join( ' ' )}
      onClick={onClick}
      disabled={disabled}
      style={style}
    >
      {children}
    </button>
  );
};
