import { useState } from 'react';
import Modal from 'react-modal';

export const ButtonWithConfirmation = ( {
  text,
  onConfirm,
  confirmLabel = 'Confirm',
  cancelLabel = 'Cancel',
} ) => {
  const [ isModalOpen, setModalOpen ] = useState( false );

  const toggleModalOpen = isOpen => () => setModalOpen( isOpen );

  return (
    <>
      <button
        onClick={toggleModalOpen( true )}
        className='btn btn--light-danger'
      >
        {text}
      </button>
      <Modal
        isOpen={isModalOpen}
        ariaHideApp={false}
        style={customStyles}
      >
        <span
          onClick={toggleModalOpen( false )}
          className='icon-close'
        />
        <div className='modal-head'>
          <div className='modal-title'>Are you sure?</div>
        </div>
        <div className='modal-body d-flex justify-content-between'>
          <div className='d-flex w-100'>
            <button
              className='btn btn--light-danger w-100 mr-2'
              onClick={onConfirm}
            >
              {confirmLabel}
            </button>
            <button
              className='btn w-100'
              onClick={toggleModalOpen( false )}
            >
              {cancelLabel}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

const customStyles = {
  content: {
    maxWidth: 720,
  },
};
