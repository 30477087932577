import { mainApi } from './mainApi';

export const locationsApi = mainApi.injectEndpoints( {
  endpoints: build => ( {
    getLocation: build.query( {
      query: id => ( {
        url: `/locations/${id}`,
        method: 'get',
      } ),
      providesTags: ( res, err, id ) => ( err ? [] : [ { type: 'location', id } ] ),
    } ),
    getLocations: build.query( {
      query: () => ( {
        url: '/locations',
        method: 'get',
      } ),
      providesTags: ( res, err ) => ( err ? [] : [ { type: 'location', id: 'all' } ] ),
    } ),
    updateLocation: build.mutation( {
      query: ( { id, fields } ) => ( {
        url: `/locations/${id}`,
        method: 'put',
        body: fields,
      } ),
      invalidatesTags: ( res, err, { id } ) =>
        err
          ? []
          : [
              { type: 'location', id },
              { type: 'location', id: 'all' },
            ],
    } ),
  } ),
} );

export const {
  useGetLocationQuery,
  useLazyGetLocationQuery,
  useUpdateLocationMutation,
  useGetLocationsQuery,
} = locationsApi;
