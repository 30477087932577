import { baseInstance } from './instances';

const invitationAPI = {
  inviteJoblinkUser: async data => {
    return baseInstance
      .post( '/invitation/joblinkuser', data, {
        headers: {
          frontendurl: `${window.location.origin}/register?token=`,
        },
      } )
      .then( res => res.data );
  },
  registerJoblinkUser: async ( token, password ) => {
    return invitationAPI
      .getInvitationData( token )
      .then( data =>
        data
          ? baseInstance.post( `/invitation/joblinkuser/register/${token}`, {
              firstName: data.payload.firstName,
              lastName: data.payload.lastName,
              phone: data.payload.phone,
              password,
            } )
          : null
      )
      .then( res => res.status === 200 )
      .catch( error => console.log( error ) );
  },
  getInvitationData: async token => {
    return baseInstance
      .get( `/invitation/retrieve/${token}` )
      .then( res => ( res.status === 200 ? res.data : false ) );
  },
  inviteEmployee: async data => {
    return baseInstance.post( '/invitation/employee', data ).then( res => res.data );
  },
};

export default invitationAPI;
