import { useMemo } from 'react';
import { EmployeeCertificate } from './EmployeeCertificate';

export const EmployeeCertificates = ( { employee } ) => {
  const images = useMemo( () => {
    return employee
      ? employee.Certificates.filter( certificate =>
          /(png|jpg|jpeg)/i.test( certificate.url )
        )
      : [];
  }, [ employee ] );

  const files = useMemo( () => {
    return employee
      ? employee.Certificates.filter(
          certificate => !/(png|jpg|jpeg)/i.test( certificate.url )
        )
      : [];
  }, [ employee ] );

  return (
    <div className='certificates-wrapper mt-2 d-flex flex-column'>
      <div className='d-flex mb-2 flex-wrap'>
        {files.map( ( certificate, index ) => (
          <EmployeeCertificate
            certificate={certificate}
            key={index}
          />
        ) )}
      </div>
      <div className='d-flex justify-content-start flex-wrap'>
        {images.map( ( certificate, index ) => (
          <EmployeeCertificate
            certificate={certificate}
            key={index}
            style={{ width: '30%', marginBottom: 12 }}
          />
        ) )}
      </div>
    </div>
  );
};
