import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetAllEmployeesQuery } from '../../store/apis/employeesApi';

export const useGetAvailableEmployees = jobEvent => {
  const { data: employees } = useGetAllEmployeesQuery(
    jobEvent
      ? {
          filters: {
            statuses: [ 'active' ],
            available: {
              start: jobEvent.start,
              end: jobEvent.end,
              level: 'certainlyAvailable'
            }
          },
          includes: {}
        }
      : skipToken
  );

  return employees || [];
};
