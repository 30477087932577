import { ReactComponent as AddIcon } from '../../../../assets/images/add-no-circle-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/images/delete-icon.svg';

export const Member = ( { employee, onRemove, onAdd } ) => {
  const onRemoveClick = onRemove
    ? () => {
        onRemove( employee );
      }
    : undefined;

  const onAddClick = onAdd
    ? () => {
        onAdd( employee );
      }
    : undefined;

  return (
    <div className='edit-members-list-user'>
      <div className='info'>
        <div className='avatar'>
          {employee.photoUrl ? (
            <img src={employee.photoUrl} />
          ) : (
            `${employee.firstName[0]}${employee.lastName[0]}`
          )}
        </div>
        <span className='name'>
          {employee.firstName} {employee.lastName}
        </span>
      </div>
      <div className='actions'>
        {onRemoveClick && (
          <div
            className='action delete'
            onClick={onRemoveClick}
          >
            <DeleteIcon />
          </div>
        )}
        {onAddClick && (
          <div
            className='action add'
            onClick={onAddClick}
          >
            <AddIcon />
          </div>
        )}
      </div>
    </div>
  );
};
