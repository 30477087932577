import React from 'react';

export const Disfavorite = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.45772 15.5249C9.32945 15.8138 9.04325 16 8.72742 16C7.09434 16 5.77044 14.6748 5.77044 13.04V10.96H2.25808C1.60341 10.966 0.978823 10.6846 0.549142 10.19C0.118359 9.69404 -0.0731697 9.0338 0.0253534 8.384L1.01793 1.90413C1.185 0.801457 2.13691 -0.0100061 3.25056 0.000105858L13.5186 0.000266075C14.7631 -0.0178918 15.8258 0.897486 15.9928 2.13302C15.9976 2.16856 16 2.20439 16 2.24025V7.28022C16 7.31608 15.9976 7.35191 15.9928 7.38745C15.833 8.56969 14.8145 9.61865 13.5186 9.59996H12.0884L9.45772 15.5249ZM10.8053 8.55028L8.2469 14.3125C7.73372 14.1182 7.3688 13.6218 7.3688 13.04V10.16C7.3688 9.71821 7.011 9.36004 6.56962 9.36004H2.25402L2.24494 9.3601C2.05742 9.36223 1.87837 9.28179 1.75531 9.14012C1.63218 8.99837 1.57748 8.80973 1.60563 8.62409L2.59803 2.14534L2.59823 2.14406C2.64604 1.8285 2.91879 1.59644 3.23757 1.60004L10.8053 1.6001V8.55028ZM12.4037 1.60016L13.5249 1.60026L13.5391 1.60013C13.9612 1.59265 14.3243 1.89175 14.4016 2.30283V7.21835C14.3168 7.68173 13.9126 8.00671 13.5391 8.0001L13.5249 7.99997H12.4037V1.60016Z'
        fill='#EF4444'
      />
    </svg>
  );
};
