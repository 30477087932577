import React, { memo, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useParams } from 'react-router-dom';
import LinkBack from '../../../components/LinkBack';
import LocationsTable from '../../../components/LocationsTable/LocationsTable';
import { SearchPanel } from '../../../components/SearchPanel';
import { searchByName } from '../../../store/actions/appActions';
import { useGetCompanyQuery } from '../../../store/apis/companiesApi';
import { SEARCH_LOCATIONS } from '../../../store/types';
import JobRoleCreatingForm from './JobRoleCreatingForm';
import JobRoleEditingForm from './JobRoleEditingForm/JobRoleEditingForm';
import CreateLocationForm from './LocationCreatingForm/LocationCreatingForm';
import EditLocationForm from './LocationEditingForm';

const EditCompanyLocations = () => {
  const { companyId } = useParams();

  const company = useGetCompanyQuery( companyId );

  const [ isOpenCreateLocationModal, setIsOpenCreateLocationModal ] = useState( false );
  const [ isOpenEditLocationModal, setIsOpenEditLocationModal ] = useState( false );
  const [ isOpenCreateJobRoleModal, setIsOpenCreateJobRoleModal ] = useState( false );
  const [ isOpenEditJobRoleModal, setIsOpenEditJobRoleModal ] = useState( false );

  const [ jobRoleCreateModalData, setJobRoleCreateModalData ] = useState( null );
  const [ jobRoleEditModalData, setJobRoleEditModalData ] = useState( null );
  const [ locationEditModalData, setLocationEditModalData ] = useState( null );
  const [ visibleItems, setVisibleItems ] = useState( null );
  const [ searchTerm, setSearchTerm ] = useState( '' );

  const customStyles = {
    content: {
      maxWidth: 860,
    },
  };

  // Actions
  const openModal = callback => callback( true );
  const closeModal = callback => callback( false );

  const openEditJobRoleModal = values => {
    openModal( setIsOpenEditJobRoleModal );
    setJobRoleEditModalData( values );
  };

  const openEditLocationModal = values => {
    openModal( setIsOpenEditLocationModal );
    setLocationEditModalData( values );
  };

  const openCreateJobRoleModal = locationId => {
    openModal( setIsOpenCreateJobRoleModal );
    setJobRoleCreateModalData( locationId );
  };

  useEffect( () => {
    setVisibleItems(
      company.data?.Locations && searchByName( company.data.Locations, searchTerm )
    );
  }, [ company.data, searchTerm ] );

  return (
    <>
      <div className='app-content'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12 mb-3'>
              <div className='link-back'>
                <LinkBack title='Back to Company' />
              </div>
            </div>
          </div>
          <div className='row mb-4'>
            <div className='col-12 col-xl-4'>
              <h1 className='title error-title mb-3'>Company locations</h1>
            </div>
            <div className='col-12 col-xl-8'>
              <div className='d-md-flex justify-content-xl-end align-items-center'>
                <div className='mr-3 mb-3 mb-md-0'>
                  <button
                    className='btn btn--light-green'
                    onClick={() => openModal( setIsOpenCreateLocationModal )}
                  >
                    Create new location
                  </button>
                </div>
                <div className='search'>
                  <SearchPanel
                    type={SEARCH_LOCATIONS}
                    value={searchTerm}
                    onChange={setSearchTerm}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <div className='company-details__info white-shadow-box pb-2'>
                <div className='small-title'>Locations</div>
                <div className='row small-padding-col'>
                  {!company.isLoading &&
                    visibleItems?.map( location => (
                      <div
                        className='col-12 col-lg-6 mb-3'
                        key={location.id}
                      >
                        <LocationsTable
                          data={location}
                          onEditJobRole={openEditJobRoleModal}
                          onEditJobLocation={openEditLocationModal}
                          onCreate={openCreateJobRoleModal}
                          isLoading={company.isLoading}
                        />
                      </div>
                    ) )}
                  {visibleItems?.length === 0 && (
                    <div className='col-12 pb-2'>
                      <div className={'no-result bg-warning'}>
                        Sorry, no results were found.
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isOpenCreateLocationModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel='Company location creating'
      >
        <span
          className='icon-close'
          onClick={() => closeModal( setIsOpenCreateLocationModal )}
        />
        <div className='modal-head'>
          <div className='modal-title'>Company location creating</div>
        </div>
        <div className='modal-body'>
          <CreateLocationForm
            companyId={companyId}
            closeModal={() => closeModal( setIsOpenCreateLocationModal )}
          />
        </div>
      </Modal>
      <Modal
        isOpen={isOpenEditLocationModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel='Job role editing'
      >
        <span
          className='icon-close'
          onClick={() => closeModal( setIsOpenEditLocationModal )}
        />
        <div className='modal-head'>
          <div className='modal-title'>Company locations editing</div>
        </div>
        <div className='modal-body'>
          <EditLocationForm
            data={locationEditModalData}
            companyId={companyId}
            closeModal={() => closeModal( setIsOpenEditLocationModal )}
          />
        </div>
      </Modal>
      <Modal
        isOpen={isOpenCreateJobRoleModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel='Job role creating'
      >
        <span
          className='icon-close'
          onClick={() => closeModal( setIsOpenCreateJobRoleModal )}
        />
        <div className='modal-head'>
          <div className='modal-title'>Job role creating</div>
        </div>
        <div className='modal-body'>
          <JobRoleCreatingForm
            locationId={jobRoleCreateModalData}
            closeModal={() => closeModal( setIsOpenCreateJobRoleModal )}
          />
        </div>
      </Modal>
      <Modal
        isOpen={isOpenEditJobRoleModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel='Job role editing'
      >
        <span
          className='icon-close'
          onClick={() => closeModal( setIsOpenEditJobRoleModal )}
        />
        <div className='modal-head'>
          <div className='modal-title'>Job role editing</div>
        </div>
        <div className='modal-body'>
          <JobRoleEditingForm
            data={jobRoleEditModalData}
            closeModal={() => closeModal( setIsOpenEditJobRoleModal )}
          />
        </div>
      </Modal>
    </>
  );
};

export default memo( EditCompanyLocations );
