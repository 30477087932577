import { useDeleteCertificateMutation } from '../../../../store/apis/employeesApi';
import { ReactComponent as RemoveIcon } from '../../../../assets/images/remove-icon.svg';
import { useMemo } from 'react';
import { ReactComponent as FileIcon } from '../../../../assets/images/pdf-file.svg';

export const CertificateEdit = ( { certificate } ) => {
  const [ deleteCertificate ] = useDeleteCertificateMutation();

  const isImage = useMemo( () => {
    return /(png|jpg|jpeg)/i.test( certificate.url );
  }, [ certificate ] );

  const onDelete = e => {
    e.preventDefault();
    deleteCertificate( certificate.id );
  };

  return (
    <a
      href={certificate.url}
      className='certificate-box'
      key={certificate.id}
      target='_blank'
      rel='noreferrer'
    >
      {isImage ? (
        <img
          src={certificate.url}
          alt=''
        />
      ) : (
        <FileIcon />
      )}
      <RemoveIcon
        className='remove'
        onClick={onDelete}
      />
    </a>
  );
};
