import { useFormikContext } from 'formik';
import React from 'react';
import { StyledSelect } from '../StyledSelect';

export const SelectField = ( { options, name, ...selectProps } ) => {
  const { values, setFieldValue } = useFormikContext();

  const onChange = value => setFieldValue( name, value );

  return (
    <StyledSelect
      options={options}
      onChange={onChange}
      value={values[name]}
      {...selectProps}
    />
  );
};
