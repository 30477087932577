import React from 'react';

import './row.css';

const RowSecond = ( { children, marginBottom = 0 } ) => {
  return (
    <div
      style={{ marginBottom: `${marginBottom}px` }}
      className='row-second'
    >
      {children}
    </div>
  );
};

export default RowSecond;
