import enGb from 'date-fns/locale/en-GB';
import { useField, useFormikContext } from 'formik';
import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';

registerLocale( 'enGb', enGb );

const DatePickerField = ( { ...props } ) => {
  const { setFieldValue } = useFormikContext();
  const [ field ] = useField( props );


  return (
    <DatePicker
      className={[ 'form-control', props.wrapperClassName ].join( ' ' )}
      {...field}
      {...props}
      selected={( field.value && new Date( field.value ) ) || null}
      onChange={val => {
        setFieldValue( field.name, val );
      }}
      locale='enGb'
    />
  );
};

export default DatePickerField;
