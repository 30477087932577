import { useState } from 'react';
import { Redirect } from 'react-router';
import ErrorBoundary from '../../../components/ErrorBoundary';
import { authApi } from '../../../store/apis/authApi';
import InvalidLinkScreen from './InvalidLinkScreen';
import viewHide from './view_hide.svg';
import viewShow from './view_show.svg';

const ConfirmResetPasswordScreen = ( { title } ) => {
  const token = new URL( window.location.href ).searchParams.get( 'token' );
  const roleParam = new URL( window.location.href ).searchParams.get( 'role' );

  const [ showPassword, setShowPassword ] = useState( false );
  const iconType = showPassword ? viewShow : viewHide;
  const [ userPassword, setUserPassword ] = useState( '' );
  const [ confirmationSuccess, setConfirmationSuccess ] = useState( null );
  const [ isLinkValid, setIsLinkValid ] = useState(
    token && roleParam && [ 'employee', 'joblink' ].includes( roleParam )
  );

  const onConfirmResetPassword = async e => {
    e.preventDefault();
    if ( token && userPassword ) {
      const success = await authApi.resetPasswordConfirm(
        { token, password: userPassword.trim() },
        roleParam === 'employee' ? 'employee' : 'joblinkUser'
      );
      setConfirmationSuccess( success );
      setIsLinkValid( success );
    }
  };

  if ( confirmationSuccess ) {
    return <Redirect to={'/'} />;
  }

  if ( !isLinkValid ) {
    return <InvalidLinkScreen />;
  }

  return (
    <ErrorBoundary>
      <div className='app-login__head app-login__head--login-reset'>
        <h2 className='title'>{title}</h2>
      </div>
      <div className='col-12'>
        <div className='app-login__body app-login__body--login-reset'>
          <p>Please set the password for your account in the field below.</p>
        </div>
        <form onSubmit={onConfirmResetPassword}>
          <div className='form-row'>
            <label htmlFor='password'>Password</label>
            <span className='relative'>
              <input
                type={showPassword ? 'text' : 'password'}
                className='form-control w-100'
                name='password'
                id='password'
                placeholder='Enter Your Password'
                required
                value={userPassword}
                onChange={e => setUserPassword( e.target.value )}
              />
              <span
                className='password-show'
                style={{ backgroundImage: `url(${iconType})` }}
                onClick={() => setShowPassword( !showPassword )}
              ></span>
            </span>
          </div>
          <div className='form-row'>
            <button
              type='submit'
              className='btn btn--green'
            >
              Set password
            </button>
          </div>
        </form>
      </div>
    </ErrorBoundary>
  );
};

export default ConfirmResetPasswordScreen;
