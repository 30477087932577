import { mainApi } from './mainApi';

export const jobRolesApi = mainApi.injectEndpoints( {
  endpoints: build => ( {
    getJobRole: build.query( {
      query: id => ( {
        url: `/jobRoles/${id}`,
        method: 'get',
      } ),
      providesTags: ( res, err, arg ) => ( err ? [] : [ { type: 'jobRole', id: arg } ] ),
    } ),
    updateJobRole: build.mutation( {
      query: ( { id, data } ) => ( {
        url: `/jobRoles/${id}`,
        method: 'PUT',
        body: data,
      } ),
      invalidatesTags: ( res, err, arg ) => ( err ? [] : [ { type: 'jobRole', id: arg.id } ] ),
    } ),
    updateJobRoleTasks: build.mutation( {
      query: ( { id, tasks } ) => ( {
        url: `/jobRoles/${id}/tasks`,
        method: 'POST',
        body: tasks,
      } ),
      invalidatesTags: ( res, err, arg ) => ( err ? [] : [ { type: 'jobRole', id: arg.id } ] ),
    } ),
    createJobRole: build.mutation( {
      query: ( { locationId, fields } ) => ( {
        url: '/jobRoles',
        body: { ...fields, LocationId: locationId },
        method: 'post',
      } ),
      invalidatesTags: ( res, err ) => ( err ? [] : [ 'allJobRoles' ] ),
    } ),
  } ),
} );

export const {
  useGetJobRoleQuery,
  useUpdateJobRoleMutation,
  useUpdateJobRoleTasksMutation,
  useCreateJobRoleMutation,
} = jobRolesApi;
