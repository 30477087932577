export const Skills = ( { skills, className } ) => {
  if ( !skills ) return null;

  return (
    <div className={[ 'required-skills', className ].join( ' ' )}>
      {skills.map( skill => (
        <div
          className='required-skills'
          key={skill.id}
        >
          <div className='skill-box pr-2'>{skill.name}</div>
        </div>
      ) )}
    </div>
  );
};
