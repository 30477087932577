import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useFormikContext } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { ReactComponent as CheckIcon } from '../../../../assets/images/check.svg';
import { useGetJobRoleQuery } from '../../../../store/apis/jobRolesApi';
import styles from './Skills.module.sass';

export const SkillsField = () => {
  const { values, setFieldValue } = useFormikContext();

  const taskId = +values.task;
  const jobRoleId = +values.jobRole;

  const { data: jobRole } = useGetJobRoleQuery( jobRoleId || skipToken );

  const [ selectedSkills, setSelectedSkills ] = useState( [] );

  const skills = useMemo( () => {
    if ( !jobRole || !taskId || jobRole.id !== jobRoleId ) return [];
    return (
      jobRole.Tasks.find( task => task.id === taskId )?.Skills.map( task => ( {
        label: task.name,
        value: task.id
      } ) ) || []
    );
  }, [ jobRole, taskId ] );

  const onSkillClick = id => () => {
    if ( selectedSkills.includes( id ) )
      setSelectedSkills( selectedSkills.filter( skillId => skillId !== id ) );
    else setSelectedSkills( [ ...selectedSkills, id ] );
  };

  const onSelectAllClick = () => {
    if ( selectedSkills.length ) {
      if ( selectedSkills.length === skills.length ) setSelectedSkills( [] );
      else setSelectedSkills( skills.map( skill => skill.value ) );
    } else {
      setSelectedSkills( skills.map( skill => skill.value ) );
    }
  };

  useEffect( () => {
    setFieldValue( 'eventSkills', selectedSkills );
  }, [ selectedSkills ] );

  useEffect( () => {
    setSelectedSkills( skills.map( skill => skill.value ) );
  }, [ skills ] );

  return (
    <div className='form-row mr-4 w-100'>
      <label className='mb-2'>Skills</label>
      <div className={styles['skills']}>
        <div
          className={styles['item']}
          onClick={onSelectAllClick}
          data-checked={selectedSkills.length === skills.length && skills.length !== 0}
        >
          <span>
            <CheckIcon />
          </span>
          Select all
        </div>
        {skills.map( ( skill, index ) => (
          <div
            key={index}
            onClick={onSkillClick( skill.value )}
            data-checked={selectedSkills.includes( skill.value )}
            className={styles['item']}
          >
            <span>
              <CheckIcon />
            </span>
            {skill.label}
          </div>
        ) )}
      </div>
    </div>
  );
};
