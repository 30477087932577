import { useMemo } from 'react';
import ReactModal from 'react-modal';
import { useParams } from 'react-router-dom';
import { useUpdateEmployeeMutation } from '../../../store/apis/employeesApi';

export const ChangeStatusModal = ( {
  isOpen,
  targetStatus,
  closeModal,
  maxWidth,
  onConfirm,
} ) => {
  const { employeeId } = useParams();

  const [ updateEmployee ] = useUpdateEmployeeMutation();

  const customStyles = {
    content: {
      maxWidth: maxWidth,
    },
  };

  const text = useMemo( () => {
    if ( targetStatus === 'active' ) return null;
    if ( targetStatus === 'blocked' )
      return 'Are you sure? Blocking user prevents new job offers.';
    return 'Are you sure? User´s access to the system will be locked.';
  }, [ targetStatus ] );

  const onConfirmClick = () => {
    updateEmployee( {
      employeeId,
      fields: { status: targetStatus },
    } ).then( () => {
      closeModal();
      onConfirm( targetStatus );
    } );
  };

  return (
    <ReactModal
      isOpen={isOpen}
      appElement={document.body}
      style={customStyles}
    >
      <div className='modal-head'>
        <div className='modal-title'>Account status change</div>
      </div>
      <div className='modal-body'>
        {text}
        <div
          className='buttons'
          style={{ display: 'flex', marginTop: 40 }}
        >
          <button
            className='btn btn--green'
            style={{ width: '100%', marginRight: 16 }}
            onClick={onConfirmClick}
          >
            Save
          </button>
          <button
            className='btn btn--default'
            style={{ width: '100%' }}
            onClick={closeModal}
          >
            Close
          </button>
        </div>
      </div>
    </ReactModal>
  );
};
