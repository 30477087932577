import React, { memo } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Spinner } from '../../components/Spinner/Spinner';
import { useGetJobEventPolling } from '../../hooks';
import Canceled from './OfferStatement/Canceled';
import Completed from './OfferStatement/Completed';
import Default from './OfferStatement/Default';
import Failed from './OfferStatement/Failed';
import Filled from './OfferStatement/Filled';
import LogWaitingCustomer from './OfferStatement/LogWaitingCustomer';
import LogWaitingEmployee from './OfferStatement/LogWaitingEmployee';
import Open from './OfferStatement/Open';
import Unprocessed from './OfferStatement/Unprocessed';

const EventByStatus = () => {
  const { orderId } = useParams();
  const event = useGetJobEventPolling( orderId );

  if ( event.isLoading ) return <Spinner />;

  if ( event.data?.phase === 'offer' && event.data?.status === 'unprocessed' ) {
    return <Open />;
  }

  switch ( event.data?.status ) {
    case 'unprocessed': {
      return <Unprocessed />;
    }
    case 'open': {
      return <Open />;
    }
    case 'failed': {
      return <Failed />;
    }
    case 'canceled': {
      return <Canceled />;
    }
    case 'filled': {
      if ( event.data?.phase === 'log' ) {
        if ( event.data?.JobLog.phase === 'Employee' ) {
          return <LogWaitingEmployee />;
        } else {
          return <LogWaitingCustomer />;
        }
      }
      return <Filled />;
    }
    case 'completed': {
      return <Completed />;
    }
    default: {
      return <Default />;
    }
  }
};

const OrderReceived = () => {
  return (
    <div className='app-content'>
      <div className='container-fluid'>
        <EventByStatus />
      </div>
    </div>
  );
};

export default memo( OrderReceived );
