import React from 'react';
import Modal from 'react-modal';
import invitationAPI from '../../../api/invitationAPI';
import InviteForm from './InviteForm';

const InviteModal = props => {
  const { data, modalIsOpen, closeModal, maxWidth = 680 } = props;

  const customStyles = {
    content: {
      maxWidth: maxWidth,
    },
  };

  const submitInvite = formValues => {
    console.log( formValues );
    invitationAPI.inviteEmployee( formValues ).then( () => closeModal() );
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={() => null}
      onRequestClose={() => null}
      style={customStyles}
      ariaHideApp={false}
      contentLabel={'Invite new Employee'}
    >
      <span
        className='icon-close'
        onClick={closeModal}
      />
      <div className='modal-head'>
        <div className='modal-title'>Invite new Employee</div>
      </div>
      <div className='modal-body'>
        <InviteForm
          onSubmit={submitInvite}
          data={data}
          closeModal={closeModal}
        />
      </div>
    </Modal>
  );
};

export default InviteModal;
