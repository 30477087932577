import React from 'react';
import ConfirmResetPasswordScreen from './ConfirmResetPasswordScreen/ConfirmResetPasswordScreen';
import RequestResetLinkScreen from './RequestResetLinkScreen';

const PasswordReset = props => {
  const token = new URL( window.location.href ).searchParams.get( 'token' );

  if ( token ) {
    return <ConfirmResetPasswordScreen {...props} />;
  } else {
    return <RequestResetLinkScreen {...props} />;
  }
};

export default PasswordReset;
