import { mainApi } from './mainApi';

export const joblinkUsersApi = mainApi.injectEndpoints( {
  endpoints: build => ( {
    getJoblinkUsers: build.query( {
      query: () => ( {
        url: '/joblinkUsers',
      } ),
      providesTags: ( res, err ) => ( err ? [] : [ 'users' ] ),
    } ),
    getJoblinkUser: build.query( {
      query: id => ( {
        url: `/joblinkUsers/${id}`,
      } ),
      providesTags: ( res, err, id ) => ( err ? [] : [ { type: 'users', id } ] ),
    } ),
    deleteJoblinkUser: build.mutation( {
      query: id => ( {
        url: `/joblinkUsers/${id}`,
        method: 'delete',
      } ),
      invalidatesTags: ( res, err, id ) => ( err ? [] : [ 'users', { type: 'users', id } ] ),
    } ),
    updateJoblinkUser: build.mutation( {
      query: ( { id, fields } ) => ( {
        url: `/joblinkUsers/${id}`,
        method: 'put',
        body: fields,
      } ),
      invalidatesTags: ( res, err, id ) => ( err ? [] : [ 'users', { type: 'users', id } ] ),
    } ),
  } ),
} );

export const {
  useGetJoblinkUserQuery,
  useGetJoblinkUsersQuery,
  useDeleteJoblinkUserMutation,
  useUpdateJoblinkUserMutation,
} = joblinkUsersApi;
