import { ReactComponent as AlertIcon } from '../../assets/images/alert-icon.svg';
import styles from './NoResults.module.sass';

export const NoResults = ( { text = 'No results found for specified filters' } ) => {
  return (
    <div className={styles['no-results']}>
      <AlertIcon />
      <span>{text}</span>
    </div>
  );
};
