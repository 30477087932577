import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ScreenContainer } from '../../components/ScreenContainer';
import { useGetEmployeesByAvailability, useGetMatchingEmployees } from '../../hooks';
import { useGetJobEventQuery, useSendOfferMutation } from '../../store/apis/eventsApi';
import { Filters, Header, Table } from './components';

export const EmployeeAssignmentScreen = () => {
  const { orderId } = useParams();
  const history = useHistory();

  const { data: jobEvent } = useGetJobEventQuery( orderId );
  const [ sendOffer ] = useSendOfferMutation();

  const [ costCenter, setCostCenter ] = useState( 'all' );
  const [ availability, setAvailability ] = useState( 'certainlyAvailable' );
  const [ searchTerm, setSearchTerm ] = useState( '' );

  const employees = useGetMatchingEmployees( jobEvent, availability, costCenter );

  const employeesByAvailabiliy = useGetEmployeesByAvailability( jobEvent );

  const isEmployeeAvailable = employee => {
    const isAvailable = !!employeesByAvailabiliy.available.find(
      availableEmployee => availableEmployee.id === employee.id
    );
    if ( isAvailable ) return 'available';

    const isUnavailable = !!employeesByAvailabiliy.unavailable.find(
      unavailableEmployee => unavailableEmployee.id === employee.id
    );
    if ( isUnavailable ) return 'unavailable';

    return 'unknown';
  };

  const [ selectedEmployees, setSelectedEmployees ] = useState( [] );

  const onAssign = () => {
    if ( selectedEmployees.length ) {
      sendOffer( {
        jobEventId: orderId,
        employeesIds: selectedEmployees
      } );
      history.goBack();
    }
  };

  useEffect( () => {
    if ( searchTerm.length >= 3 ) {
      setCostCenter( 'all' );
      setAvailability( 'all' );
    }
  }, [ searchTerm ] );

  const filteredEmployees = useMemo( () => {
    if ( searchTerm.length >= 3 )
      return employees.filter( employee =>
        [ employee.firstName, employee.lastName ]
          .join( ' ' )
          .toLowerCase()
          .includes( searchTerm.toLowerCase() )
      );
    return employees;
  }, [ employees, searchTerm ] );

  if ( !jobEvent ) return null;

  return (
    <ScreenContainer>
      <div className='container-fluid'>
        <Header
          jobEvent={jobEvent}
          onAssign={onAssign}
          selectedEmployees={selectedEmployees}
        />
        <Filters
          costCenter={costCenter}
          setCostCenter={setCostCenter}
          availability={availability}
          setAvailability={setAvailability}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
        <Table
          employees={filteredEmployees}
          jobEvent={jobEvent}
          isEmployeeAvailable={isEmployeeAvailable}
          selectedEmployees={selectedEmployees}
          setSelectedEmployees={setSelectedEmployees}
        />
      </div>
    </ScreenContainer>
  );
};
