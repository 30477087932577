import { DateTime } from 'luxon';
import moment from 'moment';
import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import {
  useDeclineOfferMutation,
  useGetJobEventQuery
} from '../../../store/apis/eventsApi';
import CancelOrAbortForm from './CancelOrAbortForm/CancelOrAbortForm';
import Default from './Default';
import { EditJobEventForm } from './EditJobEventForm';

const Filled = () => {
  const { orderId } = useParams();

  const { data: event } = useGetJobEventQuery( orderId );
  const [ declineOffer ] = useDeclineOfferMutation();

  const [ showHistory, setShowHistory ] = useState( false );
  const jobOffer = event?.JobOffer;

  const [ isEditModalOpened, setEditModalOpened ] = useState( false );
  const [ isCancelModalOpened, setCancelModalOpened ] = useState( false );

  const customStyles = {
    content: {
      maxWidth: 720
    }
  };

  const toggleEditModal = open => () => setEditModalOpened( open );
  const toggleCancelModal = open => () => setCancelModalOpened( open );

  const onCancelAcceptance = () => {
    declineOffer( orderId );
  };

  return (
    <>
      <Default />
      <div className='row'>
        <div className='col-12'>
          <div className='white-shadow-box mb-1'>
            <div className='mb-3'>Employees</div>
            <div className='info-cols'>
              {jobOffer.AcceptedEmployee ? (
                <div className='info-col'>
                  <span className='info-col__label'>
                    {jobOffer?.AcceptedEmployee.firstName}{' '}
                    {jobOffer?.AcceptedEmployee.lastName}
                  </span>
                  <div>
                    <small className='text-success mr-2'>Offer accepted</small>
                    <small
                      className='text-danger pointer'
                      onClick={onCancelAcceptance}
                    >
                      Cancel the acceptance
                    </small>
                  </div>
                </div>
              ) : (
                <div className='text-undefined'>Not assigned yet</div>
              )}
            </div>
          </div>
        </div>
        <div className='col-12'>
          <div className='d-flex flex-wrap flex-md-nowrap justify-content-center align-items-center justify-content-md-between pt-4 pb-4'>
            <div className='btn-group pb-4 pb-md-0'>
              <div className='btn btn--green mr-12'>Assign Employee</div>
              <button
                className='btn btn--light-green mr-12'
                onClick={toggleEditModal( true )}
              >
                Edit offer
              </button>
              {DateTime.fromISO( event.start ) > DateTime.now() && (
                <button
                  className='btn btn--light-danger'
                  onClick={toggleCancelModal( true )}
                >
                  Cancel offer
                </button>
              )}
            </div>
            <button
              className='btn btn--default'
              onClick={() => setShowHistory( !showHistory )}
            >
              {showHistory ? 'Hide' : 'Show'} document history
            </button>
          </div>
        </div>
        {showHistory && (
          <div className='col-12'>
            <div className='white-shadow-box mb-1'>
              <div className='mb-3'>Document History</div>
              <div className='info-cols'>
                <div className='info-col'>
                  <span className='info-col__label'>Company created Job Order</span>
                </div>
                <div className='info-col'>
                  <div className='info-col__label'>
                    {moment( event?.createdAt ).format( 'MMMM D YYYY, h:mm a' )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <ReactModal
          isOpen={isEditModalOpened}
          ariaHideApp={false}
          contentLabel={'Edit'}
          style={customStyles}
        >
          <div className='modal-head'>
            <div className='modal-title'>Edit Event</div>
          </div>
          <EditJobEventForm onClose={toggleEditModal( false )} />
        </ReactModal>
        <ReactModal
          isOpen={isCancelModalOpened}
          ariaHideApp={false}
          contentLabel={'Cancel'}
          style={customStyles}
        >
          <div className='modal-head'>
            <div className='modal-title'>Cancel Offer</div>
          </div>
          <CancelOrAbortForm
            closeModal={toggleCancelModal( false )}
            modalFormat={'cancel'}
            phase={'offer'}
          />
        </ReactModal>
      </div>
    </>
  );
};

export default Filled;
