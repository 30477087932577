import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { ReactComponent as SvgFileIcon } from '../../../assets/images/csv-file.svg';
import { LabeledField } from '../../../components/LabeledField';
import { NoResults } from '../../../components/NoResults';
import { Spinner } from '../../../components/Spinner';
import { StyledSelect } from '../../../components/StyledSelect';
import { useGetJobEventsPolling } from '../../../hooks';
import { useGetCompaniesQuery } from '../../../store/apis/companiesApi';
import { useGetCostCentersQuery } from '../../../store/apis/costCentersApi';
import { useGetAllEmployeesQuery } from '../../../store/apis/employeesApi';
import { useGetLocationsQuery } from '../../../store/apis/locationsApi';
import { groupEventsByCompany } from '../../../utils/utils';
import styles from '../ReportingScreen.module.sass';
import {
  customersView,
  datesView,
  employeesView,
  locationsView,
  weekdaysView
} from './costCentersUtils';
import { downloadCsv } from './utils';

export const CostCentersReporting = () => {
  const { data: events } = useGetJobEventsPolling();
  const { data: employees } = useGetAllEmployeesQuery();
  const { data: costCenters } = useGetCostCentersQuery();
  const { data: companies } = useGetCompaniesQuery();
  const { data: locations } = useGetLocationsQuery();

  const [ start, setStart ] = useState( DateTime.now().startOf( 'month' ) );
  const [ end, setEnd ] = useState( DateTime.now().endOf( 'month' ) );
  const [ costCenterId, setCostCenterId ] = useState();
  const [ order, setOrder ] = useState( 'customers' );

  const onDateChange = setter => date => setter( DateTime.fromJSDate( date ) );

  const data = useMemo( () => {
    if ( !events || !employees || !companies || !locations || !costCenterId ) return;
    const filteredEvents = events.filter(
      event =>
        event.phase === 'log' &&
        ( costCenterId === 'all' ||
          event.JobRole.Location.CostCenterId === costCenterId ) &&
        DateTime.fromISO( event.start ) >= start &&
        DateTime.fromISO( event.end ) <= end
    );
    const byCompany = groupEventsByCompany( filteredEvents ).map(
      ( { companyId, events } ) => ( {
        company: companies.find( company => company.id === companyId ),
        events,
        byJobRole: [],
        totalHours: 0,
        totalJobs: 0
      } )
    );
    if ( order === 'customers' ) {
      return customersView( byCompany, locations );
    } else if ( order === 'locations' ) {
      return locationsView( byCompany, locations );
    } else if ( order === 'dates' ) {
      return datesView( filteredEvents, companies, locations );
    } else if ( order === 'employees' ) {
      return employeesView( filteredEvents, employees, locations );
    } else if ( order === 'weekdays' ) {
      return weekdaysView( filteredEvents, companies, locations );
    }
  }, [ events, employees, companies, locations, start, end, order, costCenterId ] );

  const downloadCsvReport = () => {
    if ( !data ) return;
    const rows = [
      [
        order === 'employees' ? 'Employee' : 'Customer',
        'Job role',
        'Total hours',
        'Total jobs'
      ],
      ...data.map( row => row.map( col => col.value ) )
    ];
    downloadCsv(
      rows,
      `${
        costCenterId === 'all'
          ? 'All Cost Centers'
          : costCenters.find( center => center.id === costCenterId ).name
      }-${DateTime.now().toFormat( 'D' )}`
    );
  };

  console.log( { data } );

  if ( !events || !employees || !costCenters || !companies || !locations )
    return <Spinner />;

  return (
    <>
      <div className={styles['filters']}>
        <LabeledField label={'Cost Center'}>
          <StyledSelect
            options={[
              { value: 'all', label: 'All Cost Centers' },
              ...costCenters.map( costCenter => ( {
                value: costCenter.id,
                label: costCenter.name
              } ) )
            ]}
            value={costCenterId}
            onChange={setCostCenterId}
          />
        </LabeledField>
        <LabeledField label='Start'>
          <ReactDatePicker
            selected={start.toJSDate()}
            onChange={onDateChange( setStart )}
            className='form-control'
            wrapperClassName={styles['date-input']}
            dateFormat='dd/MM/yyyy'
          />
        </LabeledField>
        <LabeledField label='End'>
          <ReactDatePicker
            selected={end.toJSDate()}
            onChange={onDateChange( setEnd )}
            className='form-control'
            wrapperClassName={styles['date-input']}
            dateFormat='dd/MM/yyyy'
          />
        </LabeledField>

        <LabeledField label={'Listing order'}>
          <StyledSelect
            options={[
              { label: 'List by locations', value: 'locations' },
              { label: 'List by customers', value: 'customers' },
              { label: 'List by dates', value: 'dates' },
              { label: 'List by employees', value: 'employees' },
              { label: 'List by weekdays', value: 'weekdays' }
            ]}
            value={order}
            onChange={setOrder}
          />
        </LabeledField>
      </div>

      {data?.length > 1 ? (
        <section className={styles['reporting-item']}>
          <h1>
            {costCenterId === 'all'
              ? 'All Cost Centers'
              : costCenters.find( center => center.id === costCenterId ).name}
            <SvgFileIcon
              onClick={downloadCsvReport}
              className={styles['download']}
            />
          </h1>
          <table className='table'>
            <thead>
              <tr>
                <th>{order === 'employees' ? 'Employee' : 'Customer'}</th>
                <th>Job role</th>
                <th className={styles['center']}>Total hours</th>
                <th className={styles['center']}>Total jobs</th>
              </tr>
            </thead>
            <tbody>
              {data.map( ( row, rowIndex ) => (
                <tr key={rowIndex}>
                  {row.map( ( col, colIndex ) => (
                    <td
                      key={colIndex}
                      className={col.className}
                    >
                      {col.value}
                    </td>
                  ) )}
                </tr>
              ) )}
            </tbody>
          </table>
        </section>
      ) : (
        <NoResults />
      )}
    </>
  );
};
