import styles from '../../OperationsScreen.module.sass';

export const HeaderDay = ( { day } ) => {
  return (
    <div className={styles['header-day']}>
      <span>{day.toFormat( 'ccc' )}</span>
      <span>{day.toFormat( 'd.L.' )}</span>
    </div>
  );
};
