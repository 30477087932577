import React, { memo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useGetCompaniesQuery } from '../../../store/apis/companiesApi';
import { useUpdateCostCenterMutation } from '../../../store/apis/costCentersApi';

// const getCostCenterCompaniesIds = (costCenter) => [...new Set(costCenter.Locations.map(loc => loc.CompanyAccountId))];

const DropTableRow = ( { costCenter } ) => {
  const { data: accounts } = useGetCompaniesQuery();
  const [ updateCostCenter ] = useUpdateCostCenterMutation();

  // States
  const [ collapse, isCollapse ] = useState( false );
  const [ isEditing, setIsEditing ] = useState( false );
  const [ name, setName ] = useState( costCenter?.name );

  // Actions
  const onToggleSubMenu = () => isCollapse( !collapse && !isEditing );

  const onEditing = e => {
    setName( e.target.value );
  };

  const cancelEditing = () => {
    setName( costCenter.name );
    setIsEditing( false );
  };

  const openEditing = () => {
    setIsEditing( true );
    isCollapse( false );
  };

  const editingSave = () => {
    updateCostCenter( { id: costCenter.id, name } )
      .unwrap()
      .then( () => {
        setIsEditing( false );
      } );
  };

  return (
    <tr
      key={costCenter?.id}
      className={'tr-has-children ' + ( isEditing ? 'editing' : '' )}
    >
      <td className='link-in'>
        <span onClick={onToggleSubMenu}>
          <span className='drop-down-frame link-el'>
            <svg
              className={'drop-down ' + ( collapse ? 'opened' : 'closed' )}
              width='10'
              height='7'
              viewBox='0 0 10 7'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M9 1.33301L5 5.33301L1 1.33301L9 1.33301Z'
                fill='#A1A1AA'
                stroke='#A1A1AA'
              />
            </svg>
          </span>
          <input
            value={name}
            onChange={onEditing}
            disabled={!isEditing}
          />
        </span>
        <ul
          className={
            'custom-list color-green collapse ' + ( collapse ? 'show' : 'hide m-0' )
          }
        >
          {costCenter?.Locations.map( location => (
            <li key={location.id}>
              <Link
                to={`/accounts-companies/${location.CompanyAccountId}/edit-company-locations`}
              >
                {accounts?.find( company => company.id === location.CompanyAccountId ).name}
              </Link>
            </li>
          ) )}
        </ul>
      </td>
      <td className='link-in'>
        <span onClick={onToggleSubMenu}>{costCenter?.Locations.length} locations</span>
        <ul className={'custom-list collapse ' + ( collapse ? 'show' : 'hide m-0' )}>
          {costCenter?.Locations?.map( el => (
            <li key={el.id}>{el.name}</li>
          ) )}
        </ul>
      </td>
      <td className='control-elements'>
        {!isEditing && (
          <>
            {/* <span onClick={() => onDelete(costCenter?.id)} className="delete mr-4">Delete</span> */}
            <span
              onClick={openEditing}
              className='rename'
            >
              Rename
            </span>
          </>
        )}
        {isEditing && (
          <>
            <span
              onClick={editingSave}
              className='color-green mr-4'
            >
              Save
            </span>
            <span onClick={cancelEditing}>Cancel</span>
          </>
        )}
      </td>
    </tr>
  );
};

export default memo( DropTableRow );
