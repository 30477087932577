import { concat } from '../../utils/utils';
import styles from './LabeledField.module.sass';

export const LabeledField = ( { children, label, className } ) => {
  return (
    <label className={concat( styles['labeled-field'], className )}>
      <span>{label}</span>
      {children}
    </label>
  );
};
