import { DateTime } from 'luxon';

export const sortConversationsByDate = dialogs =>
  [ ...dialogs ].sort( ( a, b ) => {
    const aDate = DateTime.fromISO( a.LastMessage?.updatedAt || a.updatedAt );
    const bDate = DateTime.fromISO( b.LastMessage?.updatedAt || b.updatedAt );

    return bDate - aDate;
  } );

export const messagesSortingsMap = {
  date: sortConversationsByDate,
};
