import { InfoColumns } from '../InfoColumns/InfoColumns';

export const InfoRows = ( { rows = [], className, onlyContainerBox, title, children } ) => {
  const Content = () => (
    <>
      <div className='mb-3'>{title}</div>
      {rows.map( ( row, index ) => (
        <InfoColumns
          columns={row.items}
          key={index}
          disableShadowBox={onlyContainerBox}
          disableWrapper={onlyContainerBox}
          {...( row.props || {} )}
        />
      ) )}
      {children}
    </>
  );

  return (
    <div className={[ 'row', className ].join( ' ' )}>
      {onlyContainerBox ? (
        <div className='col-12'>
          <div className='white-shadow-box'>
            <Content />
          </div>
        </div>
      ) : (
        <Content />
      )}
    </div>
  );
};
