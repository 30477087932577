import { DateTime } from 'luxon';
import { ReactComponent as PartlyAvailableIcon } from '../assets/images/operations/partly-available.svg';
import { ReactComponent as UnavailableIcon } from '../assets/images/operations/unavailable.svg';
import { ReactComponent as UnknownIcon } from '../assets/images/operations/unknown.svg';
import { OperationBadge } from '../screens/OperationsScreen/components';

export const getJobLogValues = ( event, eventStartTimeAsDefault ) => ( {
  Employee_Comment: event?.JobLog?.Employee_Comment,
  Employee_Rating: event?.JobLog?.Employee_Rating,
  Employee_Start: event?.JobLog?.Employee_Start
    ? new Date( event?.JobLog?.Employee_Start )
    : eventStartTimeAsDefault
    ? new Date( event?.start )
    : undefined,
  Employee_End: event?.JobLog?.Employee_Start
    ? new Date( event?.JobLog?.Employee_End )
    : eventStartTimeAsDefault
    ? new Date( event?.end )
    : undefined,
  Employee_LunchBreak: event?.JobLog?.Employee_LunchBreak,
  Customer_Rating: event?.JobLog?.Customer_Rating,
  Customer_Comment: event?.JobLog?.Customer_Comment
} );

export const getLogStatusData = event => {
  const logPhase = event.JobLog.phase;
  if ( logPhase === 'finalized' ) {
    const decision = event.JobLog.Joblink_Decision;
    if ( decision === 'accept' ) return { label: 'Log completed', color: 'green' };
    else return { label: 'Log rejected by Joblink', color: 'red' };
  } else if ( logPhase === 'CustomerUser' )
    return { label: 'Waiting for customer’s approval', color: 'blue' };
  else if ( logPhase === 'Employee' )
    return { label: 'Waiting for employee’s log', color: 'blue' };
  else if ( logPhase === 'JoblinkUser' )
    return { label: 'Waiting for Joblink’s approval', color: 'yellow' };
};

export const getJobLogData = event =>
  event
    ? {
        Employee_Comment: event.JobLog.Employee_Comment,
        Employee_Rating: event.JobLog.Employee_Rating,
        Employee_Start: event.JobLog.Employee_Start,
        Employee_End: event.JobLog.Employee_End,
        Employee_LunchBreak: event.JobLog.Employee_LunchBreak,
        Customer_Rating: event.JobLog.Customer_Rating,
        Customer_Comment: event.JobLog.Customer_Comment
      }
    : undefined;

export const getAvailabilityType = availability => {
  const start = DateTime.fromISO( availability.start );
  const end = DateTime.fromISO( availability.end );
  const diff = end.diff( start, 'hours' ).hours;
  if ( diff < 18 ) {
    return 'partly';
  } else {
    return availability.type;
  }
};

export const getEmployeeWeekTiles = (
  week,
  eventsByDate,
  availabilitiesByDate,
  employee,
  jobRole,
  jobRoleGroups
) =>
  Array( 7 )
    .fill()
    .map( ( _, i ) => {
      const day = week.plus( { days: i } ).toISODate();
      const events = eventsByDate[day];
      if ( events?.length ) {
        return {
          content: (
            <OperationBadge
              events={events}
              employee={employee}
              jobRole={jobRole}
              jobRoleGroups={jobRoleGroups}
            />
          )
        };
      }
      const availability = availabilitiesByDate[day];
      if ( availability ) {
        const type = getAvailabilityType( availability );
        if ( type === 'available' ) {
          return undefined;
        } else
          return {
            content: (
              <OperationBadge
                icon={type === 'partly' ? PartlyAvailableIcon : UnavailableIcon}
              />
            )
          };
      }
      return {
        content: <OperationBadge icon={UnknownIcon} />
      };
    } );

export const concat = ( ...names ) => names.join( ' ' ).trim();
export const roundHalf = num => Math.floor( num / 0.5 ) * 0.5;

export const groupEventsByCompany = events => {
  const eventsByCompanyId = {};
  for ( const event of events ) {
    const companyId = event.JobRole.Location.CompanyAccountId;
    if ( eventsByCompanyId[companyId] ) eventsByCompanyId[companyId].push( event );
    else eventsByCompanyId[companyId] = [ event ];
  }
  return Object.entries( eventsByCompanyId ).map( ( [ companyId, events ] ) => ( {
    companyId: +companyId,
    events
  } ) );
};

export const groupEventsByJobRole = events => {
  const eventsByJobRoleId = {};
  for ( const event of events ) {
    const jobRoleId = event.JobRoleId;
    if ( eventsByJobRoleId[jobRoleId] ) eventsByJobRoleId[jobRoleId].push( event );
    else eventsByJobRoleId[jobRoleId] = [ event ];
  }
  return Object.entries( eventsByJobRoleId ).map( ( [ jobRoleId, events ] ) => ( {
    jobRoleId: +jobRoleId,
    events
  } ) );
};

export const groupEventsByLocation = events => {
  const eventsByLocationId = {};
  for ( const event of events ) {
    const locationId = event.JobRole.Location.id;
    if ( eventsByLocationId[locationId] ) eventsByLocationId[locationId].push( event );
    else eventsByLocationId[locationId] = [ event ];
  }
  return Object.entries( eventsByLocationId ).map( ( [ locationId, events ] ) => ( {
    locationId: +locationId,
    events
  } ) );
};

export const groupEventsByDate = events => {
  const eventsByDate = {};
  for ( const event of events ) {
    const date = DateTime.fromISO( event.start ).toISODate();
    if ( eventsByDate[date] ) eventsByDate[date].push( event );
    else eventsByDate[date] = [ event ];
  }
  return Object.entries( eventsByDate ).map( ( [ date, events ] ) => ( {
    date,
    events
  } ) );
};

export const groupEventsByEmployee = events => {
  const eventsByEmployee = {};
  for ( const event of events ) {
    const employeeId = event.JobOffer.AcceptedEmployeeId;
    if ( eventsByEmployee[employeeId] ) eventsByEmployee[employeeId].push( event );
    else eventsByEmployee[employeeId] = [ event ];
  }
  return Object.entries( eventsByEmployee ).map( ( [ employeeId, events ] ) => ( {
    employeeId: +employeeId,
    events
  } ) );
};

export const groupEventsByWeekday = events => {
  const eventsByWeekday = {};
  for ( const event of events ) {
    const weekday = DateTime.fromISO( event.start ).weekday;
    if ( eventsByWeekday[weekday] ) eventsByWeekday[weekday].push( event );
    else eventsByWeekday[weekday] = [ event ];
  }
  return Object.entries( eventsByWeekday ).map( ( [ weekday, events ] ) => ( {
    weekday: {
      num: +weekday,
      name: DateTime.fromObject( { weekday: +weekday } ).toFormat( 'cccc' )
    },
    events
  } ) );
};

export const getEmployeeWorkingHours = ( acceptedOffers, jobRoleId ) => {
  let sum = 0;
  for ( const offer of acceptedOffers ) {
    if ( jobRoleId !== 'all' && +offer.JobEvent.JobRoleId !== +jobRoleId ) continue;
    sum += DateTime.fromISO( offer.JobEvent.end ).diff(
      DateTime.fromISO( offer.JobEvent.start ),
      'hours'
    ).hours;
  }
  return roundHalf( sum );
};
