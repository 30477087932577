import React from 'react';

import { classConversion } from '../../../../utils/classConversion';

import searchIcon from '../../../../assets/images/search-icon.svg';

import './input.scss';

export const Input = ( {
  search = false,
  modificators,
  placeholdrer = '',
  value,
  onChange,
  style,
} ) => {
  const inputModification = {
    marginBtn: modificators?.marginBtn == true,
    fullInput: modificators?.width == 'full',
  };

  const onInputChange = event => onChange( event.target.value );

  return (
    <div
      style={style}
      className={`input ${classConversion( inputModification, 'input' )}`}
    >
      <input
        type={'text'}
        placeholder={placeholdrer}
        value={value}
        onChange={onInputChange}
      />

      {search && (
        <img
          className='input-search'
          src={searchIcon}
          alt=''
        />
      )}
    </div>
  );
};
