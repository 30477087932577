import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { StyledSelect } from '../../../../components/StyledSelect';
import { useGetLocationQuery } from '../../../../store/apis/locationsApi';

export const JobRoleField = () => {
  const navLocation = useLocation();

  const { values, setFieldValue } = useFormikContext();

  const locationId = +values.location;
  const jobRoleId = +values.jobRole;

  const { data: location, isFetching } = useGetLocationQuery( locationId || skipToken );

  const jobRoles = locationId ? location?.JobRoles : undefined;

  const onSelectChange = id => {
    setFieldValue( 'jobRole', id );
  };

  useEffect( () => {
    if (
      locationId &&
      jobRoles?.length &&
      !isFetching &&
      !jobRoles.find( jobRole => jobRole.id === jobRoleId )
    ) {
      setFieldValue( 'jobRole', jobRoles[0].id );
    } else if ( jobRoles && !jobRoles.length ) {
      setFieldValue( 'jobRole', '' );
    }
  }, [ locationId, jobRoles ] );

  useEffect( () => {
    if ( navLocation.state?.jobRoleId ) {
      setFieldValue( 'jobRole', navLocation.state.jobRoleId );
    }
  }, [ navLocation.state ] );

  return (
    <div className='form-row w-100 select-box'>
      <label>Job Role</label>
      <StyledSelect
        options={jobRoles?.map( jobRole => ( {
          label: jobRole.name,
          value: jobRole.id
        } ) )}
        onChange={onSelectChange}
        value={jobRoleId}
        containerStyle={{
          maxWidth: 'none',
          marginLeft: 0
        }}
        isDisabled={!jobRoles?.length}
      />
    </div>
  );
};
