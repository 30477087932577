import { Field, Form, Formik } from 'formik';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useGetCostCentersQuery } from '../../../store/apis/costCentersApi';
import {
  useCreateJobEventMutation,
  useCreateOfferMutation
} from '../../../store/apis/eventsApi';
import {
  CostCenterField,
  DescriptionField,
  InternalCommentField,
  JobRoleField,
  LocationField,
  SkillsField,
  TaskField
} from './components';
import { EndTimeField } from './components/EndTimeField';
import { StartTimeField } from './components/StartTimeField';
import { createOrderFormSchema } from './formValidation';

const CreateOrderForm = () => {
  const history = useHistory();

  const { data: costCenters } = useGetCostCentersQuery();
  const [ createOffer ] = useCreateOfferMutation();

  const initStartTime = useMemo(
    () => DateTime.now().plus( { hour: 1 } ).startOf( 'hour' ),
    []
  );

  const initialValues = {
    costCenter: '',
    location: '',
    company: '',
    jobRole: '',
    task: '',
    startTime: initStartTime.toJSDate(),
    endTime: initStartTime.plus( { hours: 6 } ).toJSDate(),
    eventSkills: [],
    description: '',
    internalComment: '',
    open: false
  };
  const [ createJobEvent ] = useCreateJobEventMutation();

  if ( !costCenters ) return null;

  const onSubmit = ( values, { setSubmitting } ) => {
    createJobEvent( values )
      .unwrap()
      .then( ( { id } ) => {
        if ( values.open && id ) {
          createOffer( { eventId: id, description: values.description } )
            .unwrap()
            .then( () => {
              setSubmitting( false );
              history.push( `/event-listing/${id}` );
            } );
        } else {
          setSubmitting( false );
          history.goBack();
        }
      } );
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={createOrderFormSchema}
      validateOnMount={true}
    >
      {( { isSubmitting, isValid, setFieldValue } ) => (
        <Form className='white-shadow-box pb-4'>
          <div className='form__body'>
            <div className='form-group'>
              <LocationField />
              <CostCenterField />
            </div>
            <div className='form-group'>
              <JobRoleField />
              <TaskField />
            </div>
            <div className='form-group'>
              <SkillsField />
            </div>
            <div className='form-group'>
              <DescriptionField />
              <InternalCommentField />
            </div>
            <div className='form-group'>
              <StartTimeField />
              <EndTimeField />
            </div>
          </div>
          <div className='form-footer'>
            <div className='mt-4'>
              <div className='form-group align-items-center'>
                <button
                  className='btn btn--green'
                  type='submit'
                  disabled={isSubmitting || !isValid}
                >
                  Save
                </button>
                <button
                  className='btn btn--light-green'
                  type='submit'
                  disabled={isSubmitting || !isValid}
                  onClick={() => setFieldValue( 'open', true )}
                >
                  Save & Open
                </button>
                <div
                  className='checkboxes-item ml-md-1'
                  style={{ display: 'none' }}
                >
                  <label className='custom-checkbox custom-checkbox--default-color mt-4 mt-md-2 ml-0'>
                    <Field
                      type='checkbox'
                      name='OfferAccepted'
                    />
                    <span className='ml-1'>
                      Send notification when Job Offer will be Accepted
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CreateOrderForm;
