import moment from 'moment';
import React, { memo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  useGetJobEventQuery,
  useReactivateOfferMutation
} from '../../../store/apis/eventsApi';
import Default from './Default';

const Canceled = () => {
  const { orderId } = useParams();

  const event = useGetJobEventQuery( orderId );
  const [ reactivateJobOffer ] = useReactivateOfferMutation();

  const [ showHistory, setShowHistory ] = useState( false );

  const onReActivateJobOrder = () => {
    reactivateJobOffer( orderId );
  };
  return (
    <>
      <Default />
      <div className='row'>
        <div className='col-12'>
          <div className='d-flex justify-content-between pt-4 pb-4'>
            <button
              className='btn btn--green'
              onClick={onReActivateJobOrder}
            >
              Activate
            </button>
            <button
              className='btn btn--default'
              onClick={() => setShowHistory( !showHistory )}
            >
              {showHistory ? 'Hide' : 'Show'} document history
            </button>
          </div>
        </div>
        {showHistory && (
          <div className='col-12'>
            <div className='white-shadow-box mb-1'>
              <div className='mb-3'>Document History</div>
              <div className='info-cols mb-4'>
                <div className='info-col'>
                  <span className='info-col__label'>
                    Order canceled by Joblink User/Admin
                  </span>
                </div>
                <div className='info-col'>
                  <span className='info-col__label'>
                    {moment( event.data?.updatedAt ).format( 'MMMM D YYYY, h:mm a' )}
                  </span>
                </div>
              </div>
              <div className='info-cols'>
                <div className='info-col'>
                  <span className='info-col__label'>Company created Job Order</span>
                </div>
                <div className='info-col'>
                  <span className='info-col__label'>
                    {moment( event.data?.createdAt ).format( 'MMMM D YYYY, h:mm a' )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default memo( Canceled );
