import { OperationsJobRoleWeek } from './OperationsJobRoleWeek';

export const OperationsWeek = ( {
  weekStart,
  goCreateOrder,
  jobRoles,
  getLocationName,
  showOnlyEmpty,
  weekElementRef,
} ) => {
  return (
    <div
      className='week'
      ref={weekElementRef}
    >
      {jobRoles.map( ( jobRole, index ) => (
        <OperationsJobRoleWeek
          getLocationName={getLocationName}
          jobRole={jobRole}
          goCreateOrder={goCreateOrder}
          weekStart={weekStart}
          showOnlyEmpty={showOnlyEmpty}
          key={index}
        />
      ) )}
    </div>
  );
};
