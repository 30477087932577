import { DateTime } from 'luxon';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useGetCostCentersQuery } from '../../store/apis/costCentersApi';
import { useGetLocationsQuery } from '../../store/apis/locationsApi';
import { OperationBody } from './OperationsBody';
import './OperationsElement.sass';
import { OperationsElementControls } from './OperationsElementControls';
import { OperationsElementHeader } from './OperationsElementHeader';

export const OperationsElement = () => {
  const costCenters = useGetCostCentersQuery();
  const { data: allLocations } = useGetLocationsQuery();

  const [ currentWeekStart, setCurrentWeekStart ] = useState(
    DateTime.now().startOf( 'week' )
  );
  const [ showAllCostCenters, setShowAllCostCenters ] = useState( true );
  const [ costCenter, setCostCenter ] = useState();
  const [ scrollTopButtonDisabled, setScrollTopButtonDisabled ] = useState( true );
  const [ homeButtonDisabled, setHomeButtonDisabled ] = useState( true );
  const [ currentLocation, setCurrentLocation ] = useState();
  const [ expandedLocations, setExpandedLocations ] = useState( [] );
  const [ isEmpty, setEmpty ] = useState( false );

  const elementBodyRef = useRef();
  const [ maxWidth, setMaxWidth ] = useState();

  useEffect( () => {
    const container = document.querySelector( '.app-content .container-fluid' );
    if ( container ) {
      const containerPadding = +window
        .getComputedStyle( container, null )
        .getPropertyValue( 'padding-left' )
        .slice( 0, -2 );
      setMaxWidth( container.clientWidth - containerPadding * 2 );
    }
  }, [] );

  const goNextWeek = () => {
    setCurrentWeekStart( currentWeekStart.plus( { week: 1 } ) );
  };
  const goPrevWeek = () => {
    setCurrentWeekStart( currentWeekStart.minus( { week: 1 } ) );
  };
  const goHome = () => {
    setCurrentWeekStart( DateTime.now().startOf( 'week' ) );
  };

  // const allLocations = useMemo( () => {
  //   if ( !costCenters.data ) return [];
  //   return costCenters.data
  //     .map( center => center.Locations )
  //     .flat()
  //     .sort( ( a, b ) => ( a.name > b.name ? 1 : -1 ) );
  // }, [ costCenters ] );

  const locations = useMemo( () => {
    if ( currentLocation ) return [ currentLocation ];
    if ( !allLocations ) return [];
    let tmp = showAllCostCenters
      ? [ ...allLocations ]
      : costCenter
      ? [ ...costCenter.Locations ]
      : [];
    return tmp.sort( ( a, b ) => ( a.name > b.name ? 1 : -1 ) );
  }, [ allLocations, costCenter, showAllCostCenters, currentLocation ] );

  const weeksStarts = useMemo( () => {
    return [
      currentWeekStart,
      currentWeekStart.plus( { week: 1 } ),
      currentWeekStart.plus( { week: 2 } ),
    ];
  }, [ currentWeekStart ] );

  useEffect( () => {
    if ( costCenters.data && !costCenter && costCenters.data.length ) {
      setCostCenter( costCenters.data[0] );
    }
  }, [ costCenters.data, costCenter ] );

  useEffect( () => {
    setHomeButtonDisabled( currentWeekStart.hasSame( DateTime.now(), 'week' ) );
  }, [ currentWeekStart ] );

  const onCostCenterChange = option => {
    if ( option.value === 'all' ) {
      setShowAllCostCenters( true );
    } else if ( costCenters.data ) {
      setCostCenter( costCenters.data.find( center => center.id === option.value ) );
      setShowAllCostCenters( false );
    }
    setCurrentLocation( undefined );
  };

  const onBodyScroll = event => {
    setScrollTopButtonDisabled( event.target.scrollTop === 0 );
  };

  const onSearchLocation = option => {
    if ( costCenters.data ) {
      const location = allLocations.find( location => location.id === option.value );
      if ( location ) {
        setShowAllCostCenters( false );
        setCurrentLocation( location );
      }
    }
  };

  const scrollBodyToTop = () => {
    if ( elementBodyRef.current ) elementBodyRef.current.scrollTop = 0;
  };

  const onCalendarDateSelect = date => {
    setCurrentWeekStart( DateTime.fromJSDate( date ).startOf( 'week' ) );
  };

  const className = useMemo(
    () =>
      [
        'operations-element',
        currentWeekStart.weekNumber % 2 === 0 ? 'even' : 'odd',
        isEmpty ? 'empty' : undefined,
      ].join( ' ' ),
    [ currentWeekStart.weekNumber, isEmpty ]
  );

  return (
    <div
      className={className}
      style={{ maxWidth }}
    >
      <OperationsElementControls
        costCenter={showAllCostCenters ? undefined : costCenter}
        costCenters={costCenters.data}
        locations={locations}
        onCostCenterChange={onCostCenterChange}
        onLocationSearch={onSearchLocation}
        onLeftClick={goPrevWeek}
        onRightClick={goNextWeek}
        onTopClick={scrollBodyToTop}
        onHomeClick={goHome}
        scrollTopButtonDisabled={scrollTopButtonDisabled}
        homeButtonDisabled={homeButtonDisabled}
        onCalendarDateSelect={onCalendarDateSelect}
      />
      <OperationsElementHeader
        weeksStarts={weeksStarts}
        locations={expandedLocations}
      />
      <div
        className='operations-element-body'
        ref={elementBodyRef}
        onScroll={onBodyScroll}
      >
        <OperationBody
          locations={locations}
          weeksStarts={weeksStarts}
          expandedLocations={expandedLocations}
          setExpandedLocations={setExpandedLocations}
          setEmpty={setEmpty}
        />
      </div>
    </div>
  );
};
