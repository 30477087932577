import React, { lazy } from 'react';
import { withSuspense } from '../../hoc/withSuspense';
import AccountsScreen from '../../screens/AccountsScreen';
import AccountScreen from '../../screens/AccountsScreen/AccountScreen';
import EditAccountInfoScreen from '../../screens/AccountsScreen/AccountScreen/EditAccountInfoScreen';
import { CustomerUserEditScreen } from '../../screens/AccountsScreen/CustomerUserEditScreen';
import { CustomerUserScreen } from '../../screens/AccountsScreen/CustomerUserScreen';
import CompanyDetailsScreen from '../../screens/CompaniesScreen/CompanyDetailsScreen';
import EditCompanyInfoScreen from '../../screens/CompaniesScreen/EditCompanyInfoScreen';
import EditCompanyLocationsScreen from '../../screens/CompaniesScreen/EditCompanyLocationsScreen';
import CostCentersScreen from '../../screens/CostCentersScreen';
import DashboardScreenContainer from '../../screens/DashboardScreen/DashboardScreen';
import { EmployeeAssignmentScreen } from '../../screens/EmployeeAssignmentScreen';
import EmployeesScreen from '../../screens/EmployeesScreen';
import EventListingContainer from '../../screens/EventListingScreen';
import JobLogsProcessingScreen from '../../screens/JobLogsProcessingScreen';
import MessagesScreen, { CreateConversationScreen } from '../../screens/MessagesScreen';
import ChatScreen from '../../screens/MessagesScreen/ChatScreen/ChatScreen.jsx';
import NewOrderContainer from '../../screens/NewOrderScreen';
import { OperationsScreen } from '../../screens/OperationsScreen/OperationsScreen';
import NewOrderReceived from '../../screens/OrderReceivedScreen';
import ProfileScreen from '../../screens/ProfileScreen';
import ProfileEditPersonalInfo from '../../screens/ProfileScreen/EditPersonalInfoScreen/ProfileEditPersonalInfo';
import { ReportingScreen } from '../../screens/ReportingScreen';
import TasksContainer from '../../screens/TasksScreen/TasksContainer';

const CompaniesScreen = lazy( () => import( '../../screens/CompaniesScreen' ) );
const JoblinkApiScreen = lazy( () => import( '../../screens/JoblinkApiScreen' ) );

export const AccountsSubMenu = [
  {
    id: 1,
    to: '/accounts-companies',
    label: '- Companies'
  },
  {
    id: 2,
    to: '/accounts-joblink-admins',
    label: '- Joblink Admins'
  },
  {
    id: 3,
    to: '/accounts-joblink-users',
    label: '- Joblink Users'
  },
  {
    id: 4,
    to: '/accounts-employees',
    label: '- Employees'
  },
  {
    id: 5,
    to: '/customer-users',
    label: '- Customer Users'
  }
];

export const AdminSettingsSubMenu = [
  {
    id: 1,
    to: '/cost-centers',
    label: '- Cost centers'
  },
  {
    id: 3,
    to: '/tasks',
    label: '- Tasks'
  },
  {
    id: 4,
    to: '/joblink-api',
    label: '- Joblink API'
  }
];

export const ReportingSubMenu = [
  {
    id: 1,
    to: '/reporting-company',
    label: '- Company'
  },
  {
    id: 2,
    to: '/reporting-employee',
    label: '- Employee'
  },
  {
    id: 3,
    to: '/reporting-cost-center',
    label: '- Cost center'
  }
];

const routes = [
  {
    path: '/',
    exact: true,
    component: <DashboardScreenContainer />
  },
  {
    path: '/messages',
    component: <MessagesScreen title='Messages' />
  },
  {
    path: '/chat/create',
    component: <CreateConversationScreen />
  },
  {
    path: '/event-listing',
    exact: true,
    component: <EventListingContainer />
  },
  {
    path: '/event-listing/:orderId',
    exact: true,
    component: <NewOrderReceived />
  },
  {
    path: '/event-listing/:orderId/employee-assignment',
    exact: true,
    component: <EmployeeAssignmentScreen />
  },
  {
    path: '/new-order',
    component: <NewOrderContainer />
  },
  {
    path: '/job-logs-processing',
    component: <JobLogsProcessingScreen title='Job logs processing' />
  },
  {
    path: '/accounts-companies',
    exact: true,
    component: withSuspense( CompaniesScreen )
  },
  {
    path: '/accounts-companies/:companyId',
    exact: true,
    component: <CompanyDetailsScreen />
  },
  {
    path: '/accounts-companies/:companyId/edit-company-info',
    exact: true,
    component: <EditCompanyInfoScreen />
  },
  {
    path: '/accounts-companies/:companyId/edit-company-locations',
    exact: true,
    component: <EditCompanyLocationsScreen />
  },
  {
    path: '/accounts-joblink-admins',
    exact: true,
    component: (
      <AccountsScreen
        title='Joblink Admins'
        role='admin'
      />
    )
  },
  {
    path: '/accounts-joblink-users',
    exact: true,
    component: (
      <AccountsScreen
        title='Joblink Users'
        role='user'
      />
    )
  },
  {
    path: '/customer-users',
    exact: true,
    component: (
      <AccountsScreen
        title='Customer Users'
        role='customerUsers'
      />
    )
  },
  {
    path: '/accounts-joblink/:id',
    exact: true,
    component: <AccountScreen />
  },
  {
    path: '/accounts-joblink/:id/edit-user-info',
    exact: true,
    component: <EditAccountInfoScreen />
  },
  {
    path: '/customer-users/:id',
    exact: true,
    component: <CustomerUserScreen />
  },
  {
    path: '/customer-users/:id/edit',
    exact: true,
    component: <CustomerUserEditScreen />
  },
  {
    path: '/accounts-employees',
    exact: true,
    component: <EmployeesScreen />
  },
  {
    path: '/accounts-employees/:employeeId',
    exact: true,
    component: <ProfileScreen />
  },
  {
    path: '/accounts-employees/:employeeId/edit-person-info',
    exact: true,
    component: <ProfileEditPersonalInfo />
  },
  {
    path: '/cost-centers',
    component: <CostCentersScreen title='Cost centers' />
  },
  {
    path: '/tasks',
    component: <TasksContainer />
  },
  {
    path: '/joblink-api',
    component: withSuspense( JoblinkApiScreen )
  },

  {
    path: '/chat/:id',
    component: <ChatScreen />
  },
  {
    path: '/operations',
    component: <OperationsScreen />
  },
  {
    path: '/reporting-company',
    component: <ReportingScreen type='company' />
  },
  {
    path: '/reporting-employee',
    component: <ReportingScreen type='employee' />
  },
  {
    path: '/reporting-cost-center',
    component: <ReportingScreen type='costCenter' />
  }
];

export default routes;
