import { Field, Form, Formik } from 'formik';
import React, { memo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { companiesApi } from '../../../../store/apis/companiesApi';
import {
  useCreateJobRoleMutation,
  useUpdateJobRoleTasksMutation
} from '../../../../store/apis/jobRolesApi';
import { PresetTasksList } from '../JobRoleEditingForm/PresetTasksList';

const JobRoleCreatingForm = ( { locationId, closeModal } ) => {
  const { companyId } = useParams();

  const [ updateJobRoleTasks ] = useUpdateJobRoleTasksMutation();
  const [ createJobRole ] = useCreateJobRoleMutation();
  const [ checkedTasks, setCheckedTasks ] = useState( [] );
  const dispatch = useDispatch();

  // From Initial Values
  const initialValues = {
    name: '',
    accessDetails: '',
    contactPerson_FullName: '',
    contactPerson_Phone: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={( values, { setSubmitting } ) => {
        createJobRole( {
          locationId,
          fields: values,
        } )
          .unwrap()
          .then( response =>
            updateJobRoleTasks( {
              id: response.id,
              tasks: checkedTasks.map( task => ( {
                PresetTaskId: task.taskId,
                SkillIds: task.skillIds,
              } ) ),
            } ).unwrap()
          )
          .then( () => {
            dispatch(
              companiesApi.util.invalidateTags( [ { type: 'company', id: companyId } ] )
            );
            setSubmitting( false );
            closeModal();
          } );
      }}
    >
      {( { values, handleChange, handleSubmit, isSubmitting } ) => (
        <Form
          onSubmit={handleSubmit}
          className='form'
        >
          <div className='row'>
            <div className='col-8'>
              <div className='form-row'>
                <label htmlFor='name'>Job role name</label>
                <Field
                  className={'form-control'}
                  type='text'
                  id='name'
                  name='name'
                  value={values.name}
                  onChange={handleChange}
                />
              </div>
              <div className='form-row'>
                <label htmlFor='accessDetails'>Access details</label>
                <Field
                  className={'form-control'}
                  type='text'
                  id='accessDetails'
                  name='accessDetails'
                  value={values.accessDetails}
                  onChange={handleChange}
                />
              </div>
              <div className='form-group'>
                <div className='form-row'>
                  <label htmlFor='contactPerson_FullName'>Contact person</label>
                  <Field
                    className={'form-control'}
                    type='text'
                    id='contactPerson_FullName'
                    name='contactPerson_FullName'
                    value={values.contactPerson_FullName}
                    onChange={handleChange}
                  />
                </div>
                <div className='form-row'>
                  <label htmlFor='contactPerson_Phone'>Contact phone</label>
                  <Field
                    className={'form-control'}
                    type='tel'
                    id='contactPerson_Phone'
                    name='contactPerson_Phone'
                    value={values.contactPerson_Phone}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className='col-4'>
              <PresetTasksList
                checked={[]}
                onChange={checked => setCheckedTasks( checked )}
              />
            </div>
          </div>
          <div className='form-footer'>
            <div className='form-row mt-40 mb-10'>
              <button
                className='btn btn--green btn--block mb-2'
                type='submit'
                disabled={isSubmitting}
              >
                Save
              </button>
              <button
                type='button'
                className='btn btn--default btn--block'
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default memo( JobRoleCreatingForm );
