const validators = {
  required: value => {
    if ( value ) return undefined;
    return 'Field is required';
  },
  maxLengthCreator: maxLength => value => {
    if ( value.length > maxLength ) return `Max length is ${maxLength} symbols`;
    return undefined;
  },
  idNumber: value => {
    return /^\d{6}-[a-zA-Z]{4}$/.test( value )
      ? undefined
      : 'ID number is invalid';
  },
  phoneNumber: value => {
    return /^\+\d{3} \d{9}/.test( value ) ? undefined : 'Phone number is invalid';
  },
};

export default validators;
