import React from 'react';
import ErrorBoundary from '../../components/ErrorBoundary';
import LoginForm from './LoginForm/LoginForm';

const Login = ( { title } ) => {
  return (
    <ErrorBoundary>
      <div className='row'>
        <div className='col-12'>
          <div className='app-login__head'>
            <h1 className='title'>{title}</h1>
          </div>
        </div>
        <div className='col-12'>
          <div className='app-login__body'>
            <LoginForm />
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default Login;
