import { DateTime, Duration } from 'luxon';
import { roundHalf } from '../utils';

export const formatDateString = dateString =>
  DateTime.fromISO( dateString ).toFormat( 'd. LLLL yyyy' );

export const formatTimeString = timeString =>
  DateTime.fromISO( timeString ).toFormat( 'H:mm' );

export const formatDiff = ( startString, endString, extraMinutes ) =>
  DateTime.fromISO( endString )
    .plus( { minutes: extraMinutes || 0 } )
    .diff( DateTime.fromISO( startString ), [ 'hours', 'minutes' ] )
    .toFormat( 'hh:mm' );

export const formateDateToMessage = dateString => {
  const currentDay = DateTime.now();
  const date = DateTime.fromISO( dateString );

  if ( !date.hasSame( currentDay, 'day' ) ) {
    if ( Math.abs( date.day - currentDay.day ) > 1 ) {
      return date.toFormat( 'LLL dd' );
    } else {
      return 'yesterday';
    }
  } else {
    const minutesDiff = Math.abs( date.diff( currentDay, 'minutes' ).minutes );
    if ( minutesDiff < 1 ) return 'just now';
    if ( minutesDiff >= 60 ) {
      return date.toFormat( 'hh:mm' );
    } else {
      return `${Math.round( minutesDiff )} minute${minutesDiff > 1 ? 's' : ''} ago`;
    }
  }
};

export const calcEventsLengthSum = events => {
  return events.reduce( ( sum, event ) => {
    const start = DateTime.fromISO( event.start );
    const end = DateTime.fromISO( event.end );
    const diff = end.diff( start, [ 'hours', 'minutes' ] );
    return Duration.fromObject( {
      hours: sum.hours + diff.hours,
      minutes: sum.minutes + diff.minutes
    } );
  }, Duration.fromObject( { hours: 0, minutes: 0 } ) );
};

export const calcWeekEventsLengthSum = ( events, weekNumber ) => {
  const weekEvents = events.filter(
    event => DateTime.fromISO( event.start ).weekNumber === weekNumber
  );
  return calcEventsLengthSum( weekEvents );
};

export const isEventsIntersecting = ( a, b ) => {
  const startA = DateTime.fromISO( a.start );
  const endA = DateTime.fromISO( a.end );
  const startB = DateTime.fromISO( b.start );
  const endB = DateTime.fromISO( b.end );
  if ( startA >= endB || startB >= endA ) return false;
  return ( startA >= startB && startA <= endB ) || ( startB >= startB && startB <= endA );
};

export const eventLengthWithTimeBreak = event => {
  const start = DateTime.fromISO( event.start );
  const end = DateTime.fromISO( event.end ).plus( {
    minutes: event?.JobLog?.Employee_LunchBreak
  } );
  const diff = end.diff( start, [ 'hours' ] );
  return roundHalf( diff.hours );
};

export const eventsLengthWithTimeBreak = events => {
  return events.reduce( ( total, event ) => eventLengthWithTimeBreak( event ) + total, 0 );
};

export const timePassedString = date => {
  const dateTime = date instanceof Date ? DateTime.fromJSDate( date ) : date;
  const diff = DateTime.now().diff( dateTime, [ 'days', 'hours', 'minutes' ] );
  if ( diff.days >= 1 ) {
    const rounded = Math.floor( diff.days );
    if ( rounded === 1 ) return 'yesterday';
    return dateTime.toFormat( 'dd.LL.yyyy' );
  } else {
    if ( diff.hours > 1 ) {
      const rounded = Math.floor( diff.hours );
      const hoursString = rounded === 1 ? 'hour' : 'hours';
      return `${rounded} ${hoursString} ago`;
    }
    const rounded = Math.floor( diff.minutes );
    if ( rounded === 0 ) return 'moment ago';
    const minutesString = rounded === 1 ? 'minute' : 'minutes';
    return `${rounded} ${minutesString} ago`;
  }
};
