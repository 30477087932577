import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useGetJobEventQuery } from '../../../store/apis/eventsApi';
import { concat } from '../../../utils/utils';
import styles from '../EmployeeAssignmentScreen.module.scss';

export const OfferHoverWindow = ( { jobEventId } ) => {
  const { data: jobEvent } = useGetJobEventQuery( jobEventId );

  const timeString = useMemo( () => {
    if ( !jobEvent ) return '';
    const start = DateTime.fromISO( jobEvent.start );
    const end = DateTime.fromISO( jobEvent.end );
    const duration = Math.floor( end.diff( start, 'hours' ).hours );
    return `${start.toFormat( 'dd.LL.' )} ${start.toFormat( 'HH:mm' )}-${end.toFormat(
      'HH:mm'
    )} (${duration})`;
  }, [ jobEvent ] );

  if ( !jobEvent ) return null;

  return (
    <Link
      className={concat( styles['info-hoverbox'], styles['offer-hover'] )}
      to={`/event-listing/${jobEventId}`}
      target='_blank'
    >
      <h3>{jobEvent.JobRole.Location.name}</h3>
      <p>{jobEvent.JobRole.name}</p>
      <p>{timeString}</p>
    </Link>
  );
};
