import { DateTime } from 'luxon';
import React, { useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import styles from '../UnprocessedEvents.module.scss';

export const UnprocessedEvent = ( { event, onMouseEnter, onMouseLeave } ) => {
  const start = useMemo( () => DateTime.fromISO( event.start ), [ event ] );
  const end = useMemo( () => DateTime.fromISO( event.end ), [ event ] );

  const ref = useRef( null );

  const onElementMouseEnter = () => {
    onMouseEnter( { event, top: ref.current?.offsetTop } );
  };

  const onElementMouseLeave = () => {
    onMouseLeave();
  };

  return (
    <div
      className={styles['unprocessed-event']}
      onMouseEnter={onElementMouseEnter}
      onMouseLeave={onElementMouseLeave}
      ref={ref}
    >
      <Link to={`/event-listing/${event.id}`}>
        <div className='d-flex justify-content-between align-items-center'>
          <span className={styles['job-row']}>
            <span className={styles['job-title']}>{event.JobRole.Location.name}</span>
          </span>
        </div>
        <div className={styles['job-desc']}>
          {start.toFormat( 'dd.LL.' )} | {start.toFormat( 'HH:mm' )} - {end.toFormat( 'H:mm' )}
        </div>
        <div className={styles['job-role']}>{event.JobRole.name}</div>
      </Link>
    </div>
  );
};
