import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useCallback, useMemo, useState } from 'react';
import ReactModal from 'react-modal';
import { debounce } from 'throttle-debounce';
import { ReactComponent as LockIcon } from '../../../assets/images/lock-icon.svg';
import { Button } from '../../../components/Button';
import { Spinner } from '../../../components/Spinner';
import {
  useChangeConversationCustomerUsersMutation,
  useChangeConversationEmpoloyeesMutation,
  useGetSingleConversationsQuery
} from '../../../store/apis/chatApi';
import { useGetCustomerUsersQuery } from '../../../store/apis/customerUsersApi';
import { useGetAllEmployeesQuery } from '../../../store/apis/employeesApi';
import { useGetJoblinkUsersQuery } from '../../../store/apis/joblinkUsersApi';
import { JoblinkUser, Member, Search } from './components';
import './EditMembersModal.sass';

export const EditMembersModal = ( { isOpen, close, conversationId } ) => {
  const { data: conversation, isLoading: isConversationLoading } =
    useGetSingleConversationsQuery( conversationId || skipToken );
  const [ changeEmployees ] = useChangeConversationEmpoloyeesMutation();
  const { data: joblinkUsers, isLoading: isUsersLoading } = useGetJoblinkUsersQuery();
  const { data: employees, isLoading: isEmployeesLoading } = useGetAllEmployeesQuery(
    conversation?.direction === 'Employee' ? undefined : skipToken
  );
  const { data: customerUsers, isLoading: isCustomerUsersLoading } =
    useGetCustomerUsersQuery(
      conversation?.direction === 'CustomerUser' ? undefined : skipToken
    );
  const [ changeCustomerUsers ] = useChangeConversationCustomerUsersMutation();

  const [ removedMembers, setRemovedMembers ] = useState( [] );
  const [ addedMembers, setAddedMembers ] = useState( [] );
  const [ searchTerm, setSearchTerm ] = useState( '' );

  const staff = useMemo( () => {
    if ( !joblinkUsers || !conversation ) return [];
    return joblinkUsers.filter( user =>
      user.JoblinkGroupIds.includes( conversation.JoblinkGroupId )
    );
  }, [ joblinkUsers, conversation ] );

  const members = useMemo( () => {
    if ( !conversation ) return [];
    const users =
      conversation?.direction === 'Employee'
        ? conversation.Employees
        : conversation.CustomerUsers;
    const removedIds = removedMembers.map( employee => employee.id );
    return users.filter( user => !removedIds.includes( user.id ) );
  }, [ joblinkUsers, removedMembers, conversation ] );

  const suitableUsers = useMemo( () => {
    if ( !conversation ) return [];
    const users = conversation?.direction === 'Employee' ? employees : customerUsers;
    if ( !searchTerm || !users ) {
      return [];
    }
    const membersIds = members.map( member => member.id );
    return users.filter(
      user =>
        [ user.firstName, user.lastName ]
          .join()
          .toLowerCase()
          .includes( searchTerm.toLowerCase() ) && !membersIds.includes( user.id )
    );
  }, [ employees, customerUsers, conversation, searchTerm, members ] );

  const membersCandidates = useMemo( () => {
    const addedIds = addedMembers.map( member => member.id );
    return [
      ...removedMembers,
      ...suitableUsers.filter( employee => !addedIds.includes( employee.id ) ),
    ];
  }, [ removedMembers, suitableUsers, addedMembers ] );

  const visibleMembers = useMemo(
    () =>
      [ ...members, ...addedMembers ].filter( employee =>
        [ employee.firstName, employee.lastName ]
          .join()
          .toLowerCase()
          .includes( searchTerm.toLowerCase() )
      ),
    [ members, addedMembers, searchTerm ]
  );

  const onRemove = employee => {
    if ( addedMembers.find( member => member.id === employee.id ) ) {
      setAddedMembers( addedMembers.filter( member => member.id !== employee.id ) );
    } else if ( !removedMembers.find( member => member.id === employee.id ) ) {
      setRemovedMembers( removedMembers.concat( employee ) );
    }
  };

  const onAdd = employee => {
    if ( !addedMembers.find( member => member.id === employee.id ) ) {
      if ( removedMembers.find( member => member.id === employee.id ) ) {
        setRemovedMembers( removedMembers.filter( member => member.id !== employee.id ) );
      } else {
        setAddedMembers( addedMembers.concat( employee ) );
      }
    }
  };

  const updateSearch = useCallback( debounce( 300, setSearchTerm ), [ searchTerm ] );

  const onSave = () => {
    const changeMembers =
      conversation?.direction === 'Employee' ? changeEmployees : changeCustomerUsers;
    changeMembers( {
      id: conversationId,
      members: [ ...members, ...addedMembers ].map( member => member.id ),
    } )
      .unwrap()
      .then( close );
  };

  const isLoading =
    isUsersLoading ||
    isConversationLoading ||
    isEmployeesLoading ||
    isCustomerUsersLoading;

  return (
    <ReactModal
      isOpen={isOpen}
      ariaHideApp={false}
      style={{ content: { maxWidth: 900 } }}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <div className='edit-members'>
          <span
            onClick={close}
            className='icon-close'
          />
          <div className='modal-head d-flex justify-content-between'>
            <div className='modal-title'>Edit participants</div>
          </div>
          <div className='modal-body'>
            <div className='white-shadow-box edit-members-section'>
              <div className='edit-members-title'>
                <h3>
                  Joblink staff <span>{staff.length}</span>
                </h3>
                <LockIcon />
              </div>
              <div className='edit-members-list'>
                {staff.map( ( user, index ) => (
                  <JoblinkUser
                    user={user}
                    key={index}
                  />
                ) )}
              </div>
            </div>

            <div className='white-shadow-box edit-members-section'>
              <div className='edit-members-title'>
                <h3>
                  {conversation?.direction === 'Employee' ? 'Employees' : 'Company Users'}{' '}
                  <span>{members.length}</span>
                </h3>
              </div>
              <Search
                value={searchTerm}
                onChange={updateSearch}
              />
              <div className='edit-members-list'>
                {visibleMembers.map( ( employee, index ) => (
                  <Member
                    key={index}
                    employee={employee}
                    onRemove={onRemove}
                  />
                ) )}
              </div>
              {!!membersCandidates.length && (
                <>
                  <h4>Not in this conversation</h4>
                  <div className='edit-members-list'>
                    {membersCandidates.map( ( employee, index ) => (
                      <Member
                        key={index}
                        employee={employee}
                        onAdd={onAdd}
                      />
                    ) )}
                  </div>
                </>
              )}
            </div>

            <div className='d-flex justify-content-center edit-members-buttons'>
              <Button
                type='pale-green'
                onClick={close}
              >
                Cancel
              </Button>
              <Button onClick={onSave}>Save</Button>
            </div>
          </div>
        </div>
      )}
    </ReactModal>
  );
};
