import React, { memo, useEffect, useState } from 'react';
import { useGetCostCentersQuery } from '../../store/apis/costCentersApi';
import ErrorBoundary from '../ErrorBoundary';
import { Spinner } from '../Spinner';
import JobRoleRow from './JobRoleRow';
import classes from './LocationsTable.module.scss';

const LocationsTable = ( {
  data,
  onCreate = value => console.log( value ),
  onEditJobLocation = ( id, value ) => console.log( value ),
  onEditJobRole = ( id, value ) => console.log( value ),
} ) => {
  const { data: costCenters, isLoading } = useGetCostCentersQuery();

  // States
  const [ isOpenCostCenter, setIsOpenCostCenter ] = useState( true );
  const [ state, setState ] = useState( data );
  const [ currentCostCenterName, setCurrentCostCenterName ] = useState( '' );

  useEffect( () => {
    const costCenterName = costCenters?.filter(
      costCenter => costCenter.id === data.CostCenterId
    )?.[0]?.name;
    setCurrentCostCenterName( costCenterName );
  }, [ costCenters, data.CostCenterId ] );

  // Side Effects
  useEffect( () => {
    setState( data );
  }, [ data ] );

  return (
    <ErrorBoundary>
      <>
        <div className={classes.crudTableBox}>
          <div className={classes.row}>
            <div className={classes.crudTableTitle}>{state?.name}</div>
            <div style={{ display: 'flex' }}>
              <div
                className={`${!isOpenCostCenter && 'reverse'} mr-3 ${
                  classes.costCenterControl
                }`}
                onClick={() => setIsOpenCostCenter( !isOpenCostCenter )}
              >
                <svg
                  width='11'
                  height='7'
                  viewBox='0 0 11 7'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M9.5 1.33301L5.5 5.33301L1.5 1.33301L9.5 1.33301Z'
                    fill='#A1A1AA'
                    stroke='#A1A1AA'
                    strokeWidth='2'
                    strokeLinejoin='round'
                  />
                </svg>
              </div>
              <span
                className={classes.editButton}
                onClick={() => onEditJobLocation( state )}
              >
                Edit
              </span>
            </div>
          </div>
          <div className={classes.row}>
            <span className={classes.value}>{currentCostCenterName}</span>
          </div>
        </div>
        <ul className={classes.crudTableList}>
          {isLoading && (
            <li className={`${classes.crudTableBox} bg-white`}>
              <Spinner />
            </li>
          )}
          {!isLoading &&
            isOpenCostCenter &&
            state?.JobRoles?.map( el => (
              <JobRoleRow
                key={el.id}
                data={el}
                onEdit={onEditJobRole}
              />
            ) )}
          {!isLoading && isOpenCostCenter && !state.JobRoles.length && (
            <div className={`${classes.noResult} bg-warning`}>
              Sorry, no results were found.
            </div>
          )}
          {!isLoading && (
            <li
              className={`${classes.crudTableBox} bg-light-green color-green`}
              onClick={() => onCreate( state?.id )}
            >
              <span className={classes.add}>Add new role</span>
            </li>
          )}
        </ul>
      </>
    </ErrorBoundary>
  );
};

export default memo( LocationsTable );
