import { DateTime } from 'luxon';
import { memo, useMemo } from 'react';
import { useGetJobEventsPolling } from '../../hooks';

const OperationsElementHeaderDayComponent = ( { day, locations } ) => {
  const events = useGetJobEventsPolling();

  const jobRolesIds = useMemo( () => {
    if ( !locations ) return [];
    return locations.map( location => location.JobRoles.map( jobRole => jobRole.id ) ).flat();
  }, [ locations ] );

  const jobCount = useMemo( () => {
    if ( day && jobRolesIds && events.data ) {
      return events.data.filter(
        event =>
          DateTime.fromISO( event.start ).hasSame( day, 'day' ) &&
          jobRolesIds.includes( event.JobRoleId )
      ).length;
    }
    return 0;
  }, [ day, events.data, jobRolesIds ] );

  return (
    <div
      className={[ 'day', day.hasSame( DateTime.now(), 'day' ) ? 'today' : undefined ].join(
        ' '
      )}
    >
      <span>
        <b>{day.toFormat( 'ccc' )}</b>
      </span>
      <span>{day.toFormat( 'd.L.' )}</span>
      <span className='count'>( {jobCount} )</span>
    </div>
  );
};

export const OperationsElementHeaderDay = memo(
  OperationsElementHeaderDayComponent,
  isEqual
);

function isEqual( prev, next ) {
  const sameDay = prev.day.hasSame( next.day, 'day' );
  if ( !sameDay ) return false;
  const prevLocationIds = prev.locations.map( loc => loc.id );
  const nextLocationIds = next.locations.map( loc => loc.id );
  if ( prevLocationIds.length !== nextLocationIds.length ) return false;
  const sameLocations =
    prevLocationIds.length === nextLocationIds.length &&
    !nextLocationIds.find( id => !prevLocationIds.includes( id ) );
  return sameLocations;
}
