import { DateTime } from 'luxon';
import { eventsLengthWithTimeBreak } from '../../../utils/dates';
import {
  concat,
  groupEventsByCompany,
  groupEventsByDate,
  groupEventsByEmployee,
  groupEventsByJobRole,
  groupEventsByLocation,
  groupEventsByWeekday
} from '../../../utils/utils';
import styles from '../ReportingScreen.module.sass';

export const customersView = ( eventsByCompany, locations ) => {
  let grandTotalHours = 0;
  let grandTotalJobs = 0;
  for ( const companyItem of eventsByCompany ) {
    const companyJobRoles = locations
      .filter( location => location.CompanyAccountId === companyItem.company.id )
      .flatMap( location => location.JobRoles );
    let totalHours = 0;
    const totalJobs = companyItem.events.length;
    const byJobRole = groupEventsByJobRole( companyItem.events ).map(
      ( { jobRoleId, events } ) => {
        const length = eventsLengthWithTimeBreak( events );
        totalHours += length;
        return {
          jobRole: companyJobRoles.find( jobRole => jobRole.id === jobRoleId ),
          events,
          totalHours: length,
          totalJobs: events.length
        };
      }
    );
    companyItem.byJobRole = byJobRole;
    companyItem.totalHours = totalHours;
    companyItem.totalJobs = totalJobs;
    grandTotalHours += totalHours;
    grandTotalJobs += totalJobs;
  }
  return [
    ...eventsByCompany.flatMap( companyItem => [
      [
        { value: companyItem.company.name, className: styles['bold'] },
        { value: '' },
        {
          value: companyItem.totalHours,
          className: concat( styles['bold'], styles['center'] )
        },
        {
          value: companyItem.totalJobs,
          className: concat( styles['bold'], styles['center'] )
        }
      ],
      ...companyItem.byJobRole.map( jobRoleItem => [
        { value: '' },
        { value: jobRoleItem.jobRole.name },
        { value: jobRoleItem.totalHours, className: styles['center'] },
        { value: jobRoleItem.totalJobs, className: styles['center'] }
      ] )
    ] ),
    [
      { value: 'Grand total:', className: styles['bold'] },
      { value: '' },
      { value: grandTotalHours, className: concat( styles['bold'], styles['center'] ) },
      { value: grandTotalJobs, className: concat( styles['bold'], styles['center'] ) }
    ]
  ];
};

export const locationsView = ( eventsByCompany, locations ) => {
  let grandTotalHours = 0;
  let grandTotalJobs = 0;
  for ( const companyItem of eventsByCompany ) {
    const companyJobRoles = locations
      .filter( location => location.CompanyAccountId === companyItem.company.id )
      .flatMap( location => location.JobRoles );
    const byLocation = groupEventsByLocation( companyItem.events ).map(
      ( { locationId, events } ) => {
        const length = eventsLengthWithTimeBreak( events );
        return {
          location: locations.find( location => location.id === locationId ),
          events,
          totalHours: length,
          totalJobs: events.length,
          byJobRole: []
        };
      }
    );
    for ( const locationItem of byLocation ) {
      const byJobRole = groupEventsByJobRole( locationItem.events ).map(
        ( { jobRoleId, events } ) => {
          const length = eventsLengthWithTimeBreak( events );
          return {
            jobRole: companyJobRoles.find( jobRole => jobRole.id === jobRoleId ),
            events,
            totalHours: length,
            totalJobs: events.length
          };
        }
      );
      locationItem.byJobRole = byJobRole;
      companyItem.totalHours += locationItem.totalHours;
      companyItem.totalJobs += locationItem.totalJobs;
    }
    companyItem.byLocation = byLocation;
    grandTotalHours += companyItem.totalHours;
    grandTotalJobs += companyItem.totalJobs;
  }

  return [
    ...eventsByCompany.flatMap( companyItem => [
      [
        { value: companyItem.company.name, className: styles['bold'] },
        { value: '' },
        {
          value: companyItem.totalHours,
          className: concat( styles['bold'], styles['center'] )
        },
        {
          value: companyItem.totalJobs,
          className: concat( styles['bold'], styles['center'] )
        }
      ],
      ...companyItem.byLocation.flatMap( locationItem => [
        [
          { value: locationItem.location.name, className: styles['location'] },
          { value: '' },
          {
            value: locationItem.totalHours,
            className: concat( styles['semi-bold'], styles['center'] )
          },
          {
            value: locationItem.totalJobs,
            className: concat( styles['semi-bold'], styles['center'] )
          }
        ],
        ...locationItem.byJobRole.map( jobRoleItem => [
          { value: '' },
          { value: jobRoleItem.jobRole.name },
          { value: jobRoleItem.totalHours, className: styles['center'] },
          { value: jobRoleItem.totalJobs, className: styles['center'] }
        ] )
      ] )
    ] ),
    [
      { value: 'Grand total:', className: styles['bold'] },
      { value: '' },
      { value: grandTotalHours, className: concat( styles['bold'], styles['center'] ) },
      { value: grandTotalJobs, className: concat( styles['bold'], styles['center'] ) }
    ]
  ];
};

export const datesView = ( events, companies, locations ) => {
  const jobRoles = locations.flatMap( location => location.JobRoles );
  let grandTotalHours = 0;
  let grandTotalJobs = 0;
  const byDate = groupEventsByDate( events )
    .map( ( { date, events } ) => {
      let dateHours = 0;
      let dateJobs = events.length;
      const byCompany = groupEventsByCompany( events ).map( ( { companyId, events } ) => {
        let companyHours = 0;
        const byJobRole = groupEventsByJobRole( events ).map( ( { jobRoleId, events } ) => {
          const jobRoleHours = eventsLengthWithTimeBreak( events );
          companyHours += jobRoleHours;
          return {
            jobRole: jobRoles.find( jobRole => jobRole.id === jobRoleId ),
            events,
            totalHours: jobRoleHours,
            totalJobs: events.length
          };
        } );

        dateHours += companyHours;

        return {
          company: companies.find( company => company.id === companyId ),
          events,
          totalHours: companyHours,
          totalJobs: events.length,
          byJobRole
        };
      } );
      grandTotalHours += dateHours;
      grandTotalJobs += dateJobs;
      return {
        date,
        events,
        totalHours: dateHours,
        totalJobs: dateJobs,
        byCompany
      };
    } )
    .sort( ( a, b ) => ( new Date( a.date ) > new Date( b.date ) ? 1 : -1 ) );
  return [
    ...byDate.flatMap( dateItem => [
      [
        {
          value: DateTime.fromISO( dateItem.date ).toFormat( 'd.LL.' ),
          className: styles['bold']
        },
        { value: '' },
        {
          value: dateItem.totalHours,
          className: concat( styles['bold'], styles['center'] )
        },
        {
          value: dateItem.totalJobs,
          className: concat( styles['bold'], styles['center'] )
        }
      ],
      ...dateItem.byCompany.flatMap( companyItem => [
        [
          {
            value: companyItem.company.name,
            className: concat( styles['bold'], styles['location'] )
          },
          { value: '' },
          {
            value: companyItem.totalHours,
            className: concat( styles['bold'], styles['center'] )
          },
          {
            value: companyItem.totalJobs,
            className: concat( styles['bold'], styles['center'] )
          }
        ],
        ...companyItem.byJobRole.map( jobRoleItem => [
          { value: '' },
          { value: jobRoleItem.jobRole.name },
          { value: jobRoleItem.totalHours, className: styles['center'] },
          { value: jobRoleItem.totalJobs, className: styles['center'] }
        ] )
      ] )
    ] ),
    [
      { value: 'Grand total:', className: styles['bold'] },
      { value: '' },
      { value: grandTotalHours, className: concat( styles['bold'], styles['center'] ) },
      { value: grandTotalJobs, className: concat( styles['bold'], styles['center'] ) }
    ]
  ];
};

export const employeesView = ( events, employees, locations ) => {
  const jobRoles = locations.flatMap( location => location.JobRoles );
  let grandTotalHours = 0;
  let grandTotalJobs = 0;
  const byEmployee = groupEventsByEmployee( events ).map( ( { employeeId, events } ) => {
    let employeeHours = 0;

    const byJobRole = groupEventsByJobRole( events ).map( ( { jobRoleId, events } ) => {
      const jobRoleHours = eventsLengthWithTimeBreak( events );
      employeeHours += jobRoleHours;
      return {
        jobRole: jobRoles.find( jobRole => jobRole.id === jobRoleId ),
        events,
        totalHours: jobRoleHours,
        totalJobs: events.length
      };
    } );

    grandTotalHours += employeeHours;
    grandTotalJobs += events.length;

    return {
      employee: employees.find( employee => employee.id === employeeId ),
      events,
      totalHours: employeeHours,
      totalJobs: events.length,
      byJobRole
    };
  } );

  return [
    ...byEmployee.flatMap( employeeItem => [
      [
        {
          value: `${employeeItem.employee.firstName} ${employeeItem.employee.lastName}`,
          className: styles['bold']
        },
        { value: '' },
        {
          value: employeeItem.totalHours,
          className: concat( styles['bold'], styles['center'] )
        },
        {
          value: employeeItem.totalJobs,
          className: concat( styles['bold'], styles['center'] )
        }
      ],
      ...employeeItem.byJobRole.map( jobRoleItem => [
        { value: '' },
        { value: jobRoleItem.jobRole.name },
        { value: jobRoleItem.totalHours, className: styles['center'] },
        { value: jobRoleItem.totalJobs, className: styles['center'] }
      ] )
    ] ),
    [
      { value: 'Grand total:', className: styles['bold'] },
      { value: '' },
      { value: grandTotalHours, className: concat( styles['bold'], styles['center'] ) },
      { value: grandTotalJobs, className: concat( styles['bold'], styles['center'] ) }
    ]
  ];
};

export const weekdaysView = ( events, companies, locations ) => {
  const jobRoles = locations.flatMap( location => location.JobRoles );
  let grandTotalHours = 0;
  let grandTotalJobs = 0;
  const byWeekday = groupEventsByWeekday( events )
    .sort( ( a, b ) => ( a.weekday.num > b.weekday.num ? 1 : -1 ) )
    .map( ( { weekday, events } ) => {
      let weekdayHours = 0;

      const byCompany = groupEventsByCompany( events ).map( ( { companyId, events } ) => {
        let companyHours = 0;
        const byJobRole = groupEventsByJobRole( events ).map( ( { jobRoleId, events } ) => {
          const jobRoleHours = eventsLengthWithTimeBreak( events );
          companyHours += jobRoleHours;
          return {
            jobRole: jobRoles.find( jobRole => jobRole.id === jobRoleId ),
            events,
            totalHours: jobRoleHours,
            totalJobs: events.length
          };
        } );

        weekdayHours += companyHours;

        return {
          company: companies.find( company => company.id === companyId ),
          events,
          totalHours: companyHours,
          totalJobs: events.length,
          byJobRole
        };
      } );
      grandTotalHours += weekdayHours;
      grandTotalJobs += events.length;
      return {
        weekday,
        events,
        totalHours: weekdayHours,
        totalJobs: events.length,
        byCompany
      };
    } );

  return [
    ...byWeekday.flatMap( weekdayItem => [
      [
        {
          value: weekdayItem.weekday.name,
          className: styles['bold']
        },
        { value: '' },
        {
          value: weekdayItem.totalHours,
          className: concat( styles['bold'], styles['center'] )
        },
        {
          value: weekdayItem.totalJobs,
          className: concat( styles['bold'], styles['center'] )
        }
      ],
      ...weekdayItem.byCompany.flatMap( companyItem => [
        [
          {
            value: companyItem.company.name,
            className: concat( styles['bold'], styles['location'] )
          },
          { value: '' },
          {
            value: companyItem.totalHours,
            className: concat( styles['bold'], styles['center'] )
          },
          {
            value: companyItem.totalJobs,
            className: concat( styles['bold'], styles['center'] )
          }
        ],
        ...companyItem.byJobRole.map( jobRoleItem => [
          { value: '' },
          { value: jobRoleItem.jobRole.name },
          { value: jobRoleItem.totalHours, className: styles['center'] },
          { value: jobRoleItem.totalJobs, className: styles['center'] }
        ] )
      ] )
    ] ),
    [
      { value: 'Grand total:', className: styles['bold'] },
      { value: '' },
      { value: grandTotalHours, className: concat( styles['bold'], styles['center'] ) },
      { value: grandTotalJobs, className: concat( styles['bold'], styles['center'] ) }
    ]
  ];
};
