import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetMatchingEmployeesQuery } from '../../store/apis/employeesApi';

export const useGetMatchingEmployees = ( jobEvent, availability, costCenter ) => {
  const { data: employees } = useGetMatchingEmployeesQuery(
    jobEvent
      ? {
          jobEvent: jobEvent,
          availability: availability === 'all' ? undefined : availability,
          costCenterIds: costCenter === 'all' ? undefined : [ costCenter ]
        }
      : skipToken
  );

  return employees || [];
};
