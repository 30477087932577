export const sortEventsByEmployeeName = ( events, order ) =>
  [ ...events ].sort( ( a, b ) => {
    const aName = a.JobOffer.AcceptedEmployee.firstName.concat(
      a.JobOffer.AcceptedEmployee.lastName
    );
    const bName = b.JobOffer.AcceptedEmployee.firstName.concat(
      b.JobOffer.AcceptedEmployee.lastName
    );

    const sortOrderCoefficient = order === 'asc' ? 1 : -1;

    console.log( 'sortOrderCoefficient ', ( aName > bName ? 1 : -1 ) * sortOrderCoefficient );
    return ( aName > bName ? 1 : -1 ) * sortOrderCoefficient;
  } );

export const eventsSortingPredicates = {
  byDate: order => ( a, b ) => {
    const aDate = new Date( a.start );
    const bDate = new Date( b.start );
    if ( aDate === bDate ) return 0;
    const sortOrderCoefficient = order === 'desc' ? -1 : 1;
    return ( aDate > bDate ? 1 : -1 ) * sortOrderCoefficient;
  },
  byJobRole: order => ( a, b ) => {
    const aName = a.JobRole.name;
    const bName = b.JobRole.name;
    if ( aName === bName ) return 0;
    const sortOrderCoefficient = order === 'asc' ? 1 : -1;
    return ( aName > bName ? 1 : -1 ) * sortOrderCoefficient;
  },
  byEnd: order => ( a, b ) => {
    const aDate = new Date( a.end );
    const bDate = new Date( b.end );
    if ( aDate === bDate ) return 0;
    const sortOrderCoefficient = order === 'desc' ? -1 : 1;
    return ( aDate > bDate ? 1 : -1 ) * sortOrderCoefficient;
  }
};

export const sortEventsByStartTime = ( events, order ) =>
  [ ...events ].sort( eventsSortingPredicates.byDate( order ) );

export const sortEventsByLocationName = ( events, order ) =>
  [ ...events ].sort( ( a, b ) => {
    const aLocationName = a.JobRole.Location.name;
    const bLocationName = b.JobRole.Location.name;
    const sortOrderCoefficient = order === 'asc' ? 1 : -1;
    return ( aLocationName > bLocationName ? 1 : -1 ) * sortOrderCoefficient;
  } );

export const sortEventsByEndTime = ( events, order ) =>
  [ ...events ].sort( eventsSortingPredicates.byEnd( order ) );

export const sortEventsByJobRole = ( events, order ) =>
  [ ...events ].sort( eventsSortingPredicates.byJobRole( order ) );

export const eventsSortingsMap = {
  employee: sortEventsByEmployeeName,
  startTime: sortEventsByStartTime,
  location: sortEventsByLocationName,
  jobRole: sortEventsByJobRole
};

export const combineSortingPredicates =
  ( ...predicates ) =>
  ( a, b ) => {
    for ( const predicate of predicates ) {
      const predicateResult = predicate( a, b );
      if ( predicateResult ) return predicateResult;
    }
    return 0;
  };
