import { forwardRef } from 'react';
import { RiCalendar2Line } from 'react-icons/ri';

export const CalendarNavigationButton = forwardRef( ( { onClick }, ref ) => (
  <span
    onClick={onClick}
    ref={ref}
  >
    <RiCalendar2Line />
  </span>
) );
