import React, { memo } from 'react';
import { useParams } from 'react-router-dom';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import LinkBack from '../../../../components/LinkBack';
import { Spinner } from '../../../../components/Spinner';
import { useGetJoblinkUserQuery } from '../../../../store/apis/joblinkUsersApi';
import EditAccountInfoForm from './EditAccountInfoForm';

const EditAccountInfo = () => {
  const { id } = useParams();

  const { data: user, isLoading } = useGetJoblinkUserQuery( id );

  if ( isLoading || !user ) return <Spinner />;

  return (
    <ErrorBoundary>
      <div className='app-content'>
        <div className='container-fluid'>
          {!isLoading && user && (
            <>
              <div className='row'>
                <div className='col-12'>
                  <div>
                    <LinkBack title='Back to Joblink Users' />
                  </div>
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-12'>
                  <div className='d-flex justify-content-between'>
                    <div className='title-box mt-4 mt-md-3'>
                      <h1 className='title error-title mb-0'>
                        {user?.firstName} {user?.lastName}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <EditAccountInfoForm />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default memo( EditAccountInfo );
