import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import EditCompany from './EditCompanyInfo';

const mapStateToProps = state => ( {
  alert: state.app.alert,
} );

const EditCompanyInfoContainer = connect( mapStateToProps )( withRouter( EditCompany ) );

export default EditCompanyInfoContainer;
