import { Field } from 'formik';

export const DescriptionField = () => {
  return (
    <div className='form-row w-100'>
      <label htmlFor='description'>Description</label>
      <Field
        as='textarea'
        id='description'
        name='description'
        className='form-control h-350'
        placeholder='Type message here...'
      />
    </div>
  );
};
