import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { render } from 'react-dom';
import JoblinkJSApp from './App';
import './app.css';
import './assets/css/bootstrap-grid.min.css';
import './assets/css/font.css';
import './assets/css/normalize.css';

render( <JoblinkJSApp />, document.getElementById( 'root' ) );
