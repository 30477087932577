import { skipToken } from '@reduxjs/toolkit/dist/query';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { useResolveEventMutation } from '../../../store/apis/employeesApi';
import { useGetJobEventQuery } from '../../../store/apis/eventsApi';
import { useGetJobRoleQuery } from '../../../store/apis/jobRolesApi';
import { useGetPresetTasksQuery } from '../../../store/apis/presetTasksApi';
import './UnresolvedEvent.sass';

export const UnresolvedEvent = ( { eventId, token, onResolve } ) => {
  console.log( { eventId } );
  const { data: jobEvent } = useGetJobEventQuery( eventId );
  const { data: tasks } = useGetPresetTasksQuery();
  const { data: jobRole } = useGetJobRoleQuery( jobEvent?.JobRoleId || skipToken );

  const [ resolveEvent ] = useResolveEventMutation();

  const [ resolved, setResolved ] = useState( false );

  const task = useMemo( () => {
    if ( !tasks || !jobEvent ) return;
    return tasks.find( t => t.id === jobEvent.TaskId );
  }, [ tasks, jobEvent ] );

  const resolve = reopen => {
    resolveEvent( {
      eventId,
      token,
      reopen,
    } ).then( () => {
      setResolved( true );
      onResolve( eventId );
    } );
  };

  const onReopen = () => resolve( true );

  const onCancel = () => resolve( false );

  return (
    <div className='unresolved-event'>
      <div className='info'>
        <div>
          <h3>
            {jobRole?.Location?.name}, {jobRole?.name}, {task?.name}
          </h3>
          <p>
            {!!jobEvent &&
              DateTime.fromISO( jobEvent.start ).toFormat( 'hh:mm, d. LLL yyyy' )}
            {' - '}
            {!!jobEvent && DateTime.fromISO( jobEvent.end ).toFormat( 'hh:mm, d. LLL yyyy' )}
          </p>
        </div>
        <div></div>
      </div>
      <div className='actions'>
        {resolved ? (
          <span
            className='color-green'
            style={{ padding: 12 }}
          >
            Resolved
          </span>
        ) : (
          <>
            <button
              className='btn btn--light-green'
              onClick={onReopen}
            >
              Reopen
            </button>
            <button
              className='btn btn--light-danger'
              onClick={onCancel}
            >
              Close
            </button>
          </>
        )}
      </div>
    </div>
  );
};
