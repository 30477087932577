import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { calcWeekEventsLengthSum } from '../../../../utils/dates/dates';
import { concat } from '../../../../utils/utils';
import styles from '../../OperationsScreen.module.sass';
import { EventsCountBadge } from '../badges';

export const JobRoleRow = ( { jobRole, location } ) => {
  const { currentWeekStart, weeksCount, allEvents, eventsByDate } = useSelector(
    state => state.operationsPage
  );

  const totalPerformedTime = useMemo( () => {
    const acceptedJobEvents = allEvents.filter(
      event => event.JobRoleId === jobRole.id && !!event.JobOffer?.AcceptedEmployee
    );
    return Array( weeksCount )
      .fill()
      .map( ( _, weekIndex ) =>
        calcWeekEventsLengthSum(
          acceptedJobEvents,
          currentWeekStart.weekNumber + weekIndex
        ).toFormat( 'hh:mm' )
      );
  }, [ allEvents, jobRole, currentWeekStart ] );

  const tiles = useMemo( () => {
    return Array( weeksCount )
      .fill()
      .map( ( _, weekIndex ) => [
        ...Array( 7 )
          .fill()
          .map( ( _, index ) => ( {
            content: ( () => {
              const date = currentWeekStart.plus( { days: index, weeks: weekIndex } );

              const events =
                eventsByDate[date.toISODate()]?.filter( event => {
                  const isJobRoleCorrect = event.JobRoleId === jobRole.id;
                  const isStatusCorrect = [ 'open', 'unprocessed' ].includes( event.status );

                  return isJobRoleCorrect && isStatusCorrect;
                } ) || [];

              return (
                <EventsCountBadge
                  events={events}
                  date={date}
                  jobRole={jobRole}
                  location={location}
                />
              );
            } )()
          } ) ),
        {
          content: totalPerformedTime[weekIndex],
          className: styles['tile-total']
        }
      ] );
  }, [ weeksCount, eventsByDate, jobRole, currentWeekStart ] );

  return (
    <>
      <div className={concat( styles['tile'], styles['tile-big'], styles['location'] )}>
        {location.name} - {jobRole.name}
      </div>
      {tiles.map( week =>
        week.map( ( day, index ) => (
          <div
            className={concat( styles['tile'], styles['location'], day?.className )}
            key={index}
          >
            {day?.content}
          </div>
        ) )
      )}
    </>
  );
};
