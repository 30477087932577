import React, { memo, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import ErrorBoundary from '../../../components/ErrorBoundary';
import LinkBack from '../../../components/LinkBack';
import { useGetAllEmployeesQuery } from '../../../store/apis/employeesApi';
import EditPersonalInfoForm from './EditPersonalInfoForm';

const ProfileEditPersonalInfo = () => {
  const { employeeId } = useParams();

  const { data: employees } = useGetAllEmployeesQuery();

  const employee = useMemo( () => {
    return employees?.find( item => item.id === +employeeId );
  }, [ employees, employeeId ] );

  console.log( { employee } );

  return (
    <ErrorBoundary>
      <div className='app-content'>
        <div className='container-fluid'>
          {employee && (
            <>
              <div className='row'>
                <div className='col-12'>
                  <div>
                    <LinkBack title='Back to Employees' />
                  </div>
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-12'>
                  <div className='d-flex justify-content-between'>
                    <div className='title-box mt-4 mt-md-3'>
                      <h1 className='title error-title mb-0'>
                        {employee?.firstName} {employee?.lastName}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <EditPersonalInfoForm
                    data={employee}
                    accountId={1}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default memo( ProfileEditPersonalInfo );
