import { mainApi } from './mainApi';

export const invitationApi = mainApi.injectEndpoints( {
  endpoints: build => ( {
    inviteJoblink: build.mutation( {
      query: inviteData => ( {
        url: '/invitation/joblinkUser',
        method: 'POST',
        body: inviteData,
        headers: {
          frontendurl: `${window.location.origin}/register?token=`
        }
      } ),
      invalidatesTags: ( res, err ) => ( err ? [] : [ 'inviteJU' ] )
    } ),
    inviteEmployee: build.mutation( {
      query: inviteData => ( {
        url: '/invitation/employee',
        method: 'POST',
        body: inviteData
      } ),
      invalidatesTags: ( res, err ) => ( err ? [] : [ 'inviteEmployee' ] )
    } ),
    getInvitedCustomerUsers: build.query( {
      query: () => ( {
        url: '/invitation/customerUsers'
      } ),
      providesTags: ( res, err ) => ( err ? [] : [] )
    } ),
    getInvitedJoblinkUsers: build.query( {
      query: () => ( {
        url: '/invitation/joblinkUsers'
      } ),
      providesTags: ( res, err ) => ( err ? [] : [ 'inviteJU' ] )
    } ),
    inviteCustomerUser: build.mutation( {
      query: fields => ( {
        url: '/invitation/customerUser',
        method: 'post',
        body: fields,
        headers: {
          frontendurl: 'http://joblinkcustomer.dodeveloper.com/register?token='
        }
      } ),
      invalidatesTags: ( res, err ) => ( err ? [] : [ 'invitedCustomerUsers' ] )
    } )
  } )
} );

export const {
  useInviteJoblinkMutation,
  useInviteEmployeeMutation,
  useGetInvitedCustomerUsersQuery,
  useGetInvitedJoblinkUsersQuery,
  useInviteCustomerUserMutation
} = invitationApi;
