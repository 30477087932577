import { Field, Form, Formik } from 'formik';
import React from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import {
  useAbortOfferMutation,
  useCancelOfferMutation,
  useCancelOrderMutation
} from '../../../../store/apis/eventsApi';

const CancelOrAbortForm = ( { closeModal, modalFormat, phase } ) => {
  const { orderId } = useParams();

  const [ cancelOffer ] = useCancelOfferMutation();
  const [ cancelOrder ] = useCancelOrderMutation();
  const [ abortOffer ] = useAbortOfferMutation();

  return (
    <Formik
      initialValues={{ comment: '' }}
      onSubmit={( { comment }, { setSubmitting } ) => {
        if ( modalFormat === 'cancel' ) {
          if ( phase === 'order' ) {
            cancelOrder( orderId );
          } else {
            cancelOffer( { eventId: orderId, comment } );
          }
        }
        if ( modalFormat === 'abort' ) abortOffer( { eventId: orderId, comment } );
        setSubmitting( false );
      }}
    >
      {( { values, handleChange, handleSubmit, isSubmitting } ) => (
        <Form
          onSubmit={handleSubmit}
          className='form'
        >
          <div className='form-row'>
            <label htmlFor='comment'>
              Reason for {modalFormat === 'abort' ? 'abortion' : 'cancel'}
            </label>
            <Field
              as='textarea'
              className={'form-control'}
              type='text'
              id='comment'
              name='comment'
              value={values.comment}
              onChange={handleChange}
              placeholder='Comment'
            />
          </div>
          <div className='form-footer'>
            <div className='form-row mt-40 mb-10'>
              <button
                className='btn btn--danger'
                type='submit'
                disabled={isSubmitting}
              >
                Yes, {modalFormat} offer
              </button>
            </div>
            <div className='form-row mb-0'>
              <button
                type='button'
                className='btn btn--default btn--block'
                onClick={closeModal}
              >
                No
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CancelOrAbortForm;
