import { useCallback, useEffect, useMemo, useState } from 'react';
import Pagination from '../components/Pagination';

const defaultItemsPerPageVariants = [ 25, 50, 100 ];

export const usePagination = (
  items,
  { perPageVariants, type, defaultPerPage, defaultState } = {
    perPageVariants: undefined,
    type: 'default',
    defaultPerPage: 25,
    defaultState: {}
  }
) => {
  const [ itemOffset, setItemOffset ] = useState( defaultState?.itemOffset || 0 );
  const itemsPerPageVariants = perPageVariants || defaultItemsPerPageVariants;
  const [ itemsPerPage, setItemsPerPage ] = useState(
    defaultState?.itemsPerPage || itemsPerPageVariants[0] || defaultPerPage || 25
  );
  const [ pageCount, setPageCount ] = useState( 0 );

  const itemsComparisonValue = JSON.stringify( items );

  const visibleItems = useMemo( () => {
    const endOffset = itemOffset + itemsPerPage;
    setPageCount( Math.ceil( items.length / itemsPerPage ) );
    return items.slice( itemOffset, endOffset );
  }, [ items, itemOffset, itemsPerPage, itemsComparisonValue ] );

  const onPageChange = useCallback(
    e => setItemOffset( e.selected * itemsPerPage ),
    [ itemsPerPage ]
  );

  useEffect( () => {
    if ( ( itemOffset / itemsPerPage ) % 1 !== 0 ) {
      setItemOffset( 0 );
    }
  }, [ itemsPerPage ] );

  useEffect( () => {
    const currentPage = itemOffset / itemsPerPage;
    if ( currentPage > pageCount ) {
      setItemOffset( 0 );
    }
  }, [ pageCount, itemOffset, itemsPerPage ] );

  useEffect( () => {
    setItemOffset( 0 );
  }, [ itemsComparisonValue ] );

  const component = useMemo(
    () => (
      <Pagination
        onPageChange={onPageChange}
        pageCount={pageCount}
        perPage={itemsPerPage}
        perPageVariants={itemsPerPageVariants}
        setPerPage={setItemsPerPage}
        forcePage={itemOffset / itemsPerPage}
        type={type}
      />
    ),
    [ itemsPerPage, itemsPerPageVariants, onPageChange, pageCount, itemOffset, type ]
  );

  return {
    visibleItems,
    pageCount,
    onPageChange,
    itemsPerPage,
    itemsPerPageVariants,
    setItemsPerPage,
    setItemOffset,
    setPageCount,
    PaginationComponent: component,
    itemOffset
  };
};
