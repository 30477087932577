import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { EmployeeRow } from './EmployeeRow';
import { JobRoleRow } from './JobRoleRow';

export const JobRoleGroup = ( { jobRole, location, jobRoleGroups } ) => {
  const { weeksCount, employeeFilter, locationFilter, allEvents } = useSelector(
    state => state.operationsPage
  );

  const jobRoleEvents = useMemo(
    () => allEvents.filter( event => event.JobRoleId === jobRole.id ),
    [ allEvents, jobRole ]
  );

  const eventsEmployees = useMemo( () => {
    if ( !allEvents ) return [];
    let filteredEvents = jobRoleEvents.filter(
      event =>
        event.JobOffer?.AcceptedEmployeeId || event.JobOffer?.ReceivedEmployees?.length
    );
    if ( employeeFilter ) {
      filteredEvents = filteredEvents.filter( event => {
        const acceptedEmployeeId = event.JobOffer?.AcceptedEmployeeId;
        if ( acceptedEmployeeId === employeeFilter.id ) return true;
        const receivedEmployees = event.JobOffer?.ReceivedEmployees;
        return !!receivedEmployees?.find( employee => employee.id === employeeFilter.id );
      } );
    }
    let eventsEmployees = [];
    const addEmployee = employee => {
      if ( !eventsEmployees.find( item => item.id === employee.id ) )
        eventsEmployees.push( employee );
    };
    for ( const event of filteredEvents ) {
      if ( event.JobOffer.AcceptedEmployee ) addEmployee( event.JobOffer.AcceptedEmployee );
      else
        for ( const employee of event.JobOffer.ReceivedEmployees ) {
          if ( employeeFilter && employee.id !== employeeFilter.id ) continue;
          addEmployee( employee );
        }
    }
    return eventsEmployees.sort( ( a, b ) => {
      if ( a.lastName > b.lastName ) return 1;
      return a.firstName > b.firstName ? 1 : -1;
    } );
  }, [ allEvents, jobRole, employeeFilter, weeksCount ] );

  if (
    ( !eventsEmployees.length || ( locationFilter && locationFilter.id !== location.id ) ) &&
    !jobRoleEvents.length
  )
    return null;

  return (
    <>
      <JobRoleRow
        jobRole={jobRole}
        location={location}
      />
      {eventsEmployees.map( ( employee, index ) => (
        <EmployeeRow
          key={index}
          jobRoleId={jobRole.id}
          employee={employee}
          jobRoleGroups={jobRoleGroups}
        />
      ) )}
    </>
  );
};
