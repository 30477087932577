import { mainApi } from './mainApi';

export const currentUserApi = mainApi.injectEndpoints( {
  endpoints: build => ( {
    getProfile: build.query( {
      query: () => {
        return {
          url: '/profile',
        };
      },
    } ),
  } ),
} );

export const { useGetProfileQuery } = currentUserApi;
