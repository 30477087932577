import React, { useState } from 'react';
import Modal from 'react-modal';
import ErrorBoundary from '../../components/ErrorBoundary';
import NoResultInTable from '../../components/NoResultInTable';
import { SearchPanel } from '../../components/SearchPanel';
import { Spinner } from '../../components/Spinner';
import { searchByName } from '../../store/actions/appActions';
import { useGetCostCentersQuery } from '../../store/apis/costCentersApi';
import CostCenterCreatingForm from './CostCenterCreatingForm/CostCenterCreatingForm';
import DropTableRow from './DropTableRow';

const CostCenters = ( { title } ) => {
  const { data: costCenters, isLoading } = useGetCostCentersQuery();

  const [ searchTerm, setSearchTerm ] = useState( '' );

  const visibleItems = costCenters ? searchByName( costCenters, searchTerm ) : [];

  const deleteCostCenter = id => console.log( `deleteCostCenter(${id})` );
  const renameCostCenter = id => console.log( `onRenameCostCenter(${id})` );

  const [ isCreatingFormOpened, setIsCreatingFormOpened ] = useState( false );
  const customStyles = {
    content: {
      maxWidth: 560,
    },
  };

  return (
    <ErrorBoundary>
      <div className='app-content'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12 col-lg-8'>
              <div className='d-md-flex align-items-center'>
                <h1 className='title mb-md-0 mr-0 mr-md-4'>{title}</h1>
                <SearchPanel
                  value={searchTerm}
                  onChange={setSearchTerm}
                />
              </div>
            </div>
            <div className='col-12 col-lg-4 mt-4 mb-4 mt-md-0 mb-md-0'>
              <div className='d-flex justify-content-lg-end'>
                <button
                  className='btn btn--green'
                  onClick={() => setIsCreatingFormOpened( true )}
                >
                  Create new cost center
                </button>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <table className='table border-none mt-40'>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Locations</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colSpan={3}>
                        <Spinner />
                      </td>
                    </tr>
                  ) : visibleItems.length ? (
                    visibleItems.map( costCenter => (
                      <DropTableRow
                        key={costCenter.id}
                        costCenter={costCenter}
                        onRename={renameCostCenter}
                        onDelete={deleteCostCenter}
                      />
                    ) )
                  ) : (
                    <NoResultInTable colSpan={3} />
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <Modal
          isOpen={isCreatingFormOpened}
          style={customStyles}
          ariaHideApp={false}
          contentLabel={'Cost center creating'}
        >
          <span
            className='icon-close'
            onClick={() => setIsCreatingFormOpened( false )}
          />
          <div className='modal-head'>
            <div className='modal-title'>Cost Center creating</div>
          </div>
          <div className='modal-body'>
            <CostCenterCreatingForm
              closeModal={() => setIsCreatingFormOpened( false )}
            ></CostCenterCreatingForm>
          </div>
        </Modal>
      </div>
    </ErrorBoundary>
  );
};

export default CostCenters;
