import React, { StrictMode } from 'react';
import { Provider, useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import Footer from './components/Footer';
import Header from './components/Header';
import AppNavigation from './navigation/AppNavigation/AppNavigation';
import LoginNavigation from './navigation/LoginNavigation';
import { store } from './store';

const App = () => {
  const { isAuth } = useSelector( state => state.app );

  return (
    <div className='app'>
      <>
        <Header />
        {isAuth ? <AppNavigation /> : <LoginNavigation />}
        {!isAuth && <Footer />}
      </>
    </div>
  );
};

const JoblinkJSApp = () => {
  return (
    <StrictMode>
      <Provider store={store}>
        <Router>
          <App />
        </Router>
      </Provider>
    </StrictMode>
  );
};

export default JoblinkJSApp;
