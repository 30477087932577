import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useCallback, useMemo, useRef, useState } from 'react';
import { debounce } from 'throttle-debounce';
import { ReactComponent as CheckIcon } from '../../../assets/images/check-long.svg';
import { useClickAwayListener, usePagination } from '../../../hooks';
import { useGetCustomerUsersQuery } from '../../../store/apis/customerUsersApi';
import { useGetAllEmployeesQuery } from '../../../store/apis/employeesApi';
import { Search } from '../EditMembersModal/components';
import './CreateConversationScreen.sass';

export const MembersSelect = ( {
  isOpen,
  close,
  open,
  costCenterId = 'all',
  type,
  selectedMembers,
  setSelectedMembers
} ) => {
  const { data: employees } = useGetAllEmployeesQuery(
    !( isOpen && type === 'Employee' ) ? skipToken : undefined
  );
  const { data: customerUsers } = useGetCustomerUsersQuery(
    !( isOpen && type === 'CustomerUser' ) ? skipToken : undefined
  );

  const [ searchTerm, setSearchTerm ] = useState( '' );
  const [ membersLocal, setMembersLocal ] = useState( selectedMembers );

  const containerRef = useRef( null );

  const updateSearch = useCallback( debounce( 300, setSearchTerm ), [ searchTerm ] );

  const selectedOverlayRef = useCallback( element => {
    if ( element ) {
      setTimeout( () => element.classList.add( 'active' ), 50 );
    }
  }, [] );

  const filteredMembers = useMemo( () => {
    let users = type === 'Employee' ? employees : customerUsers;
    if ( !users ) return [];
    if ( type === 'Employee' ) {
      users =
        costCenterId !== 'all'
          ? users.filter( employee => employee.CostCenterId === costCenterId )
          : users;
    }
    if ( searchTerm ) {
      users = users.filter( member =>
        member.firstName
          .concat( member.lastName )
          .toLowerCase()
          .includes( searchTerm.toLowerCase() )
      );
    }
    return users;
  }, [ searchTerm, employees, costCenterId, type, customerUsers ] );

  const { visibleItems, PaginationComponent } = usePagination( filteredMembers, {
    perPageVariants: [],
    type: 'compact',
    defaultPerPage: 12
  } );

  const onEmployeeClick = employee => () => {
    if ( membersLocal.find( item => item.id === employee.id ) ) {
      setMembersLocal( membersLocal.filter( item => item.id !== employee.id ) );
    } else {
      setMembersLocal( [ ...membersLocal, employee ] );
    }
  };

  const selectAll = () => setMembersLocal( filteredMembers );
  const clearAll = () => setMembersLocal( [] );
  const confirm = () => {
    setSelectedMembers && setSelectedMembers( membersLocal );
    close && close();
  };

  useClickAwayListener( containerRef, () => {
    if ( close ) {
      setMembersLocal( selectedMembers );
      close();
    }
  } );

  if ( !isOpen ) {
    if ( selectedMembers.length ) {
      return (
        <div
          className='employee-select-label'
          onClick={open}
        >
          <b>
            {selectedMembers[0].firstName} {selectedMembers[0].lastName}
          </b>
          {selectedMembers.length > 1 && (
            <b>
              , {selectedMembers[1].firstName} {selectedMembers[1].lastName}
            </b>
          )}
          {selectedMembers.length > 2 && (
            <>
              {' '}
              and <b>{selectedMembers.length - 2}</b> more
            </>
          )}
        </div>
      );
    }
    return null;
  }

  return (
    <div
      className='employee-select edit-members'
      ref={containerRef}
    >
      <Search
        onChange={updateSearch}
        style={{ marginBottom: 0 }}
      />
      <div className='white-shadow-box'>
        <div className='top'>
          <div className='left-side'>
            <span
              className='action'
              onClick={selectAll}
            >
              Select All
            </span>
            <span
              className='action'
              onClick={clearAll}
            >
              Select None
            </span>
            <p className='count'>
              <span>{membersLocal.length}</span>
              recipients selected
            </p>
          </div>
          {PaginationComponent}
        </div>
        <div className='employees-list'>
          {visibleItems.map( ( employee, index ) => {
            const isSelected = membersLocal.find( item => item.id === employee.id );

            return (
              <div
                key={index}
                className={[ 'employee-item', isSelected && 'selected' ].join( ' ' )}
                onClick={onEmployeeClick( employee )}
              >
                <div className='avatar'>
                  {employee.photoUrl ? (
                    <img src={employee.photoUrl} />
                  ) : (
                    `${employee.firstName[0]}${employee.lastName[0]}`
                  )}
                  {isSelected && (
                    <div
                      className='selected'
                      ref={selectedOverlayRef}
                    >
                      <CheckIcon />
                    </div>
                  )}
                </div>
                <span className='name'>
                  {employee.firstName} {employee.lastName}
                </span>
              </div>
            );
          } )}
        </div>
        <div className='employee-select-buttons'>
          <span
            className='action'
            onClick={confirm}
          >
            Confirm
          </span>
        </div>
      </div>
    </div>
  );
};
