import React, { useMemo, useState } from 'react';
import Modal from 'react-modal';
import {
  useCreatePresetTaskMutation,
  useCreateTaskSkillsMutation,
  useDeleteSkillMutation,
  useGetPresetTasksQuery
} from '../../store/apis/presetTasksApi';
import TasksForm from './TasksForm';

const Tasks = () => {
  const { data: tasks, isLoading } = useGetPresetTasksQuery();
  const [ createTask ] = useCreatePresetTaskMutation();
  const [ createTaskSkills ] = useCreateTaskSkillsMutation();
  const [ deleteSkill ] = useDeleteSkillMutation();

  const [ modalIsOpen, setIsOpen ] = useState( false );
  const [ modalData, setModalData ] = useState( null );
  const [ modalFormat, setModalFormat ] = useState( null );
  const [ editedTaskId, setEditedTaskId ] = useState( null );

  const sortedTasks = useMemo( () => {
    if ( !tasks ) return [];
    return [ ...tasks ].sort( ( a, b ) => ( a.id > b.id ? 1 : -1 ) );
  }, [ tasks ] );

  const openModal = () => setIsOpen( true );
  const closeModal = () => setIsOpen( false );

  const createNewTask = () => {
    openModal();
    setModalFormat( 'create' );
    setModalData( {
      name: '',
      Skills: tasks
        ?.map( task => task.Skills )
        .flat()
        .filter( onlyUnique ),
      creation: true,
    } );
  };

  const editTask = ( tasks, taskId ) => {
    openModal();
    setModalFormat( 'edit' );
    setModalData( {
      ...tasks[taskId],
      selected: tasks[taskId].Skills.map( skill => skill.name ),
    } );
    setEditedTaskId( taskId );
  };

  const customStyles = {
    content: {
      maxWidth: 680,
    },
  };

  const onEditTaskSubmit = values => {
    closeModal();
    const task = tasks[editedTaskId];
    const skillsToDelete = task.Skills.filter(
      skill => !values.skills.includes( skill.name )
    );
    const skillsToUpload = values.skills.filter(
      skillName => !task.Skills.find( skill => skill.name === skillName )
    );
    try {
      skillsToDelete.forEach( skill => {
        deleteSkill( skill.id );
      } );
    } finally {
      createTaskSkills( { id: task.id, skills: skillsToUpload } );
    }
  };

  return (
    <div className='app-content pb-4'>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12 col-md-6'>
            <h1 className='title'>Tasks</h1>
          </div>
          <div className='col-12 col-md-6 mb-4'>
            <div className='d-flex justify-content-center justify-content-md-end align-items-center'>
              <button
                className='btn btn--green'
                onClick={() => createNewTask()}
              >
                Create new task
              </button>
            </div>
          </div>
        </div>
        <div className='white-shadow-box pb-1'>
          <div className='row'>
            {!isLoading &&
              sortedTasks.map( ( task, num ) => (
                <div
                  key={task.id}
                  className='col-12 col-lg-4 mb-4'
                >
                  <div className='tasks'>
                    <div className='tasks__head task-box'>
                      <div className='tasks__title'>{task.name}</div>
                      <span
                        className='edit'
                        onClick={() => editTask( tasks, num )}
                      >
                        Edit
                      </span>
                    </div>
                    <ul className='tasks-list'>
                      {task.Skills?.map( skill => (
                        <li
                          className='task-box bg-white'
                          key={skill.id}
                        >
                          {skill.name}
                        </li>
                      ) )}
                    </ul>
                  </div>
                </div>
              ) )}
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        ariaHideApp={false}
        contentLabel='Edit Tasks'
        portalClassName='overflow-hidden'
      >
        <span
          className='icon-close'
          onClick={closeModal}
        />
        <div className='modal-head'>
          <div className='modal-title'>Task editing</div>
        </div>
        <div className='modal-body'>
          {modalFormat === 'create' && (
            <TasksForm
              data={modalData}
              onSubmit={createTask}
              closeModal={closeModal}
            />
          )}
          {modalFormat === 'edit' && (
            <TasksForm
              onSubmit={onEditTaskSubmit}
              closeModal={closeModal}
              data={modalData}
            />
          )}
        </div>
      </Modal>
    </div>
  );
};

export default Tasks;

function onlyUnique( item, index, arr ) {
  return arr.findIndex( value => value.name === item.name ) === index;
}
