import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Field, Formik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Button } from '../../../components/Button';
import { SelectField } from '../../../components/SelectField';
import { StyledSelect } from '../../../components/StyledSelect';
import {
  useChangeConversationCustomerUsersMutation,
  useChangeConversationEmpoloyeesMutation,
  useCreateConversationMutation,
  useGetJoblinkGroupsQuery,
  useSendMessageMutation
} from '../../../store/apis/chatApi';
import { useGetCostCentersQuery } from '../../../store/apis/costCentersApi';
import { useGetProfileQuery } from '../../../store/apis/currentUserApi';
import { createConversationActions } from '../../../store/slices';
import { ConfirmationModal } from '../ConfirmationModal';
import './CreateConversationScreen.sass';
import { MembersSelect } from './MembersSelect';
import { createConversationSchema } from './schemas';

export const CreateConversationScreen = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { messageToReply, employeesPrefilled, joblinkGroupId } = useSelector(
    state => state.createConversation
  );

  const [ recipientsType, setRecipientsType ] = useState();
  const [ selectedCostCenter, setSelectedCostCenter ] = useState();
  const [ isEmployeesSelectOpen, setEmplyoeesSelectOpen ] = useState( false );
  const [ selectedEmployees, setSelectedEmployees ] = useState( employeesPrefilled || [] );
  const [ isDiscardModalOpen, setDiscardModalOpen ] = useState( false );

  const { data: groups } = useGetJoblinkGroupsQuery();
  const { data: profile } = useGetProfileQuery();
  const { data: costCenters } = useGetCostCentersQuery(
    !recipientsType ? skipToken : undefined
  );
  const [ createConversation ] = useCreateConversationMutation();
  const [ sendMessage ] = useSendMessageMutation();
  const [ changeEmployees ] = useChangeConversationEmpoloyeesMutation();
  const [ changeCustomerUsers ] = useChangeConversationCustomerUsersMutation();

  const visibleGroups = useMemo( () => {
    if ( !profile ) return [];
    return groups
      ? groups.filter( group => profile.profile.JoblinkGroupIds.includes( group.id ) )
      : [];
  }, [ groups, profile ] );

  useEffect( () => {
    setEmplyoeesSelectOpen( !!selectedCostCenter );
  }, [ selectedCostCenter ] );

  useEffect( () => {
    return () => {
      dispatch( createConversationActions.clear() );
    };
  }, [] );

  const toggleEmployeesSelectOpen = value => () => setEmplyoeesSelectOpen( value );
  const toggleDiscardModalOpen = value => () => setDiscardModalOpen( value );
  const goBack = () => history.goBack();

  const onSubmit = ( { message, ...values } ) => {
    const changeMembers =
      recipientsType === 'Employee' ? changeEmployees : changeCustomerUsers;
    createConversation( { ...values, direction: recipientsType } )
      .unwrap()
      .then( ( { id } ) => {
        Promise.all( [
          changeMembers( {
            id,
            members: selectedEmployees.map( employee => employee.id )
          } ).unwrap(),
          sendMessage( { id, message: { text: message } } ).unwrap()
        ] ).then( () => {
          history.push( `/chat/${id}` );
        } );
      } );
  };

  return (
    <div className='white-shadow-box create-conversation-container'>
      <h1>New Message</h1>
      <Formik
        initialValues={{
          JoblinkGroupId: joblinkGroupId,
          topic: messageToReply?.text || '',
          message: '',
          unidirectional: false
        }}
        onSubmit={onSubmit}
        validationSchema={createConversationSchema}
        validateOnMount
      >
        {( { handleSubmit, isValid } ) => (
          <form onSubmit={handleSubmit}>
            <div className='field-row'>
              <label>Group:</label>
              <SelectField
                options={visibleGroups.map( group => ( {
                  value: group.id,
                  label: group.name
                } ) )}
                name={'JoblinkGroupId'}
              />
            </div>
            <div className='field-row'>
              <label>Recipients:</label>
              <StyledSelect
                options={[
                  { value: 'Employee', label: 'Employees' },
                  { value: 'CustomerUser', label: 'Customer Users' }
                ]}
                onChange={setRecipientsType}
                value={recipientsType}
              />
              {!!recipientsType && (
                <StyledSelect
                  options={[ { value: 'all', label: 'All cost centers' } ].concat(
                    costCenters?.map( center => ( {
                      value: center.id,
                      label: center.name
                    } ) )
                  )}
                  onChange={setSelectedCostCenter}
                  value={selectedCostCenter}
                />
              )}
            </div>
            <div className='field-row employee-select-wrapper'>
              <MembersSelect
                isOpen={isEmployeesSelectOpen}
                close={toggleEmployeesSelectOpen( false )}
                open={toggleEmployeesSelectOpen( true )}
                costCenterId={selectedCostCenter}
                selectedMembers={selectedEmployees}
                setSelectedMembers={setSelectedEmployees}
                type={recipientsType}
              />
              {!!selectedEmployees?.length && (
                <label className='custom-checkbox'>
                  <Field
                    type='checkbox'
                    name='unidirectional'
                  />
                  <span>Prevent recipients from replying to this message</span>
                </label>
              )}
            </div>
            <div
              className='field-row subject'
              data-reply={!!messageToReply}
            >
              <label>Subject:</label>
              <div className='field'>
                <Field
                  className='form-control'
                  name='topic'
                  placeholder='Conversation subject'
                />
                <div className='message'>
                  {messageToReply && (
                    <div className='original-answer'>
                      <div className='original-answer-container'>
                        <h4>Original Author</h4>
                        <p>{messageToReply.text}</p>
                      </div>
                    </div>
                  )}
                  <Field
                    className='form-control'
                    name='message'
                    as='textarea'
                    placeholder='Message'
                  />
                </div>
              </div>
            </div>
            <div className='buttons'>
              <Button
                type='red'
                onClick={toggleDiscardModalOpen( true )}
              >
                Discard
              </Button>
              <Button disabled={!isValid || !selectedEmployees?.length}>Send</Button>
            </div>
          </form>
        )}
      </Formik>
      <ConfirmationModal
        isOpen={isDiscardModalOpen}
        close={toggleDiscardModalOpen( false )}
        text='Are you sure you want to discard this message?'
        confirm={{
          label: 'Yes, discard',
          onClick: goBack
        }}
      />
    </div>
  );
};
