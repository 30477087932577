import { mainApi } from './mainApi';

export const presetTasksApi = mainApi.injectEndpoints( {
  endpoints: build => ( {
    getPresetTasks: build.query( {
      query: () => ( {
        url: '/presetTasks',
      } ),
      providesTags: ( res, err ) => ( err ? [] : [ 'tasks' ] ),
    } ),
    createPresetTask: build.mutation( {
      query: fields => ( {
        url: '/presetTasks',
        method: 'post',
        body: fields,
      } ),
      invalidatesTags: ( res, err ) => ( err ? [] : [ 'tasks' ] ),
    } ),
    createTaskSkills: build.mutation( {
      query: ( { id, skills } ) => ( {
        url: `/presetTasks/${id}/skils`,
        method: 'post',
        body: skills.map( name => ( { name } ) ),
      } ),
      invalidatesTags: ( res, err ) => ( err ? [] : [ 'tasks' ] ),
    } ),
    deleteSkill: build.mutation( {
      query: id => ( {
        url: `/presetTasks/skills/${id}`,
        method: 'delete',
      } ),
      invalidatesTags: ( res, err ) => ( err ? [] : [ 'tasks' ] ),
    } ),
  } ),
} );

export const {
  useGetPresetTasksQuery,
  useCreatePresetTaskMutation,
  useCreateTaskSkillsMutation,
  useDeleteSkillMutation,
} = presetTasksApi;
