import * as yup from 'yup';

export const createLeaveSchema = yup.object( {
  type: yup
    .string()
    .oneOf( [ 'sick', 'parental', 'unpaid', 'holiday', 'annual', 'other' ] )
    .required(),
  start: yup.date().required(),
  end: yup.date().required(),
  comment: yup.string(),
} );
