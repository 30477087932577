export const JoblinkUser = ( { user } ) => {
  return (
    <div className='edit-members-list-user'>
      <div className='info'>
        <div className='avatar'>
          {user.firstName[0]}
          {user.lastName[0]}
        </div>
        <span className='name'>
          {user.firstName} {user.lastName}
        </span>
      </div>
    </div>
  );
};
