import { Field, Form, Formik } from 'formik';
import React from 'react';
import { useHistory } from 'react-router-dom';
import ErrorBoundary from '../../../components/ErrorBoundary';
import { __resetEmail_ } from '../../../constants';
import { authApi } from '../../../store/apis/authApi';

const RequestResetLinkScreen = ( { title } ) => {
  const history = useHistory();

  const onResetPassword = ( { email } ) => {
    authApi.resetPasswordRequest( email ).then( success => {
      if ( success ) {
        localStorage.setItem( __resetEmail_, email );
        history.push( '/reset-done' );
      }
    } );
  };

  return (
    <ErrorBoundary>
      <div className='app-login__head app-login__head--login-reset'>
        <h2 className='title'>{title}</h2>
      </div>
      <div className='col-12'>
        <div className='app-login__body app-login__body--login-reset'>
          <p>
            Enter email you registered with and we will send you link to reset password
          </p>
        </div>
        <Formik
          onSubmit={onResetPassword}
          initialValues={{ email: '' }}
        >
          <Form>
            <div className='form-row'>
              <Field
                className='form-control'
                name='email'
                placeholder='Enter Your Email'
                type='email'
              />
            </div>
            <div className='form-row'>
              <button
                type='submit'
                className='btn btn--green'
              >
                Reset password
              </button>
            </div>
          </Form>
        </Formik>
      </div>
    </ErrorBoundary>
  );
};

export default RequestResetLinkScreen;
