export const employeesSortingPredicates = {
  byFirstName: ( a, b ) => {
    const aName = a.firstName.toLowerCase().concat( a.lastName.toLowerCase() );
    const bName = b.firstName.toLowerCase().concat( b.lastName.toLowerCase() );
    return aName > bName ? 1 : -1;
  },
  byLastName: ( a, b ) => {
    const aName = a.lastName.toLowerCase().concat( a.firstName.toLowerCase() );
    const bName = b.lastName.toLowerCase().concat( b.firstName.toLowerCase() );
    return aName > bName ? 1 : -1;
  }
};
