import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ErrorBoundary from '../../components/ErrorBoundary';
import { useGetJobEventsPolling } from '../../hooks';
import { operationsPageActions } from '../../store/slices';
import { OperationScreenControls } from './components';
import { EmployeeGroupLayout, OperationsJobRoleLayout } from './components/layout';

export const OperationsScreen = () => {
  const { currentWeekStart, weeksCount, viewMode } = useSelector(
    state => state.operationsPage
  );

  const dispatch = useDispatch();

  const { data: events } = useGetJobEventsPolling( {
    start: currentWeekStart.toJSDate(),
    end: currentWeekStart
      .plus( { weeks: weeksCount - 1 } )
      .endOf( 'week' )
      .toJSDate()
  } );

  const setEventsByDate = value => dispatch( operationsPageActions.setEventsByDate( value ) );

  useEffect( () => {
    if ( events ) {
      const tmp = {};
      for ( const event of events ) {
        const isoDate = DateTime.fromISO( event.start ).toISODate();
        if ( !tmp[isoDate] ) tmp[isoDate] = [];
        if ( !tmp[isoDate].find( item => item.id === event.id ) ) {
          tmp[isoDate].push( event );
        }
      }
      setEventsByDate( tmp );
    }
  }, [ events ] );

  useEffect( () => {
    dispatch( operationsPageActions.setAllEvents( events ) );
  }, [ events ] );

  return (
    <ErrorBoundary>
      <div className='app-content'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12 col-lg-6 d-flex'>
              <h1 className='title'>Operations</h1>
              <Link
                className='btn btn--green align-self-start ml-3'
                to='/new-order'
              >
                Create new
              </Link>
            </div>
          </div>
          <OperationScreenControls />

          {viewMode === 'employee' ? (
            <EmployeeGroupLayout />
          ) : (
            <OperationsJobRoleLayout />
          )}
        </div>
      </div>
    </ErrorBoundary>
  );
};
