import { concat } from '../../utils/utils';
import { InfoColumn } from '../InfoColumns/InfoColumn';
import styles from './UnprocessedEvents.module.scss';

export const UnprocessedEventsHoverWindow = ( { event, offsetTop } ) => {
  return (
    <div
      className={concat( styles['hover-window'], 'white-shadow-box' )}
      data-visible={!!event}
      style={{ top: offsetTop }}
    >
      <InfoColumn
        label='Description'
        value={
          <span className={styles['info']}>{event?.JobOrder?.description || '-'}</span>
        }
        className={'no-border'}
      />
      <InfoColumn
        label='Internal comment'
        value={
          <span className={styles['info']}>
            {event?.JobOrder?.internalComment || '-'}
          </span>
        }
        className={'no-border mt-3'}
      />
    </div>
  );
};
