import React, { memo, useEffect, useState } from 'react';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { ButtonWithConfirmation } from '../../../components/ButtonWithConfirmation/ButtonWithConfirmation';
import ErrorBoundary from '../../../components/ErrorBoundary';
import LinkBack from '../../../components/LinkBack';
import { StyledSelect } from '../../../components/StyledSelect';
import { useGetJoblinkGroupsQuery } from '../../../store/apis/chatApi';
import { useGetProfileQuery } from '../../../store/apis/currentUserApi';
import {
  useDeleteJoblinkUserMutation,
  useGetJoblinkUserQuery,
  useUpdateJoblinkUserMutation
} from '../../../store/apis/joblinkUsersApi';
import { useResetPasswordJoblinkMutation } from '../../../store/apis/resetPasswordApi';

const Account = () => {
  const { id } = useParams();
  const history = useHistory();

  const [ resetPassword, { isSuccess, isError } ] = useResetPasswordJoblinkMutation();
  const { data: profile } = useGetProfileQuery();
  const { data: user, isLoading } = useGetJoblinkUserQuery( id );
  const { data: joblinkGroups } = useGetJoblinkGroupsQuery();
  const [ deleteUser ] = useDeleteJoblinkUserMutation();
  const [ updateUser ] = useUpdateJoblinkUserMutation();

  const [ isAdmin, setIsAdmin ] = useState( false );
  const [ messagingGroups, setMessagingGroups ] = useState( user?.JoblinkGroupIds || [] );

  useEffect( () => {
    setIsAdmin( profile?.profile.class === 'admin' );
  }, [ profile?.profile.class ] );

  const onResetPasswordClick = () => {
    if ( user && user.email ) {
      resetPassword( user.email );
    }
  };

  const deleteAccount = () => {
    deleteUser( id )
      .unwrap()
      .then( () => {
        history.goBack();
      } );
  };

  const updateUserGroups = () => {
    updateUser( { id, fields: { JoblinkGroupIds: messagingGroups } } );
  };

  return (
    <ErrorBoundary>
      <div className='app-content'>
        <div className='container-fluid'>
          {!isLoading && user && (
            <>
              <div className='row mb-3'>
                <div className='col-12'>
                  <div>
                    <LinkBack title='Back to Joblink' />
                  </div>
                  <div className='d-flex flex-wrap align-items-center justify-content-between'>
                    <div className='title-box mt-4 mt-md-3'>
                      <h1 className='title error-title mb-0'>
                        {user.firstName} {user.lastName}
                      </h1>
                      <span className='title-badge mt-3 mt-md-0 ml-3'>
                        Joblink {user.class}
                      </span>
                    </div>

                    {+id !== profile?.profile.id && isAdmin && (
                      <ButtonWithConfirmation
                        onConfirm={deleteAccount}
                        confirmLabel='Delete'
                        text='Delete account'
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <div className='white-shadow-box pb-3 mb-1'>
                    <div className='info-cols'>
                      <div className='info-col'>
                        <span className='info-col__label'>Email</span>
                        <strong className='info-col__title'>{user.email}</strong>
                      </div>
                      <div className='info-col'>
                        <span className='info-col__label'>Phone</span>
                        <strong className='info-col__title'>{user.phone}</strong>
                      </div>

                      <div className='info-col align-items-start'>
                        <span className='info-col__label mb-1'>Password</span>
                        {( isSuccess || isError ) && (
                          <span className={isSuccess ? 'text-success' : 'text-danger'}>
                            {isSuccess
                              ? 'Reset link has been sent'
                              : 'Failed to send reset link'}
                          </span>
                        )}
                        <button
                          className='btn btn--light-green'
                          onClick={onResetPasswordClick}
                        >
                          Send password reset link
                        </button>
                      </div>
                    </div>
                    <div className='mt-4 mt-md-2'>
                      <NavLink
                        to={`/accounts-joblink/${id}/edit-user-info`}
                        className='btn btn--smd-block btn--light-green'
                      >
                        Edit personal info
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div className='col-12'>
                  <div className='white-shadow-box mb-1'>
                    <div className='info-cols'>
                      <div className='info-col'>
                        <div className='info-col__label mb-3'>Message groups</div>
                        <StyledSelect
                          options={
                            joblinkGroups?.map( group => ( {
                              label: group.name,
                              value: group.id
                            } ) ) || []
                          }
                          isMulti
                          containerStyle={{ maxWidth: '100%', marginLeft: 0 }}
                          onChange={setMessagingGroups}
                          value={messagingGroups}
                        />
                        <div className='mt-3'>
                          <button
                            className='btn btn--light-green '
                            onClick={updateUserGroups}
                            disabled={
                              !!messagingGroups.length &&
                              arrayEqual( messagingGroups, user?.JoblinkGroupIds || [] )
                            }
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default memo( Account );

function arrayEqual( a, b ) {
  return (
    a.length === b.length &&
    a.every( function ( value ) {
      return b.includes( value );
    } )
  );
}
