import { useContext, useMemo } from 'react';
import './EventItemHoverBox.sass';
import { EventItemHoverBoxContext } from './EventItemHoverBoxContext';

export const EventItemHoverBox = ( { children } ) => {
  const { event, boundingBox, setBoundingBox, setEvent } = useContext(
    EventItemHoverBoxContext
  );

  const isShowing = !!event && !!boundingBox;

  const stickSide = useMemo( () => {
    if ( !isShowing ) return;
    const windowHeight = window.innerHeight;
    const elementTop = boundingBox.top;
    return ( windowHeight - elementTop ) / windowHeight > 0.5 ? 'bottom' : 'top';
  }, [ isShowing, boundingBox ] );

  const style = useMemo( () => {
    if ( !isShowing ) return;
    const margin = 8;
    const verticalStyle =
      stickSide === 'bottom'
        ? { top: boundingBox.top + boundingBox.height + margin }
        : { bottom: window.innerHeight - boundingBox.top + margin };
    return {
      left: boundingBox.left + margin,
      ...verticalStyle,
    };
  }, [ boundingBox, stickSide, isShowing ] );

  const className = useMemo( () => {
    return [ eventItemHoverBoxClassName, stickSide, isShowing ? 'visible' : 'hidden' ].join(
      ' '
    );
  }, [ stickSide ] );

  const onMouseLeave = () => {
    setEvent( null );
    setBoundingBox( null );
  };

  return (
    <div
      className={className}
      style={style}
      onMouseLeave={onMouseLeave}
    >
      <span className='triangle'>▲</span>
      {children}
    </div>
  );
};

export const eventItemHoverBoxClassName = 'event-listing-hover-box';
