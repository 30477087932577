import React from 'react';
import ErrorBoundary from '../../components/ErrorBoundary';
import { DialogsScreen } from './DialogsScreen';

import './messagesScreen.sass';

const MessagesScreen = props => {
  const { title } = props;

  return (
    <ErrorBoundary>
      <DialogsScreen title={title} />
    </ErrorBoundary>
  );
};

export default MessagesScreen;
