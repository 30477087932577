import { ReactComponent as CloseIcon } from '../../../../assets/images/chat-close.svg';

import './InputSearchSmall.sass';

export const InputSearchSmall = ( { value, onChange, onClose } ) => {
  const onInputChange = event => {
    onChange && onChange( event.target.value );
  };
  return (
    <div className={[ 'search-small', onClose ? 'closable' : undefined ].join( ' ' )}>
      <input
        className='form-control'
        value={value}
        onChange={onInputChange}
      />
      {onClose && <CloseIcon onClick={onClose} />}
    </div>
  );
};
