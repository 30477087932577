import styles from '../../OperationsScreen.module.sass';

export const HeaderWeekLabel = ( { weekDate } ) => {
  return (
    <div className={styles['header-week-label']}>
      <span>
        Week <b>{weekDate.weekNumber}</b>
      </span>
    </div>
  );
};
