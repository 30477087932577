import { useFormikContext } from 'formik';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { useGetLocationsQuery } from '../../../../store/apis/locationsApi';
import { selectTheme } from '../../../../theme';

export const LocationField = () => {
  const navLocation = useLocation();

  const { setFieldValue, values } = useFormikContext();
  const { data: locations } = useGetLocationsQuery();

  const locationId = +values['location'];

  const optionsPromise = value =>
    new Promise( resolve => {
      if ( value.length <= 2 ) resolve( [] );
      else {
        const options = locations
          .filter( location => location.name.toLowerCase().includes( value.toLowerCase() ) )
          .map( location => ( {
            value: location.id,
            label: location.name
          } ) );
        resolve( options );
      }
    } );

  const location = useMemo( () => {
    return locations?.find( location => location.id === locationId );
  }, [ locationId, locations ] );

  const selected = useMemo( () => {
    if ( location ) {
      return {
        value: location.id,
        label: location.name
      };
    }
  }, [ location ] );

  useEffect( () => {
    if ( location ) setFieldValue( 'costCenter', location.CostCenterId );
  }, [ location ] );

  const onChange = option => {
    setFieldValue( 'location', option?.value );
    if ( !option ) clearDownwardFields();
  };

  const clearDownwardFields = () => {
    setFieldValue( 'costCenter', '' );
    setFieldValue( 'company', '' );
    setFieldValue( 'jobRole', '' );
    setFieldValue( 'task', '' );
    setFieldValue( 'eventSkills', [] );
  };

  useEffect( () => {
    if ( navLocation.state?.locationId ) {
      setFieldValue( 'location', navLocation.state.locationId );
    }
  }, [ navLocation.state ] );

  return (
    <div className='form-row w-100 select-box'>
      <label>Location</label>
      <AsyncSelect
        theme={selectTheme}
        styles={{
          control: base => ( { ...base, height: '100%' } ),
          container: base => ( {
            ...base,
            height: 40,
            width: '100%'
          } )
        }}
        cacheOptions
        loadOptions={optionsPromise}
        value={selected}
        onChange={onChange}
        isClearable
      />
    </div>
  );
};
