import { Field, Form, Formik } from 'formik';
import React, { memo, useEffect, useState } from 'react';
import Select from 'react-select';
import { fieldErrorClass } from '../../../../constants';
import { useCreateLocationMutation } from '../../../../store/apis/companiesApi';
import { useGetCostCentersQuery } from '../../../../store/apis/costCentersApi';
import { selectTheme } from '../../../../theme';
import validators from '../../../../utils/validators';

const LocationCreatingForm = ( { closeModal, companyId } ) => {
  const { data: costCenters } = useGetCostCentersQuery();

  const [ createLocation ] = useCreateLocationMutation();

  // States
  const [ costCentersOptions, setCostCentersOptions ] = useState( null );
  const [ costCenter, setCostCenter ] = useState( null );

  // Form => Initial Values
  const initialValues = {
    name: '',
    address_Street: '',
    address_ZipCode: '',
    address_City: '',
  };

  // Actions
  const handleCostCenterNameChange = selectedOption => setCostCenter( selectedOption );

  // Side Effects
  useEffect( () => {
    setCostCentersOptions( costCenters.map( el => ( { value: el?.id, label: el?.name } ) ) );
  }, [ costCenters ] );

  useEffect( () => {
    setCostCenter( costCentersOptions?.[0] );
  }, [ costCentersOptions ] );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={( values, { setSubmitting } ) => {
        const data = {
          ...values,
          CostCenterId: costCenter.value,
          CompanyAccountId: +companyId,
        };
        createLocation( data )
          .unwrap()
          .then( () => {
            setSubmitting( false );
            closeModal();
          } );
      }}
    >
      {( { values, handleChange, handleSubmit, errors, touched, isSubmitting } ) => (
        <Form
          onSubmit={handleSubmit}
          className='form'
        >
          <div className='row'>
            <div className='col-12'>
              <div className='form-row'>
                <label htmlFor='name'>Location Name</label>
                <Field
                  className={`form-control ${
                    errors.name && touched.name && fieldErrorClass
                  }`}
                  type='text'
                  id='name'
                  name='name'
                  value={values.name}
                  onChange={handleChange}
                  validate={validators.required}
                />
              </div>
              <div className='form-row'>
                <label htmlFor='address_Street'>Address Street</label>
                <Field
                  className={`form-control ${
                    errors.address_Street && touched.address_Street && fieldErrorClass
                  }`}
                  type='text'
                  id='address_Street'
                  name='address_Street'
                  value={values.address_Street}
                  onChange={handleChange}
                  validate={validators.required}
                />
              </div>
              <div className='form-group'>
                <div className='form-row'>
                  <label htmlFor='address_ZipCode'>Address Zip Code</label>
                  <Field
                    className={`form-control ${
                      errors.address_ZipCode && touched.address_ZipCode && fieldErrorClass
                    }`}
                    type='text'
                    id='address_ZipCode'
                    name='address_ZipCode'
                    value={values.address_ZipCode}
                    onChange={handleChange}
                    validate={validators.required}
                  />
                </div>
                <div className='form-row'>
                  <label htmlFor='address_City'>Address City</label>
                  <Field
                    className={`form-control ${
                      errors.address_City && touched.address_City && fieldErrorClass
                    }`}
                    type='text'
                    id='address_City'
                    name='address_City'
                    value={values.address_City}
                    onChange={handleChange}
                    validate={validators.required}
                  />
                </div>
              </div>
              <div className='form-row'>
                <div className='select-box'>
                  <label className='w-100'>
                    <span>Cost center name</span>
                    <Select
                      value={costCenter}
                      onChange={handleCostCenterNameChange}
                      options={costCentersOptions}
                      theme={selectTheme}
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className='form-footer'>
            <div className='form-row mt-40 mb-10'>
              <button
                className='btn btn--green btn--block mb-2'
                type='submit'
                disabled={isSubmitting}
              >
                Save
              </button>
              <button
                type='button'
                className='btn btn--default btn--block'
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default memo( LocationCreatingForm );
