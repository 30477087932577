import { DateTime } from 'luxon';
import { useCallback, useContext, useMemo, useState } from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { ReactComponent as SickLeaveIcon } from '../../assets/images/red-plus.svg';
import { eventItemHoverBoxClassName } from '../../components/EventItemHoverBox';
import { EventItemHoverBoxContext } from '../../components/EventItemHoverBox/EventItemHoverBoxContext';

export const EventItem = ( { event } ) => {
  const { url } = useRouteMatch();

  const { setEvent, setBoundingBox } = useContext( EventItemHoverBoxContext );
  const [ hoverTimeout, setHoverTimeout ] = useState();

  const openHoverBox = useCallback(
    e => () => {
      setEvent( event );
      setBoundingBox( e.target.closest( '.event-listing-item' ).getClientRects()[0] );
      if ( hoverTimeout ) clearTimeout( hoverTimeout );
    },
    [ setEvent, setBoundingBox, hoverTimeout ]
  );

  const onMouseEnter = e => {
    const timeout = setTimeout( openHoverBox( e ), 500 );
    setHoverTimeout( timeout );
  };

  const onMouseLeave = e => {
    if (
      !(
        e.relatedTarget.classList &&
        Array.from( e.relatedTarget.classList ).includes( eventItemHoverBoxClassName )
      )
    ) {
      setEvent( null );
      setBoundingBox( null );
      if ( hoverTimeout ) clearTimeout( hoverTimeout );
    }
  };

  const className = useMemo( () => {
    const names = [ 'event-listing-item' ];
    if ( event.lockedAt ) names.push( 'locked-editing' );
    return names.join( ' ' );
  }, [ event.lockedAt ] );

  const duration = useMemo( () => {
    const start = DateTime.fromISO( event.start );
    const end = DateTime.fromISO( event.end );
    return Math.floor( end.diff( start, 'hours' ).hours );
  }, [ event ] );

  const timeString = useMemo( () => {
    const start = DateTime.fromISO( event.start );
    const end = DateTime.fromISO( event.end );
    return `${start.toFormat( 'dd.LL.' )} ${start.toFormat( 'HH:mm' )}-${end.toFormat(
      'HH:mm'
    )} (${duration})`;
  }, [ event, duration ] );

  const employeeText = useMemo( () => {
    if ( event.status === 'filled' ) {
      const employee = event.JobOffer.AcceptedEmployee;
      return [ employee.firstName, employee.lastName ].join( ' ' );
    } else if ( event.JobOffer?.ReceivedEmployees?.length ) {
      return (
        <span>
          Tarjottu <b>( {event.JobOffer.ReceivedEmployees.length} )</b>
        </span>
      );
    } else {
      return 0;
    }
  }, [ event ] );

  return (
    <tr
      key={event.id}
      className={className}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <td className='link-in text-bold sick-leve-icon-container'>
        <NavLink to={`${url}/${event.id}`}>
          {!!event.lockedAt && <span className='locked-icon' />}
          {timeString}
          {event.sickLeaveReplacement && <SickLeaveIcon className='sick-leave-icon' />}
        </NavLink>
      </td>
      <td className='link-in'>
        <NavLink to={`${url}/${event.id}`}>{event.JobRole.Location.name}</NavLink>
      </td>
      <td className='link-in'>
        <NavLink to={`${url}/${event.id}`}>{event.JobRole.name}</NavLink>
      </td>
      <td className='link-in'>
        <NavLink to={`${url}/${event.id}`}>{employeeText}</NavLink>
      </td>
      <td className='link-in'>
        <NavLink to={`${url}/${event.id}`}>{event.status}</NavLink>
      </td>
    </tr>
  );
};
